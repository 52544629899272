// // httpClient.js
// import axios from 'axios';

// const httpClient = axios.create({
//   baseURL: 'https://vector.cloobot.ai/backend', // Set your API base URL
// });

// const testhttpClient = axios.create({
//   baseURL : 'http://quote.cloobot.ai:5004/'
// })

// export default httpClient;
// export {testhttpClient};


// httpClient.js
import axios from 'axios';

// const PROD_BACK = 'https://vector.cloobot.ai/backend'
// const PROD_BACK = 'http://quote.cloobot.ai:5005'
// const PROD_BACK = 'http://authtest.cloobot.ai'
const PROD_BACK = 'http://20.198.93.27:5010'



const MICR_SERV = 'https://microservice.cloobot.ai/configuration-script';
// const MICR_SERV = 'http://quote.cloobot.ai:5004';

const httpClient = axios.create({
  baseURL: PROD_BACK 
});

const testhttpClient = axios.create({
  baseURL : MICR_SERV
})

const dummyhttpClient = axios.create({
  baseURL : 'http://quote.cloobot.ai:5003/'
})

export default httpClient;
export {testhttpClient};
export {dummyhttpClient};
export {PROD_BACK};
import React,{useState,useEffect,useRef} from 'react';
import PropTypes from 'prop-types';
import httpClient from '../../../../httpClient';
import botHttpClient from '../../../../bothttpClient';
import './chatTemplate.css'
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Popover from '@mui/material/Popover';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Avatar from '@mui/material/Avatar';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Chip from '@mui/material/Chip';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import produce from 'immer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Fab from '@mui/material/Fab';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/Card';
import CardContent from '@mui/material/Card';
import CardActions from '@mui/material/Card';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const ChatTemplate = (props) => {
  const [hovered, setHovered] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const cf_trunc_len = 15;
  // // console.log("\n\n\n\n\n props :: ",props)
  
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [SendMsgLoader, setSendMsgLoader] = useState(false);
  const [GetMsgListLoader, setGetMsgListLoader] = useState(false);
  const [widget, setwidgets] = useState([]);
  const [nomalmsg, setNormalmsg] = useState([]);
  const [message,setMessage] = useState([]);
  let [wholePageLoad,SetWholePageLoad] = useState(false);
  let [curr_submit_load_flag,setCurr_submit_load_flag] = useState(false);
  let SPECIAL_SUBMIT_NON_MUTLI = "dirrect_database_upload_non_multi_sub_form"
  const message_index = 0;
  let FORM_RESP_TYPE_FREE_TEXT = "free text"
  let FORM_RESP_TYPE_POS_NO = "Positive Number"
  let FORM_RESP_TYPE_POS_DEC = "Positive Decimal"
  let FORM_RESP_TYPE_INT = "Integer"
  let FORM_RESP_TYPE_DEC = "Decimal"
  let FORM_RESP_TYPE_DATE = "Date"
  let FORM_RESP_TYPE_TIME = "Time"
  let FORM_RESP_TYPE_TIME_IN_NO = "Time in Numbers"
  let FORM_RESP_TYPE_FILE_UPL = "file upload"
  let FORM_RESP_TYPE_NONE = "None"
  let FORM_RESP_DICT = {  FORM_RESP_TYPE_NONE:0,
                      FORM_RESP_TYPE_FREE_TEXT:1,
                      FORM_RESP_TYPE_POS_NO:2,
                      FORM_RESP_TYPE_POS_DEC:3,
                      FORM_RESP_TYPE_INT:4,
                      FORM_RESP_TYPE_DEC:5,
                      FORM_RESP_TYPE_DATE:6,
                      FORM_RESP_TYPE_TIME:9,
                      FORM_RESP_TYPE_TIME_IN_NO:10,
                      FORM_RESP_TYPE_FILE_UPL:0}
  let FORM_RESP_REV_DICT = {  0:FORM_RESP_TYPE_NONE,
                          1:FORM_RESP_TYPE_FREE_TEXT,
                          2:FORM_RESP_TYPE_POS_NO,
                          3:FORM_RESP_TYPE_POS_DEC,
                          4:FORM_RESP_TYPE_INT,
                          5:FORM_RESP_TYPE_DEC,
                          6:FORM_RESP_TYPE_DATE,
                          9:FORM_RESP_TYPE_TIME,
                          10:FORM_RESP_TYPE_TIME_IN_NO
                        }
  
  let FORM_SUB_RESP_TYPE_ALL = "ALL"
  let FORM_SUB_RESP_TYPE_FULL_NAME = "FULL_NAME"
  let FORM_SUB_RESP_TYPE_MAIL_ID = "MAIL_ID"
  let FORM_SUB_RESP_TYPE_PHONE = "PHONE_NUMBER"
  let FORM_SUB_RESP_TYPE_URL = "URL"
  let FORM_SUB_RESP_TYPE_PHONE_WITH_CONT_CODE = "PHONE_NUMBER_WITH_CONT_CODE"

  let FORM_SUB_RESP_REV_DICT = {  0:FORM_SUB_RESP_TYPE_ALL,
                              1:FORM_SUB_RESP_TYPE_FULL_NAME,
                              2:FORM_SUB_RESP_TYPE_MAIL_ID,
                              3:FORM_SUB_RESP_TYPE_PHONE,
                              4:FORM_SUB_RESP_TYPE_URL,
                              5:FORM_SUB_RESP_TYPE_PHONE_WITH_CONT_CODE}
  let ques_resp_error_msg_map = {normal:{},multiple:{}};
  let [subform_search_key,setsubform_search_key] = useState("");
  let isvisiblebulkUpload_formResp = false;
  let onLoadbulkUpload_formResp = false;
  let currBulkUploadForm_formResp =  null;
  let FileUploadDiasable_formResp = false;
  let visibleFileUpload_formResp = false;
  let [CurrBulkUplFormIndx,setCurrBulkUplFormIndx]  = useState(null);
  let [CurrBulkUplFormId,setCurrBulkUplFormId]  = useState(null);
  let [CurrBulkUplSubFormId,setCurrBulkUplSubFormId]  = useState(null);
  let [CurrBulkUplAddData,setCurrBulkUplAddData]  = useState({});
  let [CURR_FORM_IS_MULTI_SUB,setCURR_FORM_IS_MULTI_SUB]  =  useState(false);
  let [CURR_MULTI_SUBFORM_Q1_TOGGLE,setCURR_MULTI_SUBFORM_Q1_TOGGLE]  =  useState(false);
  let [CURR_MULTI_SUBFORM_TEMP,setCURR_MULTI_SUBFORM_TEMP]  = useState([]);
  let [CURR_MULTI_SUBFORM_TEMP_CACHE,setCURR_MULTI_SUBFORM_TEMP_CACHE]  = useState([]);
  let [CURR_MULTI_SUBFORM_ROW_INDX,setCURR_MULTI_SUBFORM_ROW_INDX] = useState(-1);
  let CURR_MULTI_SUBFORM_CF_INST_ID  = null;
  let [CURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE,setCURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE] = useState(null) ;
  let [CURR_MULTI_SUBFORM_TOTAL_PAGE_NO,setCURR_MULTI_SUBFORM_TOTAL_PAGE_NO] = useState(1) ;
  let CURR_MULTI_SUBFORM_TOTAL_ROWS  = 0;
  let [MULTI_SUB_BATCH_COUNT,setMULTI_SUB_BATCH_COUNT] = useState(15);
  let [onTableRowLoad,setonTableRowLoad]  =  useState(false);
  let multiSubFormWidth = 500;
  let [multisub_page_no,setmultisub_page_no] = useState(1);
  let filters  = [];
  let FETCH_ALL_LIMIT = 100;
  let EXP_FORM_MASTER = "MASTER_ALONE"
  let isProgressVisible  =  false;
  let [currBulkUpldPerct,setcurrBulkUpldProgPerct] = useState(0);
  let progressLoop ;
  let [curr_master_filter_list,setcurr_master_filter_list]  =  useState({"cfm":{},"seq":{}});
  let [curr_master_order_by_list,setcurr_master_order_by_list] = useState({"cfm":{},"seq":{}});
  let check_multi_sub_track  = {"all":false,"215360":true}
  let [expMasterTaskUpdates,setexpMasterTaskUpdates]  = useState([]);
  let img_formats = ["jpg","jpeg","png","gif","jpe",".jpg",".jpeg",".png",".gif",".jpe","..jpg","..jpeg","..png","..gif","..jpe"];
  let CURR_MULTI_SUB_HIDE_CURR_VAL = false;
  let MULTIPLE_SUBFORM_MODE_START = "multiple_subform_starts"
  let MULTIPLE_SUBFORM_MODE_END   = "multiple_subform_end"
  let CONVO_PARAM_SUBFORM_MODE_MULTI_CURR_INDX = "multiple_subform_mode_curr_indx"
  let SPECIAL_SUBMIT_TYPE = "dirrect_database_upload"
  let SPECIAL_SUBMIT_TYPE_DREAM_DASH = "dirrect_database_upload_dream_dash"
  let SPECIAL_SUBMIT_GRID_ROW_SUBMIT_DREAM_DASH = "dirrect_database_upload_grid_row_submit_dream_dash"
  let [curr_date_pick_mess_indx,setCurr_date_pick_mess_indx] = useState(null);
  let [curr_date_pick_form_indx,setCurr_date_pick_form_indx] = useState(null);
  let [curr_date_pick_ques_indx,setCurr_date_pick_ques_indx] = useState(null);
  let [curr_date_pick_form_type,setCurr_date_pick_form_type] = useState(null);
  let [curr_date_pick_is_multi,setCurr_date_pick_is_multi] = useState(null);
  let [curr_date_pick_sub_form_indx,setCurr_date_pick_sub_form_indx] = useState(null);
  let [curr_act_mess_indx,setCurr_act_mess_indx] = useState(null);
  let [curr_act_form_indx,setCurr_act_form_indx] = useState(null);
  let [curr_act_ques_indx,setCurr_act_ques_indx] = useState(null);
  let [curr_act_form_type,setCurr_act_form_type] = useState(null);
  let [curr_act_is_multi,setCurr_act_is_multi] = useState(null);
  let [curr_act_sub_form_indx,setCurr_act_sub_form_indx] = useState(null);
  let [visble_datepick,setvisble_datepick] = useState(false);
  let [visble_col_filtr,setvisble_col_filtr] = useState(false);
  let [datepickvar,setDatepickvar] = useState(null);
  let [fileuploadType,setfileuploadType] = useState('form_response');
  let [visibleFileUpload,setvisibleFileUpload] = useState(false);
  let [FileUploadDisable,setFileUploadDisable] = useState(false);
  let [temp_Upld_fileList,settemp_Upld_fileList] = useState([]);
  let [form_display_view,setform_display_view] = useState('multi_sub');
  let [curr_card_view_form_ind,setcurr_card_view_form_ind] = useState(null);
  let [imageObject,setimageObject] = useState([...[]]);
  let [currImgIndx,setcurrImgIndx] = useState([]);
  let [imgGalVisible,setimgGalVisible] = useState(false);
  let [updateNoteTaskform,setupdateNoteTaskform] = useState({
    description: "",
    attachments: [],
    task_id: "",
    revision: 0
  })
  let empyImgLink = "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
  let defFileImg = "https://vector.cloobot.ai/backend/database_download?file_id=8a1bdb62-8e7c-4587-812f-1f4f40b79093"
  let [BulkExImp,setBulkExImp] = useState(false);
  let EXCEL_EXTENSION = '.xlsx'
  let [fileStatusModal,setfileStatusModal] = useState(false);
  let [File_status_list,setFile_status_list] = useState([]);
  let [fullScreenToggle,setfullScreenToggle] = useState(0)
  let [MasterFilterCfIndx,setMasterFilterCfIndx] = useState(null);
  const sendmsg = (msg) => {
    sendApi(msg.msg, msg.msg_id, 'widget')
  }
  const sendmsg_text = (msg, msg_id)  =>{
    sendApi(msg, msg_id, 'text')
  }

  const sendApi = (msg, msg_id, type) => {
    setSendMsgLoader(true);
    try{
      let payloadGM = {
        token: localStorage.getItem('vector_token'),
        to: 'vector',
        message: msg,
        chat_type: type,
        message_id: msg_id,
        channel:"UI Chat"
      }

      botHttpClient.post('/getUiBotappmessage',payloadGM )
        .then(respBody => {
          let response = respBody.data;
          setSendMsgLoader(false);
          if (response.status) {
            // console.log("send msg success")
          } else {
            // console.log("send msg error")
          }
        })
        .catch(error => {
          console.error('sm Error 1:', error);
          setSendMsgLoader(false);
        });
    } catch (error) {
      setSendMsgLoader(false);
      console.error('sm Error 2:', error);
    }
  }
  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  const handleImageClick = (imageURL) => {
    // Set the selected image URL when an image name is clicked
    setSelectedImage(imageURL);
  };

  const handleImageHover = (imageURL) => {
    // Set the selected image URL on hover for displaying a larger version
    setSelectedImage(imageURL);
  };
  

  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);

  const handleCountClick = () => {
    setSelectedImageIndex(-1); // Reset selected index to show all images
  };


  const getMsgList = () => {
    setGetMsgListLoader(true);
    try{
      let payloadGM = {
        token: localStorage.getItem('vector_token'),
        to: 'vector',
        limit: 30,
      }

      httpClient.post('/getInitialMessages',payloadGM )
        .then(respBody => {
          let response = respBody.data;
          setGetMsgListLoader(false);
          setwidgets(response.data['widget']);
          setNormalmsg(response.data['normal']);
        })
        .catch(error => {
          console.error('getMsgList Error 1:', error);
          setGetMsgListLoader(false);
        });
    } catch (error) {
      setGetMsgListLoader(false);
      console.error('getMsgList Error 2:', error);
    }
  }

  const last_widg_has_notification = (input_data) => {
    let prev_succ = 'prev_success_notification' in input_data['data'] ? true : false
    if(prev_succ){
      // console.log("notification :: ",input_data['data']['prev_success_notification']);
      props.triggerNotification('success',input_data['data']['prev_success_notification']);
    }
  }
  const [filledRows, setFilledRows] = useState([]);

  const handleFillRow = (blockSno) => {
    // Toggle the filled status of the row
    setFilledRows((prevFilledRows) => {
      const isFilled = prevFilledRows.includes(blockSno);
      if (isFilled) {
        return prevFilledRows.filter((row) => row !== blockSno);
      } else {
        return [...prevFilledRows, blockSno];
      }
    });
  };

  const refresh_form = () => {
    SetWholePageLoad(true)
    try{
      let payloadGM = {
        token: localStorage.getItem('vector_token'),
        form_id : props.trigger_form_data.type_id
      }

      botHttpClient.post('/get-form-response',payloadGM )
        .then(respBody => {
          let response = respBody.data;
          // console.log("\n\nget-form-response resp : ",response,response.status)
          if(response.status){
            // console.log("ch1")
            SetWholePageLoad(false);
            setwidgets([response.data]);
            setMessage(response.data);
            // console.log("widget : ",response.data)
            last_widg_has_notification(response.data)
            // refresh_ques_resp_error_map()
            // console.log("call last_widg_has_multiple_subform")
            last_widg_has_multiple_subform(response.data["data"])
          }else{
            // console.log('message_service warning', 'Something went wrong');
            props.triggerNotification('warning', 'Something went wrong');
          }
        })
        .catch(error => {
          console.error('sm Error 1:', error);
          SetWholePageLoad(false);
        });
    } catch (error) {
      SetWholePageLoad(false);
      console.error('sm Error 2:', error);
    }
  }

  useEffect(() => {
    refresh_form();
  }, []
  );

  function displayCurTime(ref){
    let new_time = new Date();
    // console.log("time ",ref, " :: ",new_time,new_time.getMilliseconds());
  }
  const handleAllInOneResp = (formIndex, QuesIndx, Response,form_type,mul_sub_index = null,file_data = null) => {
    // console.log("\n\n\nform_type :: ",form_type);
    if(form_type !== 'multi_subform'){
      setMessage((prevMessage) =>
        produce(prevMessage, (draft) => {
          if(file_data == null){
            draft.data.form_list[formIndex].ques_resp_list.data.list[QuesIndx].response = [7,8,11].includes(draft.data.form_list[formIndex].ques_resp_list.data.list[QuesIndx].free_text) ? (Response ? Response.id : "") : Response;
          }else{
            draft.data.form_list[formIndex].ques_resp_list.data.list[QuesIndx].user_resp_files.push(file_data);
          }
        })
      );
      // console.log("\n\nmessage :: ",message)
      if([7, 8, 11].includes(message.data.form_list[formIndex].ques_resp_list.data.list[QuesIndx].free_text)){
        onOptionSelect(message.data.form_list[formIndex].ques_resp_list.data.list,
          message.data.form_list[formIndex].ques_resp_list.data.list[QuesIndx],
          message,
          formIndex,
          QuesIndx,
          Response,
          'all_in_one')
      }
    }else{
      // // displayCurTime("t1")
      if([7, 8, 11].includes(CURR_MULTI_SUBFORM_TEMP[mul_sub_index][QuesIndx].free_text)){
        onOptionSelect(CURR_MULTI_SUBFORM_TEMP[mul_sub_index],
          CURR_MULTI_SUBFORM_TEMP[mul_sub_index][QuesIndx],
          message_index,
          formIndex,
          QuesIndx,
          CURR_MULTI_SUBFORM_TEMP[mul_sub_index][QuesIndx]['response'],
          'all_in_one',
          true,
          mul_sub_index)
      }
      // displayCurTime("t2")
      setCURR_MULTI_SUBFORM_TEMP((prevMessage) =>
        {
          const updatedCURR_MULTI_SUBFORM_TEMP = produce(prevMessage, (draft) => {
          draft[mul_sub_index][QuesIndx]['response'] = ([7, 8, 11].includes(CURR_MULTI_SUBFORM_TEMP[mul_sub_index][QuesIndx].free_text)) ? (Response ? Response.id : "") : Response;
          
        });
        on_multi_subform_row_update(mul_sub_index,updatedCURR_MULTI_SUBFORM_TEMP[mul_sub_index]);
        return updatedCURR_MULTI_SUBFORM_TEMP;
      }
      );
    }
  };

  function reloadOptions(req_type,ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,is_multiple_subform = false,mul_sub_index = null){
    // console.log("\n\n\n\nreloadOptions :: ",req_type,curr_resp);
    refresh_option(req_type,ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,undefined,is_multiple_subform,mul_sub_index)  
  }

  function get_ques_resp_map(ques_list,ques,form_type){
    let quest_id_resp_map = {}
    if (form_type ==='all_in_one'){
      for (let i = 0; i < ques_list.length; i++) {
        quest_id_resp_map[String(ques_list[i]["ques_id"])] = ques_list[i]["response"]
      }
    }else{
      quest_id_resp_map[ques_list["ques_id"]] = ques["response"]
    }
    return quest_id_resp_map
  }

  function check_for_master_cf(ques,signle_opt_resp,is_multiple_subform,mess_indx,formIndex,ques_indx,mul_sub_index){
    // // console.log("check :: ",signle_opt_resp)
    if("master_cust_fields" in ques){
      // // console.log("sss")
      try{
        let payloadTCV = {
          token: localStorage.getItem('vector_token'),
          task_id:signle_opt_resp,
          cf_key_list:ques['master_cust_fields']
        }
  
        httpClient.post('/task/getTaskCustomFiledsValue',payloadTCV )
          .then(respBody => {
              let res = respBody.data;
              // // console.log("res :: ",res)
              if(res.status){
                if(!is_multiple_subform){
                  widget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['master_cf_value_pairs'] = res.data
                }else{
                  CURR_MULTI_SUBFORM_TEMP[mul_sub_index][ques_indx]['master_cf_value_pairs'] = res.data 
                }
              }
            }).catch(error => {
              console.error('getTaskCustomFiledsValue Error 1:', error);
            });
        } catch (error) {
          console.error('getTaskCustomFiledsValue Error 2:', error);
        }
    }
    return true
  }

  function refresh_option(req_type,ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,ques_reps_map,is_multiple_subform = false,mul_sub_index = null){
    // console.log("inside refresh_option",ques['free_text'],req_type,curr_resp)
    if((ques['free_text'] === 7 || ques['free_text'] === 8  || (ques['free_text'] === 8 && req_type === 'search') || (ques['free_text'] === 11 && req_type === 'search')) && ((req_type !== 'search' && (((typeof curr_resp) === 'boolean' && curr_resp) || ((typeof curr_resp) === 'string' && curr_resp === ''))) || (req_type === 'search' ) || (req_type === 'refresh' )) ){
      // console.log("pass refresh_option")
      if(req_type !== 'search'){
        curr_resp = '' //event give boolean
      }
      let quest_id_resp_map = null
      // console.log("ques_reps_map :: ",ques_reps_map)
      if(ques_reps_map !== undefined){
        quest_id_resp_map = ques_reps_map
      }else{
        quest_id_resp_map = get_ques_resp_map(ques_list,ques,form_type)
        // console.log("quest_id_resp_map :: ",quest_id_resp_map)
      }
      let child_inst_ref = null;
      if(!is_multiple_subform){
        child_inst_ref = ques["cf_instance_id"]
      }else{
        child_inst_ref = widget[mess_indx]['data']['bulk_ques_resp_list']['subform_cf_instance_id']
      }
      try{
        let payloadGM = {
          phone:null,
          token:localStorage.getItem('vector_token'),
          search_key: curr_resp,
          quest_id: ques["ques_id"],
          instance_id:child_inst_ref,
          form_id:ques["form_id"],
          quest_id_resp_map:quest_id_resp_map,
          no_of_opt_limit:true,
          resp_type:ques['free_text'],
          is_multi_subform:is_multiple_subform,
          mul_subform_indx:mul_sub_index,
          search_map_need : true
        }
        // console.log("\n\n\n\n\npayload QuestResponseRefresh :: ",payloadGM)
        httpClient.post('/bot/QuestResponseRefresh',payloadGM )
          .then(respBody => {
            // console.log("resp QuestResponseRefresh :: ",respBody)
            let resp = respBody.data;
            let new_opt_list = []
            let alph_no = 1
            let master_list = resp["data"]["master_task_list"];
            for (let k = 0; k < master_list.length; k++) {
              // if(ques['option_as_no'])
              let new_opt;
              if (form_type ==='all_in_one'){
                new_opt = {"id":master_list[k]['option_id'], "val": master_list[k]['option_value'],"as": [String(ques_indx + 1) + "," + String(alph_no)] }
              }
              else{
                new_opt = { "id":master_list[k]['option_id'],"val": master_list[k]['option_value'],"as": [String(k+1)] }
              }
              new_opt_list.push(new_opt)
              alph_no += 1
            }
            if (form_type ==='all_in_one'){
              if(!is_multiple_subform){
                setwidgets((prevMessage) =>
                  produce(prevMessage, (draft) => {
                    draft[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['options'] = new_opt_list
                  })
                );
              }else{
                const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
                updatedResponses[mul_sub_index][ques_indx] = {
                  ...updatedResponses[mul_sub_index][ques_indx],
                  options: new_opt_list,
                };
                setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
              }
              
              if(req_type === 'refresh' && new_opt_list.length === 1 && ques['auto_select_single']){
                check_for_master_cf(ques,new_opt_list[0]['id'],is_multiple_subform,mess_indx,formIndex,ques_indx,mul_sub_index)
                if(!is_multiple_subform){
                  const updatedWidget = [...widget];
                  updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['response'] = new_opt_list[0]['id']
                  setwidgets(updatedWidget);
                }else{
                  const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
                  updatedResponses[mul_sub_index][ques_indx]['response'] = new_opt_list[0]['id']
                  setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
                  on_multi_subform_row_update(mul_sub_index)
                }
              }
            }
            else{
              const updatedWidget = [...widget];
              updatedWidget[mess_indx]['data']['options'] = new_opt_list
              if(req_type === 'refresh' && new_opt_list.length === 1 && ques['auto_select_single']){
                updatedWidget[mess_indx]['data']['response'] = new_opt_list[0]['id']
              }
              setwidgets(updatedWidget);
            }
          })
          .catch(error => {
            console.error('QuestResponseRefresh Error 11:', error);
          });
      } catch (error) {
        console.error('QuestResponseRefresh Error 12:', error);
      }
    }
  }
  

  function get_selected_master_cf(ques,mess_indx,formIndex,ques_indx,is_multiple_subform = false,mul_sub_index = null){
    if('master_cust_fields' in ques){
      try{
        let payloadTCV = {
          token: localStorage.getItem('vector_token'),
          task_id:ques['response'],
          cf_key_list:ques['master_cust_fields']
        }
        httpClient.post('/task/getTaskCustomFiledsValue',payloadTCV )
          .then(respBody => {
            let res = respBody.data;
            // // console.log("res :: ",res)
            if(res.status){
              if(!is_multiple_subform){
                widget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['master_cf_value_pairs'] = res.data
              }else{
                CURR_MULTI_SUBFORM_TEMP[mul_sub_index][ques_indx]['master_cf_value_pairs'] = res.data
              }
            }
          }).catch(error => {
            console.error('getTaskCustomFiledsValue Error 1:', error);
          });
        } catch (error) {
          console.error('getTaskCustomFiledsValue Error 2:', error);
        }
      }
  }

  function onOptionSelect(ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,is_multiple_subform = false,mul_sub_index = null){
    // console.log("option selected :: ",ques)
    if(ques['free_text'] === 7){
      let quest_id_resp_map = get_ques_resp_map(ques_list,ques,form_type)
      if ("is_master_ques" in ques){
        if (form_type ==='all_in_one'){
          for (let k = 0; k < ques_list.length; k++) {
            let edit_task_hint = ""
            if("edit_task_conf" in ques_list[k]){
              let payload = {
                phone:null,
                token:localStorage.getItem('vector_token'),
                form_id:ques_list[k]['form_id'],
                ques_id:ques_list[k]['ques_id'],
                instance_id:ques_list[k]['cf_instance_id'],
                edit_task_conf:ques_list[k]['edit_task_conf'],
                quest_id_resp_map : quest_id_resp_map
              }
              try{
                httpClient.post('/bot/hintEditTaskPfa',payload )
                  .then(respBody => {
                    let res = respBody.data;
                    edit_task_hint = res.data
                    let new_hint_format = " <span style='font-style: italic;'>Current Value : " + edit_task_hint + "</span>"
                    if(!is_multiple_subform){
                      const updatedWidget = [...widget];
                      updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][k]['edit_task_hint'] = new_hint_format
                      updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][k]['edit_task_hint_val'] = edit_task_hint
                      // MASTER_RESP_TYPE.indexOf(widget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][k]['free_text']) == -1 &&
                      if(widget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][k]['overwrite_resp_flag'] && edit_task_hint.length ){
                        updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][k]['response'] = edit_task_hint
                      }
                      setwidgets(updatedWidget);
                    }else{
                      const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
                      updatedResponses[mul_sub_index][k]['edit_task_hint'] = new_hint_format
                      updatedResponses[mul_sub_index][k]['edit_task_hint_val'] = edit_task_hint
                      if(CURR_MULTI_SUBFORM_TEMP[mul_sub_index][k]['overwrite_resp_flag'] && edit_task_hint.length){
                        updatedResponses[mul_sub_index][k]['response'] = edit_task_hint
                        on_multi_subform_row_update(mul_sub_index)
                      }
                      setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
                    }
                  })
                  .catch(error => {
                    console.error('hintEditTaskPfa Error 1:', error);
                  });
                } catch (error) {
                  console.error('hintEditTaskPfa Error 2:', error);
                }
            }
          }
        }
      }
      // // console.log("curr)_ques :: ",ques)
      if ('refresh_after_resp' in ques){
        // // console.log("\n\n refreesh option enable")
        if (form_type ==='all_in_one'){
          if(ques_indx < ques_list.length - 1){
            for (let k = ques_indx + 1; k < ques_list.length; k++) {
              // if(k !== ques_indx && !('refresh_after_resp' in ques_list[k])){
                refresh_option('refresh',ques_list,ques_list[k],mess_indx,formIndex,k,'','all_in_one',quest_id_resp_map,is_multiple_subform,mul_sub_index)
              // }
            }
          }
        }
      }
      get_selected_master_cf(ques,mess_indx,formIndex,ques_indx,is_multiple_subform,mul_sub_index)
    }
    if(is_multiple_subform){
      on_multi_subform_row_update(mul_sub_index)
      // no multi as for now
    }
  }

  function formsubMit(data, msg_id,submit_type) {
    // console.log("\n\n\n\nform submit check :: ",data)
    if(!curr_submit_load_flag){
      // console.log("formed submit check")
      dirrect_submit_single_primary_form(data)
    }else{
      // console.log("submit ignored")
    }
  }

  function get_resp_check_non_multi_sub(ques_block){
    let resp = ''
    let file_list = [];
    if (
      ques_block.options.length === 0 &&
      ques_block.free_text >= 1
    ) {
      if (ques_block['response'] !== '') {
        resp = ques_block['response']
      }
    } else if (
      ques_block.options.length !== 0 &&
      (ques_block.free_text < 1 || ques_block.free_text === 7 || ques_block.free_text === 8 || ques_block.free_text === 11)
    ) {
      if (ques_block['response'] !== '') {
        resp = ques_block['response'];
      }
    } else if (ques_block.file_upload === 1) {
        file_list = ques_block['user_resp_files']
    } else {
      resp = ques_block['response']
    }
    // console.log("\n\n check :: ",ques_block.free_text,resp)
    return {"resp":resp,"file_list":file_list}
  }

  function closeAfterSubmit(){
    // console.log("\n\nclose after submit trigger")
    props.CloseWidget(props.trigger_section_index,props.trigger_comp_type,props.trigger_comp_index);
  }

  function dirrect_submit_single_primary_form(data){
    setCurr_submit_load_flag(true);
    let whole_struct = [];
    for (let k = 0; k < data.length; k++) {
      let forms_data = data[k];
      let ques_block_list = data[k]['ques_resp_list']['data']['list']
      let temp_cf_instance_id = forms_data["cf_instance_id"]
        
      let new_form = {
            form_type : "form", 
            form_id : ques_block_list[0]['form_id'],
            cf_instance_id : temp_cf_instance_id,
            ques_list : []
          }

      for (let i = 0; i < ques_block_list.length; i++) {
        let response_data = get_resp_check_non_multi_sub(ques_block_list[i])
        // console.log("response_data ::",response_data)
        let resp = response_data["resp"]
        let file_list = response_data["file_list"]
        new_form.ques_list.push({
                            ques_id : ques_block_list[i]['ques_id'],
                            resp_type : ques_block_list[i].free_text,
                            response : resp,
                            file_list : file_list,
                            file_upload_flag : ques_block_list[i].file_upload,
                            task_id : ques_block_list[i].task_id,
                            revision : ques_block_list[i].revision,
                            contrib_id : ques_block_list[i].contrib_id,
                            custom_field : ques_block_list[i].custom_field,
                            scope : ques_block_list[i].scope,
                            task_assct_flag : ques_block_list[i].task_assct_flag
                          })
        }
      whole_struct.push(new_form)
    }
    let out_resp = {}
    out_resp[SPECIAL_SUBMIT_NON_MUTLI] = whole_struct
    // console.log("whole_struct :: ",whole_struct)
    try{
      let payload = {
        token: localStorage.getItem('vector_token'),
        response : out_resp,
      }
      botHttpClient.post('/uibot/submitGridRow',payload )
      .then(res => {
        curr_submit_load_flag = false;
        // console.log("before submit :: ",props.triggerNotification);
        props.triggerNotification('success', 'submitted successfully');
        closeAfterSubmit();
      })
      .catch(error => {
        console.error('submitGridRow Error 1:', error);
      });
    } catch (error) {
      console.error('submitGridRow Error 2:', error);
    }
  }

  function updateValidationobj(ques_id,validation_msg,is_mul_sub,mul_sub_indx){
    // // console.log("\n on update :: ",ques_id,validation_msg,is_mul_sub,mul_sub_indx)
    if(!is_mul_sub){
      ques_resp_error_msg_map['normal'][ques_id] = validation_msg
    }else{
      if(mul_sub_indx in ques_resp_error_msg_map['multiple']){
        ques_resp_error_msg_map['multiple'][mul_sub_indx][ques_id] = validation_msg
      }else{
        ques_resp_error_msg_map['multiple'][mul_sub_indx] = {}
        ques_resp_error_msg_map['multiple'][mul_sub_indx][ques_id] = validation_msg
      }
      
    }
  }

  function validate_response_format(ques_id,ques_data,resp_no,user_resp,is_mul_sub = false,mul_sub_indx = 0){
    // // console.log("\n\nrecent quesit ::",ques_id)
    let resp_type = get_resp_format(user_resp)
    let curr_ques_free_txt_flag = resp_no
    // // console.log("received resp type :: ",user_resp," :: ",resp_type,FORM_RESP_REV_DICT[curr_ques_free_txt_flag])
    let int_array = [('Positive ' + FORM_RESP_TYPE_INT),('Negative ' + FORM_RESP_TYPE_INT)] 
    let dec_arr = [('Positive ' + FORM_RESP_TYPE_INT),('Negative ' + FORM_RESP_TYPE_INT),('Negative ' + FORM_RESP_TYPE_DEC),'Positive ' + FORM_RESP_TYPE_DEC]
    let pos_dec = [FORM_RESP_TYPE_POS_DEC,('Positive ' + FORM_RESP_TYPE_INT)]
    if (curr_ques_free_txt_flag > 1 && user_resp.length > 0){
        updateValidationobj(ques_id,"",is_mul_sub,mul_sub_indx)
        if ((FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_POS_NO) && (resp_type !== ('Positive ' + FORM_RESP_TYPE_INT))){
          // // console.log("p1")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +"'"),is_mul_sub,mul_sub_indx)
          return "error"
        }    
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_POS_DEC && pos_dec.indexOf(resp_type) === -1){
          // // console.log("p2")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +"'"),is_mul_sub,mul_sub_indx)
          return "error"
        }
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_INT &&  int_array.indexOf(resp_type) === -1 ){
          // // console.log("p3")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +"'"),is_mul_sub,mul_sub_indx)
          return "error"
        }
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_DEC && dec_arr.indexOf(resp_type) === -1 ){
          // // console.log("p4")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +"'"),is_mul_sub,mul_sub_indx)
          return "error"
        }
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_DATE && resp_type !== FORM_RESP_TYPE_DATE ){
          // // console.log("p5")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +" (dd/mm/yy)'"),is_mul_sub,mul_sub_indx)
          return "error"
        }
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_TIME && resp_type !== FORM_RESP_TYPE_TIME ){
          // // console.log("p5")
          updateValidationobj(ques_id,("Response should  be '" + FORM_RESP_REV_DICT[resp_no] +" (HH:MM)'"),is_mul_sub,mul_sub_indx)
          return "error"
        }
        else if (FORM_RESP_REV_DICT[curr_ques_free_txt_flag] === FORM_RESP_TYPE_TIME_IN_NO && (resp_type !== FORM_RESP_TYPE_TIME && resp_type !== FORM_RESP_TYPE_TIME_IN_NO) ){
          // // console.log("p5")
          updateValidationobj(ques_id,("Response should be 'hh:mm Eg : 156:50'"),is_mul_sub,mul_sub_indx)
          return "error"
        }

        if("advanced_features" in ques_data){
          // // console.log(" r1 :: ",user_resp,FORM_RESP_TYPE_DEC)
            let user_resp_num_val = null
            if(resp_type.includes(FORM_RESP_TYPE_DEC)){
                user_resp_num_val = parseFloat(user_resp)
            }else if(resp_type.includes(FORM_RESP_TYPE_INT)){
                user_resp_num_val = parseInt(user_resp)
            }
            
            if( user_resp_num_val != null){
              // // console.log(" r2 :: ",('range_data' in ques_data['advanced_features']),', r22 :: ',ques_data['advanced_features']['range_data']['isRangeTrue'],",r222 :: ",ques_data['advanced_features']['range_data']['isMin'],", r2222 :: ",ques_data['advanced_features']['range_data']['isMax'])
                if (('range_data' in ques_data['advanced_features']) && (ques_data['advanced_features']['range_data']['isRangeTrue'] && (ques_data['advanced_features']['range_data']['isMin'] || ques_data['advanced_features']['range_data']['isMax']))){
                  // // console.log(" r33 :: ")  
                  if(ques_data['advanced_features']['range_data']['isMin'] && ques_data['advanced_features']['range_data']['minRange'] != null && parseFloat(ques_data['advanced_features']['range_data']['minRange']) >  user_resp_num_val){
                      updateValidationobj(ques_id,("Response value should be greater than or equal to " + ques_data['advanced_features']['range_data']['minRange']),is_mul_sub,mul_sub_indx)
                      // // console.log(" r3 :: ")
                      return "error"
                    }
                    if(ques_data['advanced_features']['range_data']['isMax'] && ques_data['advanced_features']['range_data']['maxRange'] != null && parseFloat(ques_data['advanced_features']['range_data']['maxRange']) <  user_resp_num_val){
                      updateValidationobj(ques_id,("Response value should be lesser than or equal to " + ques_data['advanced_features']['range_data']['maxRange']),is_mul_sub,mul_sub_indx)
                      // // console.log(" r4 :: ")
                      return "error"
                    }
                }
                
                  // // console.log(" r5 :: ",('link_data' in ques_data['advanced_features']),', r55 :: ',ques_data['advanced_features']['link_data']['isLinkTrue'],",r555 :: ",ques_data['advanced_features']['link_data']['isMin'],", r5555 :: ",ques_data['advanced_features']['link_data']['isMax'])
                if(('link_data' in ques_data['advanced_features']) && (ques_data['advanced_features']['link_data']['isLinkTrue'] && (ques_data['advanced_features']['link_data']['isMin'] || ques_data['advanced_features']['link_data']['isMax']))){
                  // // console.log(" r6 :: ")
                  if('edit_task_conf' in ques_data){
                      if ('edit_task_hint_val' in ques_data){
                        // // console.log(" r77 :: ",ques_data['edit_task_hint_val'])
                        let edit_task_hint_val = ques_data['edit_task_hint_val']
                        if(edit_task_hint_val){
                          // // console.log(" r8 :: ",edit_task_hint_val,", r88 :: ",user_resp_num_val,", r888 ::",ques_data['advanced_features']['link_data']['minRange'])
                          edit_task_hint_val = parseFloat(edit_task_hint_val)
                            let rangeDiff = user_resp_num_val - edit_task_hint_val
                            if(ques_data['advanced_features']['link_data']['isMin'] && ques_data['advanced_features']['link_data']['minRange'] != null  && parseFloat(ques_data['advanced_features']['link_data']['minRange']) >  rangeDiff){
                              // // console.log(" r9 :: ",)
                              updateValidationobj(ques_id,("Difference between the current value(" + String(edit_task_hint_val) + ") and your response value should be greater than or equal to " + ques_data['advanced_features']['link_data']['minRange']),is_mul_sub,mul_sub_indx)
                              return "error"
                            }
                            if(ques_data['advanced_features']['link_data']['isMax'] && ques_data['advanced_features']['link_data']['maxRange'] != null && parseFloat(ques_data['advanced_features']['link_data']['maxRange']) <  rangeDiff){
                              // // console.log(" r10 :: ")
                              updateValidationobj(ques_id,("Difference between the current value(" + String(edit_task_hint_val) + ") and your response value should be lesser than or equal to " + ques_data['advanced_features']['link_data']['maxRange']),is_mul_sub,mul_sub_indx)
                              return "error"
                            }
                        }
                      }
                  }
                }
                
            }
        }
    }else if(curr_ques_free_txt_flag === 1 && user_resp.length > 0 && FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] !== FORM_SUB_RESP_TYPE_ALL  ){
      if(FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] === FORM_SUB_RESP_TYPE_FULL_NAME && (!is_full_name(user_resp))){
        updateValidationobj(ques_id,("Response format should be 'FIRSTNAME SECONDNAME' Eg : William jones"),is_mul_sub,mul_sub_indx)
        return "error"
      }else if(FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] === FORM_SUB_RESP_TYPE_MAIL_ID && (!is_mail(user_resp))){
        updateValidationobj(ques_id,("Response format should be 'abc@domain.xyz' Eg : johnsmith@gmail.com"),is_mul_sub,mul_sub_indx)
        return "error"
      }else if(FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] === FORM_SUB_RESP_TYPE_PHONE && (!is_phone_no(user_resp))){
        updateValidationobj(ques_id,("Response format should be 10 digits Eg : 8834010237"),is_mul_sub,mul_sub_indx)
        return "error"
      }else if(FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] === FORM_SUB_RESP_TYPE_URL && (!is_url(user_resp))){
        updateValidationobj(ques_id,("Response format should be 'https://www.example.com/' Eg : https://www.google.com/"),is_mul_sub,mul_sub_indx)
        return "error"
      }else if(FORM_SUB_RESP_REV_DICT[ques_data['sub_resp_type']] === FORM_SUB_RESP_TYPE_PHONE_WITH_CONT_CODE && (!is_phone_no_with_cont_code(user_resp))){
        updateValidationobj(ques_id,("Response format should be 10-12 digits with country code Eg : +918834010237"),is_mul_sub,mul_sub_indx)
        return "error"
      }
    }
    else{
      return 'success'
    }
    return 'success'  
  }

  function get_resp_format(resp){
    resp = String(resp)
    // // console.log("\n validate resp :: ",resp)
    // // console.log("resp :: ",resp," cond :: resp.indexOf('.') :: ",resp.indexOf('.'),",sec :: ",isdigit(resp.replace('.','').replace('-','')),", third :: ",isdigit(resp.replace('-','')))
    if(resp.indexOf('/') > -1){
      if(resp.match("^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/]([0]?[1-9]|[1][0-2])[/]([0-9]{2})$")){
        return FORM_RESP_TYPE_DATE
      }
      else{
        return "Not " + FORM_RESP_TYPE_DATE
      }
    }else if(resp.indexOf(':') > -1){ //check for time
      if(resp.match("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$")){
        return FORM_RESP_TYPE_TIME
      }
      else if(resp.match("^[0-9]+:[0-5][0-9]$")){
        return FORM_RESP_TYPE_TIME_IN_NO
      }
      else{
        return "Not " + FORM_RESP_TYPE_TIME
      }
    }
    else{if((resp.indexOf('.') > -1) && (isdigit(resp))){
              if((resp.indexOf('-') > -1)){
                return 'Negative ' + FORM_RESP_TYPE_DEC 
              }    
              else{
                return 'Positive ' + FORM_RESP_TYPE_DEC
              }    
            }
        else if(isdigit(resp)){
            if (resp.indexOf('-') > -1){
              return 'Negative ' + FORM_RESP_TYPE_INT
            }   
            else{
              return 'Positive ' + FORM_RESP_TYPE_INT
            }
                
        }}
            
  }

  function isdigit(str){
    return !isNaN(str) && isFinite(str) && !/e/i.test(str)
  }

  function validate_mandatory(ques){
    if( ques['mandatory'] &&
        (
          (ques['file_upload'] === 0 && (ques['response'] === '' || ques['response'] === null)) ||
          (ques['file_upload'] === 1 && ques['user_resp_files'].length === 0)
        )
      ){
        return false
      }
      return true
    }

    function get_validate_message(ques_id,resp_type,is_mul_sub,mul_sub_indx = null){
      // // console.log("ques_resp_error_msg_map :: ",ques_resp_error_msg_map,ques_id,is_mul_sub,mul_sub_indx)
      if(!is_mul_sub){
        if(ques_id in ques_resp_error_msg_map['normal'] && ques_resp_error_msg_map['normal'][ques_id] !== ""){
          return ques_resp_error_msg_map['normal'][ques_id]
        }
      }else{
        if(ques_resp_error_msg_map['multiple'][mul_sub_indx] && ques_id in ques_resp_error_msg_map['multiple'][mul_sub_indx] && ques_resp_error_msg_map['multiple'][mul_sub_indx][ques_id] !== ""){
          return ques_resp_error_msg_map['multiple'][mul_sub_indx][ques_id]
        }
      }
    }

    function refresh_ques_resp_error_map(){
      ques_resp_error_msg_map = {'normal':{},'multiple':{}}
    }

    function is_mail(inp){
      if(inp.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g)){
        return true;
      }   
      return false;
    }

    function is_full_name(inp){
      
      if(inp.match(/^[A-Za-z]+\s[A-Za-z]+$/g)){
        return true;
      }
      return false;
    }

    function is_phone_no(inp){
      if(inp.match(/^[0-9]{10}$/g)){
        return true;
      } 
      return false;
    }

    function is_phone_no_with_cont_code(inp){
      if(inp.match(/^\+?([0-9]{2})\)?[-]?([0-9]{3})?[-]?([0-9]{3})?[-]?([0-9]{4})$/g)){
        return true;
      } 
      return false;
    }

    function is_url(inp){
      if(inp.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)){
        return true;
      } 
      return false;
    }

    function validate_mandatory(ques){
    if( ques['mandatory'] &&
        (
          (ques['file_upload'] === 0 && (ques['response'] === '' || ques['response'] === null)) ||
          (ques['file_upload'] === 1 && ques['user_resp_files'].length === 0)
        )
      ){
        return false
      }
      return true
    }

    function have_bulk_subform(input_data){
      // // console.log("\n\n\n have_bulk_subform :: ",input_data)
      return 'bulk_ques_resp_list' in input_data ? true : false
    }

    function assign_multi_subform_table_width(multi_subform_list,master_cf,each_row_submit){
      let col_count = multi_subform_list[0].length;
      if(each_row_submit){
        col_count += 1;
      }
  
      let master_cf_width = 0;
      for(let i=0;i<master_cf.length;i++){
        master_cf_width += Number(master_cf[i].col_size)
      }
      // console.log("master_cf_width :: ",master_cf_width)
      multiSubFormWidth = (col_count * 200) + (master_cf_width)
      // console.log("multiSubFormWidth :: ",multiSubFormWidth)
    }

    function store_userResp_TempCacheJson_serv(multi_subform_resp_cache){
      // // console.log("store_userResp_TempCacheJson_serv :: ",multi_subform_resp_cache)
      localStorage.setItem("multi_subform_resp_cache",JSON.stringify(multi_subform_resp_cache));
      return true
    }

    function store_userResp_TempCacheJson(multi_subform_resp_cache){
      store_userResp_TempCacheJson_serv(multi_subform_resp_cache);
    }

    function write_all_rows_inside_response_tracker(msg_data){
      let ques_resp_list = msg_data['bulk_ques_resp_list']['ques_block_list'];
      let single_ques_resp_temp = Object.assign([], msg_data['bulk_ques_resp_list']['ques_block_list'][0])
        // console.log("d2")
        if(msg_data['bulk_ques_resp_list']['q1_toggle']){
          if(ques_resp_list.length > 15){
            
                try{
                  let payload = {
                    phone:null,
                    token:localStorage.getItem('vector_token'),
                    search_key: subform_search_key,
                    quest_id: ques_resp_list[0][0]["ques_id"],
                    instance_id:msg_data['bulk_ques_resp_list']['subform_cf_instance_id'],
                    form_id:ques_resp_list[0][0]["form_id"],
                    resp_type:ques_resp_list[0][0]["free_text"],
                    ques_resp_list_struct : single_ques_resp_temp,
                    construct_for_submit_flag : false,
                    already_exist_resp : null,
                    page_no : 1,
                    master_filter_list : null,
                    master_order_list : null,
                    page_size : FETCH_ALL_LIMIT
                  }
                  httpClient.post('/bot/searchMultiSubformFixedMaster',payload )
                  .then(response => {
                    let resp = response.data;
                    if(resp["data"].length > 0){
                      let multi_subform_resp_cache = {};
                      for(let i=0;i < resp["data"].length;i++){
                        let key = resp["data"][i][0]['response'];
                        multi_subform_resp_cache[String(key)] = resp["data"][i];
                      }
                      // console.log("searchMultiSubformFixedMaster all :: ",multi_subform_resp_cache)
                      store_userResp_TempCacheJson(multi_subform_resp_cache)
                    }
                  })
                  .catch(error => {
                    console.error('submitGridRow Error 1:', error);
                  });
                } catch (error) {
                  console.error('submitGridRow Error 2:', error);
                }
          }
          else{
            // console.log("d3 :: ",ques_resp_list.length)
            let multi_subform_resp_cache = {};
            for(let i=0;i < ques_resp_list.length;i++){
              let key = ques_resp_list[i][0]['response'];
              multi_subform_resp_cache[String(key)] = ques_resp_list[i];
            }
            // console.log("multi_subform_resp_cache :: ",multi_subform_resp_cache)
            store_userResp_TempCacheJson(multi_subform_resp_cache)
          }
        }else{
          // console.log("d3 :: ",ques_resp_list.length)
            let multi_subform_resp_cache = {};
            for(let i=0;i < ques_resp_list.length;i++){
              let key = i;
              multi_subform_resp_cache[String(key)] = ques_resp_list[i];
            }
            // console.log("multi_subform_resp_cache :: ",multi_subform_resp_cache)
            store_userResp_TempCacheJson(multi_subform_resp_cache)
        }
    }

    function filter_subform_multiple(mess_indx){
      setCURR_MULTI_SUBFORM_TEMP(widget[mess_indx]['data']['bulk_ques_resp_list']['ques_block_list'].filter(each_set => (each_set[0]["response_preview"].toLowerCase().includes(subform_search_key.toLowerCase()))));
    }

    function reset_multisub(){
      // console.log("on reset");
      for(let mcf_indx = 0;mcf_indx < message['data']['bulk_ques_resp_list']['master_cf_ordered_list'].length;mcf_indx ++ ){
        OnResetMultiSubFilters(message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][mcf_indx],mcf_indx,false)
        message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][mcf_indx]['order_by'] = ""
      }
      setcurr_master_order_by_list({"cfm":{},"seq":{}});
      setcurr_master_filter_list({"cfm":{},"seq":{}});
      setTimeout(() => {
        setmultisub_page_no(1);
        onFixedMasterSearch(message_index,1,15);
      }, 500);
    }

    function refresh_mutiSub(){
      // console.log("on refresh :: ");
      setmultisub_page_no(1);
      onFixedMasterSearch(message_index,1);
    }

    function OnResetMultiSubFilters(cf_data,cf_indx,refresh_flag = true){
      const updatedmessage = {...message};
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['filter_visible'] = false;
      if('selected_filters' in cf_data){
        let tempcurr_master_filter_list = {...curr_master_filter_list};
        for(let mcf_indx = 0;mcf_indx < cf_data.selected_filters.length;mcf_indx ++ ){
          if(cf_data.cfm_id){
            if(cf_data.cfm_id in curr_master_filter_list["cfm"] ){
              tempcurr_master_filter_list["cfm"][cf_data.cfm_id] = []
            }
          }
          if(cf_data.seq_id){
            if(cf_data.seq_id in curr_master_filter_list["seq"]){
              tempcurr_master_filter_list["seq"][cf_data.seq_id] = []
            }
          }
        }
        setcurr_master_filter_list(tempcurr_master_filter_list);
      }
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['selected_filters'] = []
      setMessage(updatedmessage);
      if(refresh_flag){
        setmultisub_page_no(1);
        onFixedMasterSearch(message_index);
      }
    }

    function triggerMasterSearch(message_index){
      setmultisub_page_no(1);
      onFixedMasterSearch(message_index,1,null,null);
    }

    function onFixedMasterSearch(mess_indx,temp_page_no = null,temp_page_size = null,temp_order_by = null,temp_filter = null){
      if(CURR_MULTI_SUBFORM_Q1_TOGGLE && (CURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE === 7 || CURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE === 8 || CURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE === 11)){
        setonTableRowLoad(true);
        let single_ques_resp_temp = Object.assign([], widget[mess_indx]['data']['bulk_ques_resp_list']['ques_block_list'][0])
        try{
          let temp_MULTI_SUB_BATCH_COUNT = temp_page_size ? temp_page_size : MULTI_SUB_BATCH_COUNT;
          let payload = {
            phone:null,
            token:localStorage.getItem('vector_token'),
            search_key: subform_search_key,
            quest_id: CURR_MULTI_SUBFORM_TEMP[0][0]["ques_id"],
            instance_id:widget[mess_indx]['data']['bulk_ques_resp_list']['subform_cf_instance_id'],
            form_id:CURR_MULTI_SUBFORM_TEMP[0][0]["form_id"],
            resp_type:CURR_MULTI_SUBFORM_TEMP[0][0]["free_text"],
            ques_resp_list_struct : single_ques_resp_temp,
            construct_for_submit_flag : false,
            already_exist_resp : null,
            page_no : temp_page_no ? temp_page_no : multisub_page_no,
            master_filter_list : temp_filter ? temp_filter : curr_master_filter_list,
            master_order_list : temp_order_by ? temp_order_by : curr_master_order_by_list,
            page_size : temp_MULTI_SUB_BATCH_COUNT
          }
          
          httpClient.post('/bot/searchMultiSubformFixedMaster',payload )
          .then(response => {
            let resp = response.data;
              setonTableRowLoad(false);
              if(resp["data"].length > 0){
                let temp_pages = Math.floor(resp["data"][0][0]["total_rows"]/temp_MULTI_SUB_BATCH_COUNT)
                if(resp["data"][0][0]["total_rows"] % temp_MULTI_SUB_BATCH_COUNT !== 0){
                  temp_pages = temp_pages + 1;
                }
                setCURR_MULTI_SUBFORM_TOTAL_PAGE_NO(temp_pages);
                setCURR_MULTI_SUBFORM_TEMP_CACHE(resp["data"]);
                setCURR_MULTI_SUBFORM_TEMP(resp["data"].slice(0,temp_MULTI_SUB_BATCH_COUNT));
                replace_already_fill_on_search_multi_sub(0,resp["data"].slice(0,temp_MULTI_SUB_BATCH_COUNT));
              }else{
                props.triggerNotification('warning','No data found')
              }
          })
          .catch(error => {
            console.error('searchMultiSubformFixedMaster 111 Error 1:', error);
          });
        } catch (error) {
          console.error('searchMultiSubformFixedMaster 222 Error 2:', error);
        }
      }else if(CURR_MULTI_SUBFORM_Q1_TOGGLE){
        filter_subform_multiple(mess_indx)
      }else{
        // console.log("no search available")
      }
    }

    function last_widg_has_multiple_subform(input_data){
      setsubform_search_key("");
      setmultisub_page_no(1);
      setonTableRowLoad(true);
      // console.log("\n\nlast_widg_has_multiple_subform :: ")
      if(have_bulk_subform(input_data)){
        // console.log("\n\have_bulk_subform :: ")
        setCURR_FORM_IS_MULTI_SUB(true);
        setCURR_MULTI_SUBFORM_Q1_TOGGLE(input_data['bulk_ques_resp_list']['q1_toggle'])
        CURR_MULTI_SUB_HIDE_CURR_VAL = input_data['bulk_ques_resp_list']['hide_curr_value']
        setCURR_MULTI_SUBFORM_TEMP_CACHE(input_data['bulk_ques_resp_list']['ques_block_list'])
        let temp_CURR_MULTI_SUBFORM_TEMP = null;
        // console.log("\n\n\nMULTI_SUB_BATCH_COUNT :: ",MULTI_SUB_BATCH_COUNT);
        if(input_data['bulk_ques_resp_list']['q1_toggle']){
          temp_CURR_MULTI_SUBFORM_TEMP = input_data['bulk_ques_resp_list']['ques_block_list'].slice(0,MULTI_SUB_BATCH_COUNT)
          setCURR_MULTI_SUBFORM_TEMP(input_data['bulk_ques_resp_list']['ques_block_list'].slice(0,MULTI_SUB_BATCH_COUNT))
        }else{
          temp_CURR_MULTI_SUBFORM_TEMP = input_data['bulk_ques_resp_list']['ques_block_list']
          setCURR_MULTI_SUBFORM_TEMP(input_data['bulk_ques_resp_list']['ques_block_list'])
        }
        CURR_MULTI_SUBFORM_CF_INST_ID = input_data['bulk_ques_resp_list']['subform_cf_instance_id']
        setCURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE(temp_CURR_MULTI_SUBFORM_TEMP[0][0]["free_text"]);
        let multi_subform_resp_cache = {}
        if(input_data['bulk_ques_resp_list']['q1_toggle']){
          CURR_MULTI_SUBFORM_TOTAL_ROWS = temp_CURR_MULTI_SUBFORM_TEMP[0][0]["total_rows"]
          let temp_pages = Math.floor(temp_CURR_MULTI_SUBFORM_TEMP[0][0]["total_rows"] / MULTI_SUB_BATCH_COUNT)
          if(temp_CURR_MULTI_SUBFORM_TEMP[0][0]["total_rows"] % MULTI_SUB_BATCH_COUNT !== 0){
            temp_pages = temp_pages + 1;
          }
          setCURR_MULTI_SUBFORM_TOTAL_PAGE_NO(temp_pages);
        }else{
          CURR_MULTI_SUBFORM_TOTAL_ROWS = input_data['bulk_ques_resp_list']['ques_block_list'].length;
          let temp_pages = Math.floor(input_data['bulk_ques_resp_list']['ques_block_list'].length/MULTI_SUB_BATCH_COUNT)
          if(input_data['bulk_ques_resp_list']['ques_block_list'].length % MULTI_SUB_BATCH_COUNT !== 0){
            temp_pages = temp_pages + 1;
          }
          setCURR_MULTI_SUBFORM_TOTAL_PAGE_NO(temp_pages);
        }
        assign_multi_subform_table_width(temp_CURR_MULTI_SUBFORM_TEMP,input_data['bulk_ques_resp_list']['master_cf_ordered_list'],input_data['bulk_ques_resp_list']['each_row_submit'])
        if(input_data['bulk_ques_resp_list']['all_row_mandatory']){
          write_all_rows_inside_response_tracker(input_data)
        }else{
          store_userResp_TempCacheJson(multi_subform_resp_cache)
        }
        // console.log("\n\nfirst temp_CURR_MULTI_SUBFORM_TEMP :: ",temp_CURR_MULTI_SUBFORM_TEMP)
      }else{
        setCURR_FORM_IS_MULTI_SUB(false);
        setCURR_MULTI_SUBFORM_Q1_TOGGLE(false);
        setCURR_MULTI_SUBFORM_Q1_TOGGLE_RESP_TYPE(0);
        setCURR_MULTI_SUBFORM_TEMP([])
        setCURR_MULTI_SUBFORM_TEMP_CACHE([])
        CURR_MULTI_SUBFORM_CF_INST_ID = null
        let multi_subform_resp_cache = {}
        CURR_MULTI_SUB_HIDE_CURR_VAL = false;
        store_userResp_TempCacheJson(multi_subform_resp_cache)
      }
      setonTableRowLoad(false);
    }

    function get_user_resp_cache_key(multi_subform_indx,temp_CURR_MULTI_SUBFORM_TEMP){
      // // console.log("get_user_resp_cache_key :: ",temp_CURR_MULTI_SUBFORM_TEMP,multi_subform_indx)
      if(CURR_MULTI_SUBFORM_Q1_TOGGLE){
        return temp_CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][0]['response']
      }else{
        return  multi_subform_indx
      }
    }

  function get_userResp_TempCacheJson_serv(){
    return JSON.parse(localStorage.getItem("multi_subform_resp_cache"))
  }

  function get_userResp_TempCacheJson(){
    return get_userResp_TempCacheJson_serv()
  }

    function replace_already_fill_on_search_multi_sub(check_start_point,temp_CURR_MULTI_SUBFORM_TEMP){
      let multi_subform_resp_cache = get_userResp_TempCacheJson()
      // Create a new array with the updated object at the specified index
      const updatedList = [...temp_CURR_MULTI_SUBFORM_TEMP];
      for (let k = check_start_point; k < temp_CURR_MULTI_SUBFORM_TEMP.length; k++) {
        let row_id = get_user_resp_cache_key(k,temp_CURR_MULTI_SUBFORM_TEMP)
        if(row_id in multi_subform_resp_cache){
          updatedList[k] = multi_subform_resp_cache[temp_CURR_MULTI_SUBFORM_TEMP[k][0]["response"]]
        }
      }
      // Update the state with the new array
      setCURR_MULTI_SUBFORM_TEMP(updatedList);
    }

    function onPageChange(pageIndx,direct_val = null){
      // console.log("pageIndx,direct_val :: ",pageIndx,direct_val);
      let page_no = (direct_val !== null) ? direct_val : (pageIndx.target.value);
      // console.log("page_no :: ",page_no)
      setmultisub_page_no(page_no);
      onFixedMasterSearch(message_index,page_no);    
    }

    function onPageForwRBACK(direction){
      // console.log("onPageForwRBACK :: ",direction,CURR_MULTI_SUBFORM_TOTAL_PAGE_NO);
      if(direction === 'front' && multisub_page_no < CURR_MULTI_SUBFORM_TOTAL_PAGE_NO){
        onPageChange(null,multisub_page_no + 1);
      }else if(direction === 'back' && multisub_page_no > 1){
        onPageChange(null,multisub_page_no - 1);
      }else{
        props.triggerNotification('warning','Action not allowed!')
      }
      // console.log("onPageForwRBACK :: ",multisub_page_no);
    }
  
    function onPageSizeChange(pageSize){
      let temp_psize = pageSize.target.value;
      // console.log("pageSize :: ",pageSize)
      setMULTI_SUB_BATCH_COUNT(temp_psize);
      setmultisub_page_no(1);
      onFixedMasterSearch(message_index,1,temp_psize);
    }

    function changeOrder(cf_indx,cf_data,col_type,col_type_name,col_type_id,col_type_id_2,order_by){
      // console.log("cf_indx,col_type,col_key,col_val,order_by :: ",cf_indx,cf_data,col_type,col_type_name,col_type_id,col_type_id_2,order_by)
      const updatedmessage = {...message};
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['order_by'] = order_by;
      setMessage(updatedmessage);
      // message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['order_by'] = order_by
      let tempcurr_master_order_by_list = {...curr_master_order_by_list};
      if(cf_data.cfm_id){
        let obj = {"type":col_type,"type_name":col_type_name,"type_id":col_type_id,"order_by":order_by}
        tempcurr_master_order_by_list["cfm"][cf_data.cfm_id] = obj
      }
      if(cf_data.seq_id){
        let obj = {"type":col_type,"type_name":col_type_name,"type_id":col_type_id_2,"order_by":order_by}
        tempcurr_master_order_by_list["seq"][cf_data.seq_id] = obj
      }
      onFixedMasterSearch(message_index,null,null,tempcurr_master_order_by_list);
      setcurr_master_order_by_list(tempcurr_master_order_by_list);
    }

    function on_multi_subform_row_update(mul_sub_index,dirr_val = null){
      // // console.log("try multi_subform_resp_cache :: ",mul_sub_index,CURR_FORM_IS_MULTI_SUB)
      if(CURR_FORM_IS_MULTI_SUB){
        let recent_edit_row_q1_id = get_user_resp_cache_key(mul_sub_index,CURR_MULTI_SUBFORM_TEMP)
        let multi_subform_resp_cache = get_userResp_TempCacheJson()
        // console.log("\n\non local st update :: ",CURR_MULTI_SUBFORM_TEMP[mul_sub_index],dirr_val);
        multi_subform_resp_cache[String(recent_edit_row_q1_id)] = (dirr_val!==null ? dirr_val : CURR_MULTI_SUBFORM_TEMP[mul_sub_index])
        store_userResp_TempCacheJson(multi_subform_resp_cache)
        // // console.log("multi_subform_resp_cache :: ",multi_subform_resp_cache)
      }
    }

    function get_resp_check(k,i,ques_block_list){
      let resp = ''
      let file_list = [];
      if (
        ques_block_list[k][i].options.length === 0 &&
        ques_block_list[k][i].free_text >= 1
      ) {
        if (ques_block_list[k][i]['response'] !== '') {
          resp = ques_block_list[k][i]['response']
        }
      } else if (
        ques_block_list[k][i].options.length !== 0 &&
        (ques_block_list[k][i].free_text < 1 || ques_block_list[k][i].free_text === 7 || ques_block_list[k][i].free_text === 8 || ques_block_list[k][i].free_text === 11)
      ) {
        if (ques_block_list[k][i]['response'] !== '') {
          resp = ques_block_list[k][i]['response'];
        }
      } else if (ques_block_list[k][i].file_upload === 1) {
          file_list = ques_block_list[k][i]['user_resp_files']
      } else {
        resp = ques_block_list[k][i]['response']
      }
      return {"resp":resp,"file_list":file_list}
    }

    function set_mutli_sub_key_val(multi_subform_indx,eqIndx,key,resp_val){
      setCURR_MULTI_SUBFORM_TEMP((prevList) => {
        const newList = [...prevList];
        newList[multi_subform_indx] = [...newList[multi_subform_indx]];
        newList[multi_subform_indx][eqIndx] = { ...newList[multi_subform_indx][eqIndx], [key]: resp_val };
        return newList;
      });
    }

    function clear_multi_sub_resp(multi_subform_indx){
      // console.log("before_clear :: ",multi_subform_indx,CURR_MULTI_SUBFORM_TEMP[multi_subform_indx])
      for(let eqIndx = 1;eqIndx < CURR_MULTI_SUBFORM_TEMP[multi_subform_indx].length; eqIndx++){
        // CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][eqIndx].response = ""
        set_mutli_sub_key_val(multi_subform_indx,eqIndx,"response","")
        if(CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][eqIndx].tag_option_flag){
          set_mutli_sub_key_val(multi_subform_indx,eqIndx,"options",clear_checked_if_tag_enable(CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][eqIndx].options))
          // CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][eqIndx].options = clear_checked_if_tag_enable(CURR_MULTI_SUBFORM_TEMP[multi_subform_indx][eqIndx].options)
        }
        set_mutli_sub_key_val(multi_subform_indx,eqIndx,"user_resp_files",[])
      }
      // console.log("after_clear :: ",multi_subform_indx,CURR_MULTI_SUBFORM_TEMP[multi_subform_indx])
      let recent_edit_row_q1_id = get_user_resp_cache_key(multi_subform_indx,CURR_MULTI_SUBFORM_TEMP)
      let multi_subform_resp_cache = get_userResp_TempCacheJson()
      delete multi_subform_resp_cache[String(recent_edit_row_q1_id)]
      store_userResp_TempCacheJson(multi_subform_resp_cache)
    }

    function remove_master_from_list(multi_subform_indx){
      let recent_edit_row_q1_id = get_user_resp_cache_key(multi_subform_indx,CURR_MULTI_SUBFORM_TEMP)
      let multi_subform_resp_cache = get_userResp_TempCacheJson()
      delete multi_subform_resp_cache[String(recent_edit_row_q1_id)]
      store_userResp_TempCacheJson(multi_subform_resp_cache)
      CURR_MULTI_SUBFORM_TEMP.splice(multi_subform_indx, 1);
      CURR_MULTI_SUBFORM_TOTAL_ROWS -= 1;
    }

    function submit_single_row_grid_view(data,k) {
      let subforms_data = data["bulk_ques_resp_list"]
      let ques_block_list = CURR_MULTI_SUBFORM_TEMP
      let temp_cf_instance_id = subforms_data["subform_cf_instance_id"]
      let whole_struct = [];
      let new_subform = {
            form_type : "subform", 
            form_id : ques_block_list[k][0]['form_id'],
            cf_instance_id : temp_cf_instance_id,
            ques_list : []
          }
      temp_cf_instance_id = null;
      for (let i = 0; i < ques_block_list[k].length; i++) {
        let response_data = get_resp_check(k,i,ques_block_list)
        let resp = response_data["resp"]
        let file_list = response_data["file_list"]
        new_subform.ques_list.push({
                            ques_id : ques_block_list[k][i]['ques_id'],
                            resp_type : ques_block_list[k][i].free_text,
                            response : resp,
                            file_list : file_list,
                            file_upload_flag : ques_block_list[k][i].file_upload,
                            task_id : ques_block_list[k][i].task_id,
                            revision : ques_block_list[k][i].revision,
                            contrib_id : ques_block_list[k][i].contrib_id,
                            custom_field : ques_block_list[k][i].custom_field,
                            scope : ques_block_list[k][i].scope,
                            task_assct_flag : ques_block_list[k][i].task_assct_flag
                          })
      }
      whole_struct.push(new_subform)
      let out_resp = {}
      out_resp[SPECIAL_SUBMIT_GRID_ROW_SUBMIT_DREAM_DASH] = whole_struct
      try{
        let payload = {
          token: localStorage.getItem('vector_token'),
          response : out_resp,
        }
        botHttpClient.post('/uibot/submitGridRow',payload )
        .then(resp => {
          {
            let res = resp.data;
            if(res.status){
              props.triggerNotification('success', 'submitted successfully');
              let response_data = get_resp_check(k,0,ques_block_list)
              try{
                let payload2 = {
                  phone:null,
                  token:localStorage.getItem('vector_token'),
                  search_key: subform_search_key,
                  quest_id: ques_block_list[k][0]['ques_id'],
                  instance_id:subforms_data["subform_cf_instance_id"],
                  form_id:ques_block_list[k][0]['form_id'],
                  resp_type:ques_block_list[k][0].free_text,
                  ques_resp_list_struct : ques_block_list[k][0],
                  page_no : 1,
                  master_id :response_data["resp"]
                }
                httpClient.post('/bot/checkMasterExistAfterSubmit',payload2 )
                .then(res => {
                  let exist_status = res.data
                  if(exist_status.status){
                    clear_multi_sub_resp(k)
                  }else{
                    remove_master_from_list(k)
                  }
                })
                .catch(error => {
                  console.error('checkMasterExistAfterSubmit Error 1:', error);
                  props.triggerNotification('error', 'Something went wrong');
                });
              } catch (error) {
                console.error('checkMasterExistAfterSubmit Error 2:', error);
                props.triggerNotification('error', 'Something went wrong');
              }
            }else{
              props.triggerNotification('error', 'Something went wrong');
            }
          }
        })
        .catch(error => {
          console.error('submitGridRow Error 111:', error);
        });
      } catch (error) {
        console.error('submitGridRow Error 222:', error);
      }
      
    }

    function add_one_more_subform_resp(mess_indx){
      let template  = [];
      const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
      updatedResponses[updatedResponses.length - 1].forEach(val => template.push(Object.assign({}, val)));
      for(let i = 0; i < template.length; i++) {
        template[i]['response'] = ""
        if(template[i]['file_upload']){
          template[i]['user_resp_files'] = []
        }
      }
      updatedResponses.push(template);
      setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
    }

    function dublicate_last_fill_subform_resp(){
      let template  = [];
      let multi_subform_resp_cache = localStorage.getItem("multi_subform_resp_cache")
      multi_subform_resp_cache = JSON.parse(multi_subform_resp_cache)
      let keyList = [];
      Object.keys(multi_subform_resp_cache).forEach(key => {
        keyList.push(Number(key))
      });
      let maxIndx = keyList.reduce((op, item) => op = op > item ? op : item, 0);
      // console.log("key list :: ",keyList,maxIndx)
      if(keyList.length > 0){
        multi_subform_resp_cache[String(maxIndx)].forEach(val => template.push(Object.assign({}, val)));
        CURR_MULTI_SUBFORM_TEMP.push(template)
      }else{
        props.triggerNotification('warning', 'No data for duplicate');
      }  
    }

    function has_overwrite_psa_resp(single_block_temp){
      // console.log("\n\nhas_overwrite_psa_resp : ",single_block_temp)
      for (let i = 0; i < single_block_temp.length; i++) {
        if(single_block_temp[i]["overwrite_resp_flag"]){
          return true
        }
      }
      return false
    }

    function has_master_single_opt(single_block_temp){
      for (let i = 0; i < single_block_temp.length; i++) {
        if(single_block_temp[i]["options"].length === 1  && single_block_temp[i]['auto_select_single'] ){
          return true
        }
      }
      return false
    }

    function submit_multiple_subform_atonce(data, msg_id,mess_indx) {
      let subforms_data = data["bulk_ques_resp_list"]
      // let ques_block_list = subforms_data["ques_block_list"]
      let q1_toggle = subforms_data['q1_toggle']
      let un_submited_subform_inst = CURR_MULTI_SUBFORM_CF_INST_ID
      
      let multi_subform_resp_cache = get_userResp_TempCacheJson()
      let ques_block_list = [];
      if(q1_toggle && (subforms_data["has_default_resp_flag"] || has_overwrite_psa_resp(CURR_MULTI_SUBFORM_TEMP[0]) || has_master_single_opt(CURR_MULTI_SUBFORM_TEMP[0]))){
        let single_ques_resp_temp = Object.assign([], widget[mess_indx]['data']['bulk_ques_resp_list']['ques_block_list'][0])
        try{
          let payload = {
            phone:null,
            token:localStorage.getItem('vector_token'),
            search_key: "",
            quest_id: CURR_MULTI_SUBFORM_TEMP[0][0]["ques_id"],
            instance_id:widget[mess_indx]['data']['bulk_ques_resp_list']['subform_cf_instance_id'],
            form_id:CURR_MULTI_SUBFORM_TEMP[0][0]["form_id"],
            resp_type:CURR_MULTI_SUBFORM_TEMP[0][0]["free_text"],
            ques_resp_list_struct : single_ques_resp_temp,
            construct_for_submit_flag : true,
            already_exist_resp : multi_subform_resp_cache,
            page_no : null,
            master_filter_list : null,
            master_order_list : null,
            page_size : null
          }
          // console.log("payload :: ",payload)
          httpClient.post('/bot/searchMultiSubformFixedMaster',payload )
          .then(response => {
            let resp = response.data;
            ques_block_list = resp["data"]
            const_and_submit_mul_subform_all_atonce(ques_block_list,q1_toggle,un_submited_subform_inst,msg_id)
          })
          .catch(error => {
            console.error('searchMultiSubformFixedMaster 11 Error 1:', error);
          });
        } catch (error) {
          console.error('searchMultiSubformFixedMaster 22 Error 2:', error);
        }
      }else{
        ques_block_list = Object.values(multi_subform_resp_cache);
        const_and_submit_mul_subform_all_atonce(ques_block_list,q1_toggle,un_submited_subform_inst,msg_id)
      }
    }


    function const_and_submit_mul_subform_all_atonce(ques_block_list,q1_toggle,un_submited_subform_inst,msg_id){
      var whole_struct = []
      // console.log("ques_block_list ::",ques_block_list)
      for (let k = 0; k < ques_block_list.length; k++) {
        let sel_opt = ques_block_list[k].map((currElement, index) => {
          if(currElement.response || currElement.user_resp_files.length){return 1}else{return 0}
        });
        const sum = sel_opt.reduce((partialSum, a) => partialSum + a, 0);
        if(((q1_toggle && ques_block_list[k][0].mandatory && sum > 0) || (q1_toggle && !ques_block_list[k][0].mandatory && sum > 1)) || (!q1_toggle && sum > 0)){
          let new_subform = {
                            form_type : "subform",
                            form_id : ques_block_list[k][0]['form_id'],
                            cf_instance_id : un_submited_subform_inst,
                            ques_list : []
                          }
          un_submited_subform_inst = null;
          for (let i = 0; i < ques_block_list[k].length; i++) {
          
            let response_data = get_resp_check(k,i,ques_block_list)
            let resp = response_data["resp"]
            let file_list = response_data["file_list"]
            new_subform.ques_list.push({
                                          ques_id : ques_block_list[k][i]['ques_id'],
                                          resp_type : ques_block_list[k][i].free_text,
                                          response : resp,
                                          file_list : file_list,
                                          file_upload_flag : ques_block_list[k][i].file_upload,
                                          task_id : ques_block_list[k][i].task_id,
                                          revision : ques_block_list[k][i].revision,
                                          contrib_id : ques_block_list[k][i].contrib_id,
                                          custom_field : ques_block_list[k][i].custom_field,
                                          scope : ques_block_list[k][i].scope,
                                          task_assct_flag : ques_block_list[k][i].task_assct_flag
                                        })
          }
          whole_struct.push(new_subform)
        }
      }
      let out_resp = {
        "primary_form_id":widget[message_index]['data']['bulk_ques_resp_list']['primary_form_id'],
        "subform_instance_id":widget[message_index]['data']['bulk_ques_resp_list']['subform_cf_instance_id']};
      out_resp[SPECIAL_SUBMIT_TYPE_DREAM_DASH] = whole_struct
      try{
        let payload = {
          token: localStorage.getItem('vector_token'),
          response : out_resp,
        }
        botHttpClient.post('/uibot/dirrectSubmitDB',payload )
        .then(res => {
          props.triggerNotification('success', 'submitted successfully');
          refresh_form();
        })
        .catch(error => {
          console.error('dirrectSubmitDB Error 1:', error);
        });
      } catch (error) {
        console.error('dirrectSubmitDB Error 2:', error);
      }
    }
    ///////////////////////////////////////////
    
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleDatePickClose = () => {
      setAnchorEl(null);
      setvisble_datepick(false);
    };

    const closeMasterColFilter = () => {
      setAnchorEl(null);
      setvisble_col_filtr(false);
    };

    const openMasterColFilter = (event,cf_indx) => {
      setMasterFilterCfIndx(cf_indx);
      setAnchorEl(event.currentTarget);
      setvisble_col_filtr(true);
      notOpt_multiSub_filt_visible(true,cf_indx)
    };

    function mergeLists(sourceList, destinationList, key) {
      // Create a set of existing values in the destination list
      const existingValues = new Set(destinationList.map(item => item[key]));
    
      // Iterate through the source list
      for (const sourceItem of sourceList) {
        // Check if the key value of the source item exists in the destination list
        if (!existingValues.has(sourceItem[key])) {
          // Add the source item to the destination list
          destinationList.push(sourceItem);
          // Update the set of existing values
          existingValues.add(sourceItem[key]);
        }
      }
    
      // Return the updated destination list
      return destinationList;
    }

    function handleFilterOptionSelect(e,newInput){
      console.log("on filter select :: ",newInput);
      if(newInput){
        const updatedmessage = {...message};
        updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx]['selected_filters'] = mergeLists([newInput], updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx]['selected_filters'], 'val')
        setMessage(updatedmessage);
      }
    }

    function removeObjectsByKey(list, key, textToRemove) {
      // Use the filter method to create a new list excluding items with the specified key value
      const updatedList = list.filter(item => item[key] !== textToRemove);
    
      // Return the updated list
      return updatedList;
    }

    function handleMasterFilterDelete(delKey){
      const updatedmessage = {...message};
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx]['selected_filters'] = removeObjectsByKey(updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx]['selected_filters'], 'val',delKey);
      setMessage(updatedmessage);
    }


    function mapdatepick(event,message_index,formIndex,ques_inx,formtype,curr_resp,is_multiple_subform = false,mul_sub_index = null){
      handleOpen(event);
      setvisble_datepick(true);
      setCurr_date_pick_mess_indx(message_index);
      setCurr_date_pick_form_indx(formIndex);
      setCurr_date_pick_ques_indx(ques_inx)
      setCurr_date_pick_form_type(formtype)
      setCurr_date_pick_is_multi(is_multiple_subform)
      // console.log("\n\n\ncurr_date_pick_is_multi :: ",curr_date_pick_is_multi,curr_date_pick_form_type)
      setCurr_date_pick_sub_form_indx(mul_sub_index)
      if(curr_resp && curr_resp.length > 0 && (curr_resp.includes('/'))){
        let formattedDate = "";
        try{
          const parts = curr_resp.split('/');
          // Reformat the date string in 'mm/dd/yy' format
          if(parts[0].length > 0 && parts[1].length > 0 && parts[2].length > 0){
            formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
          }
        }catch(error){
          formattedDate = "";
        }
        setDatepickvar(formattedDate)
      }else{
        setDatepickvar("");
      }
    }

    function formatDate(inputDate) {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = String(inputDate.getFullYear()).slice(-2);
    
      return `${day}/${month}/${year}`;
    }
    
  
    function OnDatePick(evt){
      // console.log("On date pick :: ",evt)
      let new_formdate = formatDate(evt)
      // console.log("datepick evnt :: ",new_formdate);
      // console.log("curr_date_pick_is_multi :: ",curr_date_pick_form_type,curr_date_pick_is_multi);
      if (curr_date_pick_form_type ==='all_in_one'){ 
        if(!curr_date_pick_is_multi){
          // const updatedResponses = {...message};
          // updatedResponses.data.form_list[curr_date_pick_form_indx].ques_resp_list.data.list[curr_date_pick_ques_indx].response = new_formdate;
          // setMessage(updatedResponses);
          setMessage((prevMessage) =>
            produce(prevMessage, (draft) => {
              draft.data.form_list[curr_date_pick_form_indx].ques_resp_list.data.list[curr_date_pick_ques_indx].response = new_formdate;
            })
          );
        }else{

          on_multi_subform_row_update(curr_date_pick_sub_form_indx)
          setCURR_MULTI_SUBFORM_TEMP((prevMessage) =>
            produce(prevMessage, (draft) => {
              draft[curr_date_pick_sub_form_indx][curr_date_pick_ques_indx]['response'] = new_formdate
            })
          );
          // const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
          // updatedResponses[curr_date_pick_sub_form_indx][curr_date_pick_ques_indx]['response'] = new_formdate
          // setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
        }
      }
      else{
        const updatedResponses = {...message};
        updatedResponses.data.form_list[curr_date_pick_form_indx].ques_resp_list.data.list[curr_date_pick_ques_indx].response = new_formdate;
        setMessage(updatedResponses);
      }
      handleDatePickClose()
    }

    function handleMasterSerach(e){
      // console.log('on search :: ',e);
      setsubform_search_key(e);
    }

    const enterKeyPress = (event,funct_trigg) => {
      
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default form submission behavior
        // Handle the Enter key press
        // console.log('Enter key pressed',funct_trigg == 'master_search',subform_search_key);
        // Add your logic here

        if(funct_trigg === 'master_search'){
          triggerMasterSearch(message_index);
        }
        else if(funct_trigg === 'update_note'){
          UpdateTaskNotes();
        }
      }
    };

    function showFileUpload(message_index,formIndex,ques_inx,formtype,curr_resp,file_upld_type = 'form_response',is_multiple_subform = false,mul_sub_index = null){
      setvisibleFileUpload(true);
      setFileUploadDisable(false);
      settemp_Upld_fileList([]);
      setfileuploadType(file_upld_type);
      if (file_upld_type === 'form_response'){
        setCurr_act_mess_indx(message_index);
        setCurr_act_form_indx(formIndex);
        setCurr_act_ques_indx(ques_inx)
        setCurr_act_form_type(formtype)
        setCurr_act_is_multi(is_multiple_subform)
        setCurr_act_sub_form_indx(mul_sub_index)
      }
    }

    function cancelfileupload(){
      setvisibleFileUpload(false);
      settemp_Upld_fileList([]);
    }

    const handleUplloadedFileChange = (event) => {
      if(event.target.files.length){
        settemp_Upld_fileList([...temp_Upld_fileList, event.target.files[0]]);
      }
    };

    const handleRemoveUploadedFile = (index) => {
      const updatedFiles = [...temp_Upld_fileList];
      updatedFiles.splice(index, 1);
      settemp_Upld_fileList(updatedFiles);
    };

    function confirmFileUpload(){
      setFileUploadDisable(true)
      const formData = new FormData()
      let caption = {};
      if(temp_Upld_fileList.length > 0) {
        for (let i = 0; i < temp_Upld_fileList.length; i++) {
          formData.append('attachments', temp_Upld_fileList[i], temp_Upld_fileList[i].name)
          caption[temp_Upld_fileList[i]['name']] = temp_Upld_fileList[i].iconType
        }
      }
      if(fileuploadType === 'form_response'){
        formData.append('type', 'Task')
      }else if(fileuploadType === 'bulk_form_resp_upload'){
        formData.append('type', 'form')
        formData.append('type_id', CurrBulkUplFormId)
        formData.append('form_additional_data', JSON.stringify(CurrBulkUplAddData))
      }else{
        formData.append('type', 'TaskUpdates')
      }
      formData.append('token', localStorage.getItem('vector_token'))
      formData.append('caption', JSON.stringify(caption))
      // console.log("file uploaded formdata ::",fileuploadType)
      if(fileuploadType === 'form_response'){
        // try{
          httpClient.post('/bot/formsFileUpload',formData )
          .then(response => {
            let res = response.data;
            if (res.status === true) {
              props.triggerNotification('success', 'Upload sucessfully');
              settemp_Upld_fileList([]);
              setvisibleFileUpload(false);
              for (let i = 0; i < res.data.length; i++) {
                if(curr_act_is_multi){
                  const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
                  // console.log("curr_act_ques_indx :: ",curr_act_sub_form_indx,curr_act_ques_indx)
                  updatedResponses[curr_act_sub_form_indx][curr_act_ques_indx].user_resp_files.push(res.data[i]);
                  setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
                  on_multi_subform_row_update(curr_act_sub_form_indx);
                }else{
                  // const updatedResponses = {...message};
                  handleAllInOneResp(curr_act_form_indx, curr_act_ques_indx,'','all_in_one',null,res.data[i]);
                  // updatedResponses.data.form_list[curr_act_form_indx].ques_resp_list.data.list[curr_act_ques_indx].user_resp_files.push(res.data[i]);
                  // setMessage(updatedResponses);
                }
              }
            }
          })
          .catch(error => {
            console.error('file upload Error 1:', error);
          });
        // } catch (error) {
        //   console.error('file upload Error 2:', error);
        // }
      }else if(fileuploadType === 'bulk_form_resp_upload'){
        try{
          httpClient.post('/forms/uploadFormResponse',formData )
          .then(response => {
            let res = response.data;
            setcurrBulkUpldProgPerct(100);
            // stopProgress();
            if (res.status === true) {
                props.triggerNotification( 'success','Form responses has been submitted successfully')
                settemp_Upld_fileList([]);
                setvisibleFileUpload(false);
                // setCurrBulkUplAddData(null);
                // setCurrBulkUplFormIndx(null);
                // setCurrBulkUplFormId(null);
            }else {
              props.triggerNotification('error','Please check the response format(s)')
            }
          })
          .catch(error => {
            console.error('file upload Error 1111:', error);
          });
        } catch (error) {
          console.error('file upload Error 2222:', error);
        }
      }else{
        try{
          httpClient.post('/file/insertFile',formData )
          .then(response => {
            let res = response.data;
            if (res.status === true) {
              props.triggerNotification('success', 'Upload sucessfully');
              settemp_Upld_fileList([]);
              setvisibleFileUpload(false);
              for (let i = 0; i < res.data.length; i++) {
                setupdateNoteTaskform((prevObject) => ({
                  ...prevObject,
                  attachments: [...prevObject.attachments, res.data[i]],
                }));
              }
            }
          })
          .catch(error => {
            console.error('file upload Error 1:', error);
          });
        } catch (error) {
          console.error('file upload Error 2:', error);
        }

      }
    }

    function removeSingleOldFile(rowid,file_indx,formIndex,ques_inx,is_multiple_subform = false,mul_sub_index = null) {
      try{
        let payload = {
          token: localStorage.getItem('vector_token'),
          file_row_id: rowid
        }
        httpClient.post('/bot/formsFileArchive',payload )
        .then(response => {
          let res = response.data;
          if (res.status === true) {
            if(is_multiple_subform){
              const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
              updatedResponses[mul_sub_index][ques_inx].user_resp_files.splice(file_indx, 1);
              setCURR_MULTI_SUBFORM_TEMP(updatedResponses);
            }else{
              const updatedResponses = {...message};
              updatedResponses.data.form_list[formIndex].ques_resp_list.data.list[ques_inx].user_resp_files.splice(file_indx, 1);
              setMessage(updatedResponses);
            }
          }
        })
        .catch(error => {
          console.error('file upload Error 1:', error);
        });
      } catch (error) {
        console.error('file upload Error 2:', error);
      }
    }

    const downloadUploadedFile = (fileUrl,fileName) => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleFullScreen = () => {
      console.log("called full screen in chat template")
      if(fullScreenToggle){
        console.log("close full screen");
        props.closeFullScreen();
        setfullScreenToggle(0);
      }else{
        setfullScreenToggle(1);
        console.log("open full screen");
        props.viewFullScreen();
      }
    };

    function tagOptionCheckChange(present_val,option_indx,option_data,resp_type,mess_indx,formIndex,ques_indx,form_type,is_multiple_subform = false,mul_sub_index = null){
      console.log("on check event :: ",present_val)
      if (form_type ==='all_in_one'){ 
        if(!is_multiple_subform){
          const updatedWidget = [...widget];          
          if(!present_val){
            let curr_resp = ((resp_type === 7 ||  resp_type === 8 ||  resp_type === 11) ? option_data['id'] : option_data['val'])
            updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['response'] = curr_resp
            updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['options'] = makeUncheckOptionExceptId(option_indx,widget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['options'])
            if(resp_type === 7 ||  resp_type === 8 ||  resp_type === 11){
              let ques_list = updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list']
              let ques = updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]
              onOptionSelect(ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,is_multiple_subform,mul_sub_index)
            }
            
          }else{
            updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['response'] = ""
          }
          updatedWidget[mess_indx]['data']['form_list'][formIndex]['ques_resp_list']['data']['list'][ques_indx]['options'][option_indx]['checked'] = !present_val
          setwidgets(updatedWidget);
        }else{
          // const updatedResponses = [...CURR_MULTI_SUBFORM_TEMP];
          // if(!present_val){
          //   let curr_resp = ((resp_type === 7 ||  resp_type === 8 ||  resp_type === 11) ? option_data['id'] : option_data['val'])
          //   updatedResponses[mul_sub_index][ques_indx]['response'] = curr_resp
          //   updatedResponses[mul_sub_index][ques_indx]['options'] = makeUncheckOptionExceptId(option_indx,CURR_MULTI_SUBFORM_TEMP[mul_sub_index][ques_indx]['options'])
          //   if(resp_type === 7 ||  resp_type === 8 ||  resp_type === 11){
          //     let ques_list = CURR_MULTI_SUBFORM_TEMP[mul_sub_index]
          //     let ques = CURR_MULTI_SUBFORM_TEMP[mul_sub_index][ques_indx]
          //     onOptionSelect(ques_list,ques,mess_indx,formIndex,ques_indx,curr_resp,form_type,is_multiple_subform,mul_sub_index)
          //   }
          // }else{
          //   updatedResponses[mul_sub_index][ques_indx]['response'] = ""
          // }
          // updatedResponses[mul_sub_index][ques_indx]['options'][option_indx]['checked'] = !present_val
          // setCURR_MULTI_SUBFORM_TEMP(updatedResponses);

          setCURR_MULTI_SUBFORM_TEMP(produce((draft) => {
            if (!present_val) {
              let curr_resp = resp_type === 7 || resp_type === 8 || resp_type === 11 ? option_data['id'] : option_data['val'];
              draft[mul_sub_index][ques_indx]['response'] = curr_resp;
              draft[mul_sub_index][ques_indx]['options'] = makeUncheckOptionExceptId(option_indx, draft[mul_sub_index][ques_indx]['options']);
        
              if (resp_type === 7 || resp_type === 8 || resp_type === 11) {
                let ques_list = draft[mul_sub_index];
                let ques = draft[mul_sub_index][ques_indx];
                onOptionSelect(ques_list, ques, mess_indx, formIndex, ques_indx, curr_resp, form_type, is_multiple_subform, mul_sub_index);
              }
            } else {
              draft[mul_sub_index][ques_indx]['response'] = "";
            }
        
            draft[mul_sub_index][ques_indx]['options'][option_indx]['checked'] = !present_val;
          }));
          on_multi_subform_row_update(mul_sub_index)
        }
      }
    }

    function makeUncheckOptionExceptId(expOptId, option_list) {
      return option_list.map((option, opIndx) => ({
        ...option,
        checked: expOptId !== opIndx ? false : option.checked,
      }));
    }
  
    // function makeUncheckOptionExceptId(expOptId,option_list){
    //   for(let opIndx = 0;opIndx < option_list.length; opIndx++){
    //     if(expOptId !== opIndx){
    //       option_list[opIndx]["checked"] = false;
    //     }
    //   }
    //   return option_list;
    // }

    function clear_checked_if_tag_enable(options) {
      return options.map((originalOption) => ({
        ...originalOption,
        checked: false,
      }));
    }

    function returnImgTitle(item,currIndx,total_len){
      let imgName = ''
      if('custom_field_key' in item){
        imgName = item.custom_field_key + ' - ' + item.file_name
      }else{
        imgName = item.file_name
      }
      return imgName + ' (' + String(currIndx + 1) + '/' + String(total_len) + ')'
    }

    function onImgSlide(event){
      // console.log("on img slide",event)
      if(event === 'next' && ((currImgIndx + 1) < imageObject.length)){
        setcurrImgIndx(currImgIndx + 1)
      }
      else if((currImgIndx - 1) >= 0){
        setcurrImgIndx(currImgIndx - 1)
      }
    }

    function construct_dirr_dwld_link(url){
      // Create a URL object
      const parsedUrl = new URL(url);
      // Using URLSearchParams to get the value of the 'file_id' parameter
      const params = new URLSearchParams(new URL(url).search);
      const fileId = params.get("file_id");
      const fullDomain = parsedUrl.origin;
      let dirr_url = (fullDomain + '/backend/database_download?file_id=' + fileId);
      return dirr_url;
    }

    function returnImgLink(item,file_name_key = 'file_name',spec_key = 'link',dirr_ink = false){
      // console.log("\n\ncheck img link :: ",item,file_name_key,spec_key);
      if (item[file_name_key].toLowerCase().match(/.(jpg|jpeg|png|gif|jpe|bmp|svg|webp|tiff)$/i)){
        // console.log("ret1",item[file_name_key]);
        return (dirr_ink ? construct_dirr_dwld_link(item[spec_key]) : item[spec_key])
      }else{
        // console.log("ret2");
        return (dirr_ink ? construct_dirr_dwld_link(item[spec_key]) : defFileImg)
      }
    }

    function onImgLoad(curr_indx,cfIndx = null,curr_img_indx = 0){
      // if cfIndx not null then load cf files instead task files
      setcurrImgIndx(curr_img_indx);
      let temp_img_obj = null;
      if(cfIndx != null){
        temp_img_obj = CURR_MULTI_SUBFORM_TEMP[curr_indx][0]['master_cf'][cfIndx]['cf_files'].map((item, indx) =>
          new Object({ image: returnImgLink(item), thumbImage: returnImgLink(item),download:item.link,title: returnImgTitle(item,indx,CURR_MULTI_SUBFORM_TEMP[curr_indx][0]['master_cf'][cfIndx]['cf_files'].length)  })
        );
      }else if(CURR_MULTI_SUBFORM_TEMP[curr_indx][0]['master_file_list'].length > 0){
        temp_img_obj = CURR_MULTI_SUBFORM_TEMP[curr_indx][0]['master_file_list'].map((item, indx) =>
          new Object({ image: returnImgLink(item), thumbImage: returnImgLink(item),download:item.link,title: returnImgTitle(item,indx,CURR_MULTI_SUBFORM_TEMP[curr_indx][0]['master_file_list'].length)  })
        );
      }else{
        temp_img_obj = [{ image: empyImgLink, thumbImage: empyImgLink,title:"No images found",download:empyImgLink }]
      }
      console.log("image obj :: ",temp_img_obj);
      setimageObject([...temp_img_obj]);
    }

    function move_to_form_view(curr_indx){
      setcurr_card_view_form_ind(curr_indx);
      onImgLoad(curr_indx)
    }

    function expMultisubRow(task_id,mul_sub_index){
      setform_display_view('single_mul_sub_row');
      // // console.log("expMultisubRow ::")
      move_to_form_view(mul_sub_index);
      getTaskUpdateNotes(task_id);
      refresh_update_note_form();
      set_update_not_task_id(task_id);
    }

    function getTaskUpdateNotes(task_id){
      expMasterTaskUpdates = null;
      const data = {
        token: localStorage.getItem('vector_token'),
        task_id:task_id
      }
      try{
        httpClient.post('/bot/getTaskUpdates',data )
        .then(response => {
          let resp = response.data;
          if(resp.status){
            setexpMasterTaskUpdates(resp.data);
            // console.log("getTaskUpdates 1 :: ",resp.data)
          }
        })
        .catch(error => {
          console.error('getTaskUpdates 2:', error);
        });
      } catch (error) {
        console.error('getTaskUpdates 3:', error);
      }
    }

    function refresh_update_note_form(){
      setupdateNoteTaskform({
        description: "",
        attachments: [],
        task_id: "",
        revision: 0
      });
    }

    function set_update_not_task_id(master_task_id){
      setupdateNoteTaskform((prevObject) => ({
        ...prevObject,
        task_id: master_task_id,
      }));
    }

    function handleUpdateNoteDescr(e){
      setupdateNoteTaskform((prevObject) => ({
        ...prevObject,
        description: e,
      }));
    }

    function revert_to_mul_sub_view(){
      setform_display_view('multi_sub');
    }

    function reset_update_note_form(){
      setupdateNoteTaskform((prevObject) => ({
        ...prevObject,
        description: "",
        attachments: []
      }));
    }

    function UpdateTaskNotes(){
      try{
        let data ={
          token: localStorage.getItem('vector_token'),
          data:updateNoteTaskform
        }
        httpClient.post('/task/task_updates',data )
        .then(response => {
          let res = response.data;
          if(res.status){
            reset_update_note_form();
            getTaskUpdateNotes(updateNoteTaskform.task_id);
            props.triggerNotification('success','updated successfully');
          } else {
            props.triggerNotification('error', 'Please try again later');
          }
        })
        .catch(error => {
          console.error('getTaskUpdates 2:', error);
        });
      } catch (error) {
        console.error('getTaskUpdates 3:', error);
      }
      
    }

    function removeUpdNoteOldFile(indexToRemove, rowId) {
      try{
        let playLoad = {
          token: localStorage.getItem('vector_token'),
          file_row_id: rowId,
        }
        httpClient.post('/file/removeS3File',playLoad )
        .then(response => {
          let res = response.data;
          if (res.status === true) {
            setupdateNoteTaskform((prevObject) => ({
              ...prevObject,
              attachments: prevObject.attachments.filter((_, index) => index !== indexToRemove),
            }));
            props.triggerNotification('success', 'Files Removed');
          } else {
            props.triggerNotification('error', 'Please try again later');
          }
        })
        .catch(error => {
          console.error('removeS3File 2:', error);
        });
      } catch (error) {
        console.error('removeS3File 3:', error);
      }
    }

    function openImgGal(eIndx,cfIndx = null,masterIndx = null){
      setimgGalVisible(true);
      setcurrImgIndx(eIndx);
      if(masterIndx != null){
        onImgLoad(masterIndx,cfIndx,eIndx);
      }
    }

    function closeImgGal(){
      setimgGalVisible(false);
    }

    function showBulkImpExp(form_data,form_indx,form_id,export_form_type = 'all'){
      // console.log("form id :: ",form_id)
      setBulkExImp(true);
      setCurrBulkUplFormIndx(form_indx);
      setCurrBulkUplFormId(form_id);
      let curr_BulkFormUpload__addData = {
                                            scope : form_data.scope,
                                            Task_association : form_data.Task_association,
                                            revision : form_data.revision,
                                            cf_instance_id : form_data.cf_instance_id,
                                            task_id : form_data.task_id,
                                            contrib_id : form_data.contrib_id,
                                            export_form_type : export_form_type,
                                            search : ''
                                        }
      setCurrBulkUplSubFormId(form_data.subform_id);
      setCurrBulkUplAddData(curr_BulkFormUpload__addData);
    }

    function closeBulkExpImp(){
      setBulkExImp(false);
    }

    function exportFromRespTemplate(dirr_val = null){
      try{
        const postdata = {
          token: localStorage.getItem('vector_token'),
          form_id : CurrBulkUplFormId,
          form_additional_data : (dirr_val !== null ? dirr_val : CurrBulkUplAddData)
        }
        httpClient.post('/forms/exportFormRespTemplate',postdata,{
          responseType: 'arraybuffer'})
        .then(response => {
          downLoadFile(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;")
        })
        .catch(error => {
          console.error('exportFormRespTemplate 2:', error);
        });
      } catch (error) {
        console.error('exportFormRespTemplate 3:', error);
      }
    }

  function downLoadFile(data, type) {
    var a = document.createElement("a");
    document.body.appendChild(a);

    let blob = new Blob([data], { type: type});
    // console.log("blob  :: ",blob)
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "Bulk_Response_Upload_" +  new Date().getTime() + EXCEL_EXTENSION
    a.click();
    window.URL.revokeObjectURL(url);
  }

  function refreshBulkFileUpldStatus(){
    try{
      const postdata ={
        token:localStorage.getItem('vector_token'),
        upload_type_id : CurrBulkUplFormId
      }
      httpClient.post('/get_bulk_upload_status',postdata)
      .then(response => {
        let res = response.data;
        if(res.status===true){
          setFile_status_list(res.data);
        }
      })
      .catch(error => {
        console.error('get_bulk_upload_status 2:', error);
      });
    } catch (error) {
      console.error('get_bulk_upload_status 3:', error);
    }
  }

  function openFileStatusModal(){
    setfileStatusModal(true);
    refreshBulkFileUpldStatus();
  }

  function closeBulkImpFileStatus(){
    setfileStatusModal(false);
  }

  function download_uploaded_file(file_id){
    try{
      const postdata = {
        token: localStorage.getItem('vector_token'),
        file_id : file_id
      }
      httpClient.post('/get_file_obj_from_file_id',postdata,{
        responseType: 'arraybuffer'})
      .then(response => {
        downLoadFile(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;")
      })
      .catch(error => {
        console.error('get_file_obj_from_file_id 2:', error);
      });
    } catch (error) {
      console.error('get_file_obj_from_file_id 3:', error);
    }
  }

  function download_uploaded_file_with_status(form_id,bu_instance_id,uploaded_file_id){
    try{
      const postdata ={
        token:localStorage.getItem('vector_token'),
        form_id : form_id,
        instance_id: bu_instance_id,
        file_id : uploaded_file_id
      }
      httpClient.post('/exp_bulk_upl_file_with_status',postdata,{
        responseType: 'arraybuffer'})
      .then(response => {
        downLoadFile(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;")
      })
      .catch(error => {
        console.error('exp_bulk_upl_file_with_status 2:', error);
      });
    } catch (error) {
      console.error('exp_bulk_upl_file_with_status 3:', error);
    }
  }

  function download_failed_rows(inst_id){
    let temp_add_data = {
      "Task_association": 0,
      "cf_instance_id": null,
      "contrib_id": null,
      "export_form_type": "all",
      "revision": null,
      "scope": "",
      "search": "",
      "task_id": null
    }
    try{
      const postdata={
        token: localStorage.getItem('vector_token'),
        instance_id:inst_id,
        form_additional_data : temp_add_data
      }
      httpClient.post('/export_bulk_upload_rejected_rows',postdata,{
        responseType: 'arraybuffer'})
      .then(response => {
        downLoadFile(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;")
      })
      .catch(error => {
        console.error('export_bulk_upload_rejected_rows 2:', error);
      });
    } catch (error) {
      console.error('export_bulk_upload_rejected_rows 3:', error);
    }
  }

  function exportMaster(){ 
    let temp_currBulkUpldAddData = {...CurrBulkUplAddData};
    temp_currBulkUpldAddData['export_form_type'] = EXP_FORM_MASTER;
    temp_currBulkUpldAddData['subform_id'] = CurrBulkUplSubFormId;
    temp_currBulkUpldAddData['search'] = subform_search_key;
    exportFromRespTemplate(temp_currBulkUpldAddData);
    setCurrBulkUplAddData(temp_currBulkUpldAddData);
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      '& .MuiTooltip-arrow': {
        color: theme.palette.common.white, // Match the tooltip background color
      },
    
    },
  }));

  const hasMultipleFilledResponses = (row) => {
    // Count the number of columns with filled responses
    const filledResponsesCount = Object.values(row).reduce((count, column) => {
      return count + ((column.response.trim() !== '' || column.user_resp_files.length > 0) ? 1 : 0);
    }, 0);
    // Change the threshold as needed (e.g., > 1 for more than one filled response)
    return filledResponsesCount > 1;
  };

  function imgGalDownload(imageUrl){
    window.open(imageUrl, '_blank');
  }

  function notOpt_multiSub_filt_visible(e,cf_indx){
    console.log("pop over filter togg :: ",e)
    if('filter_visible' in message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]){
      const updatedmessage = {...message};
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['filter_visible'] = e
      setMessage(updatedmessage);
    }else{
      const updatedmessage = {...message};
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['filter_visible'] = e
      updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['selected_filters'] = []
      setMessage(updatedmessage);
    }
  }

  function on_master_cf_stat_refresh(search_key,cf_indx,each_cf){
    // try{
      console.log("on serch :: ",search_key.target.value);
      const postdata ={
        token:localStorage.getItem('vector_token'),
        search_key: search_key.target.value,
        quest_id: CURR_MULTI_SUBFORM_TEMP[0][0]["ques_id"],
        form_id:CURR_MULTI_SUBFORM_TEMP[0][0]["form_id"],
        instance_id:widget[message_index]['data']['bulk_ques_resp_list']['subform_cf_instance_id'],
        quest_id_resp_map:{},
        master_cust_fields:[each_cf]
      }
      httpClient.post('/get-master-cf-stats',postdata)
      .then(response => {
        let resp = response.data;
        if(resp.data){
          console.log("on cf search :: ",resp.data);
          const updatedWidget = [...widget];
          updatedWidget[message_index]['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['filters'] = resp.data;
          setwidgets(updatedWidget);
        } 
      })
    //   .catch(error => {
    //     console.error('get-master-cf-stats 2:', error);
    //   });
    // } catch (error) {
    //   console.error('get-master-cf-stats 3:', error);
    // }
  }

  function OnApplyMultiSubFilters(cf_data,cf_indx){
    closeMasterColFilter();
    const updatedmessage = {...message};
    updatedmessage['data']['bulk_ques_resp_list']['master_cf_ordered_list'][cf_indx]['filter_visible'] = false;
    setMessage(updatedmessage);

    console.log("test cf puch :: ",cf_data);
    let tempcurr_master_filter_list = {...curr_master_filter_list};
    if(cf_data.cfm_id){
      tempcurr_master_filter_list["cfm"][cf_data.cfm_id] = [];
    }
    if(cf_data.seq_id){
      tempcurr_master_filter_list["seq"][cf_data.seq_id] = [];
    }
    for(let mcf_indx = 0;mcf_indx < cf_data.selected_filters.length;mcf_indx ++ ){
      if(cf_data.cfm_id){
        if(cf_data.cfm_id in tempcurr_master_filter_list["cfm"] ){
          tempcurr_master_filter_list["cfm"][cf_data.cfm_id].push(cf_data.selected_filters[mcf_indx]["val"])
        }else{
          tempcurr_master_filter_list["cfm"][cf_data.cfm_id] = [cf_data.selected_filters[mcf_indx]["val"]]
        }
      }
      if(cf_data.seq_id){
        if(cf_data.seq_id in tempcurr_master_filter_list["seq"]){
          tempcurr_master_filter_list["seq"][cf_data.seq_id].push(cf_data.selected_filters[mcf_indx]["val"])
        }else{
          tempcurr_master_filter_list["seq"][cf_data.seq_id] = [cf_data.selected_filters[mcf_indx]["val"]]
        }
      }
    }
    setcurr_master_filter_list(tempcurr_master_filter_list);
    setmultisub_page_no(1);
    console.log("on aply filter event :: ",tempcurr_master_filter_list);
    onFixedMasterSearch(message_index,1,null,null,tempcurr_master_filter_list);
  }
      
  // html /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
  <div>
    {(wholePageLoad) ? (
    <>
      <Stack spacing={1}>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Stack>
    </>) : (
      <>
        { (message && message.type === 'custom_form' && message.display ==='new') ? (
          <div >
            { message.data.form_list.map((form, formIndex) => (
              <div >
                <div className='sectionName'> {form.name}</div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  { form.ques_resp_list.data.list.map((ques, sno) => (
                    <Grid item xs={3}>
                      <div style={{padding: '10px'}} key={sno}>
                        <div key={sno + 'q'} className='question'>({ques.ques_no}) {ques.question} </div>
                        { (ques['free_text'] >= 1 && ques['file_upload'] === 0 && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11) ? (
                          <div className='quesRespBetw'>
                            {(ques['free_text'] !== 9 && ques['free_text'] !== 6 && ques['free_text'] !== 10) ? (
                                <TextField
                                  fullWidth
                                  value={ques['response']}
                                  onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                  className="GridinputBox"
                                  error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                  helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                  key={sno + 'ft'}
                                  size='small'
                                />
                              ) : null}
                            {(ques['free_text'] === 6) ? (
                              <>
                                <TextField
                                  fullWidth
                                  value={ques['response']}
                                  onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                  className="GridinputBox"
                                  error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                  helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                  key={sno + 'ft6'}
                                  size='small'
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton  onClick={(e) => mapdatepick(e,message_index,formIndex,sno,'all_in_one',ques['response'])}>
                                        <CalendarTodayIcon style={{fontSize:'15px'}} />
                                      </IconButton>
                                    ),
                                  }}
                                />
                                </>
                              ) : null}
                            {(ques['free_text'] === 9) ? (
                                <TextField
                                  fullWidth
                                  value={ques['response']}
                                  placeholder="HH:MM"
                                  onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                  className="GridinputBox"
                                  error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                  helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                  key={sno + 'ft9'}
                                  size='small'
                                />
                              ) : null}
                            {(ques['free_text'] === 10)?(
                              <TextField
                                  fullWidth
                                  value={ques['response']}
                                  placeholder="HH:MM"
                                  className="GridinputBox"
                                  onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                  error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                  helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                  key={sno + 'ft10'}
                                  size='small'
                                />
                            ):null}     
                          </div>
                        ):null}
                        { (ques['free_text'] === 7 ||  ques['free_text'] === 8 ||  ques['free_text'] === 11) ? (
                        <div  className='quesRespBetw'>
                          <Autocomplete
                            options={ques['options']}
                            getOptionLabel={(option) => option.val || ""}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e,newInputValue) => handleAllInOneResp(formIndex,sno,newInputValue,'all_in_one')}
                            onOpen={() => reloadOptions('refresh',form['ques_resp_list']['data']['list'],ques,message_index,formIndex,sno,null,'all_in_one')}
                            clearOnBlur
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                onChange={(event) => reloadOptions('search',form['ques_resp_list']['data']['list'],ques,message_index,formIndex,sno,event.target.value,'all_in_one')}
                                error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                sx={{ backgroundColor: 'white'}}
                                size='small'
                              />
                            )}
                            key={sno + 'ft11'}
                          />
                        </div>
                        ):null}
                        { ((ques['options'].length!==0 || ques['free_text'] === 0) && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11 && ques['file_upload'] !== 1 && !ques['tag_option_flag']) ? (
                        <div className='quesRespBetw'>
                          <Autocomplete
                            options={ques['options']}
                            getOptionLabel={(option) => option.val || ques['response']}
                            onChange={(e,newInputValue) => handleAllInOneResp(formIndex,sno,(newInputValue ? newInputValue.val : ''),'all_in_one')}
                            clearOnBlur
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                sx={{ backgroundColor: 'white' }}
                                size='small'
                              />
                            )}
                            key={sno + 'ft12'}
                          />
                        </div>  
                        ) : null} 
                        { (ques['free_text'] === 0 && ques['file_upload'] === 1) ? (
                          <>
                          <Button 
                            fullWidth
                            variant="contained"
                            onClick={() => showFileUpload(message_index,formIndex,sno,'all_in_one',ques['response'],'form_response')} 
                              sx={{backgroundColor : 'white', color: 'black',
                                    '&:hover': {
                                    backgroundColor: '#00AAFF', // Adjust the alpha value to control transparency
                                    color: 'white'
                                } }}
                            >
                            <UploadFileIcon />
                            <span> - Upload File</span>
                          </Button>
                          {(ques.user_resp_files.length > 0) ? (
                            <Paper
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                border: '1px dotted #00AAFF',
                                marginTop:'10px'
                              }}
                              component="ul"
                            >
                              {ques.user_resp_files.map((file, findex) => (
                              <Chip label={file.file_name} icon={<SimCardDownloadIcon />} sx={{marginRight: '5px'}} variant="outlined" onClick={() => downloadUploadedFile(file.link,file.file_name)} onDelete={() => removeSingleOldFile(file.file_row_id,findex,formIndex,sno)} />
                              ))}
                            </Paper>
                          ) : null}
                          </>
                        ) : null}
                        { (ques['tag_option_flag'] && ques['file_upload'] !== 1) ? (
                          <Grid key={sno + 'ft13'} container sx={{boxShadow: '0px 0px 4px 0px #00000040',borderRadius:'5px',marginTop:'10px'}}>
                            {ques['options'].map((option,optInd)=>(
                              <Grid item key={optInd} >
                                <Chip
                                label={option['val']}
                                style={{backgroundColor:(option['checked'] ? '#008FD7' : '#E5F6FF'),color:(option['checked'] ? 'white' : 'black'), height: '20px', margin: '4px', }}
                                onClick={() => tagOptionCheckChange(option['checked'],optInd,option,ques['free_text'],message_index,formIndex,sno,'all_in_one')} />
                              </Grid>
                            ))}
                          </Grid>
                        ) : null}
                      </div>
                    </Grid>
                    ))}
                </Grid>
                <div style={{textAlign: 'left',margin:'10px'}}>
                  <Button 
                  size="large" 
                  variant="contained" 
                  color="primary" 
                  onClick={() => formsubMit(message['data']['form_list'],message['id'],'normal_sumbit')} 
                  sx={{backgroundColor : 'white', color: 'black',
                          '&:hover': {
                          backgroundColor: '#00AAFF', // Adjust the alpha value to control transparency
                          color: 'white'
                      },borderRadius:'25px' }}
                  // disabled={() => enable_submit_for_all_in_one(message['data']['form_list'])}
                  >
                    Submit
                    <KeyboardArrowRightIcon />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : null }
        { (message && message['type']==='menu_on_all_in_one' && message['display']==='new') ? (
          <div>
            <div >
              {/* Start - Normall All in one */}
              {(message['data']['form_type'] === 'form' && !have_bulk_subform(message['data'])) ? (
                <div  >
                  { message.data.form_list.map((form, formIndex) => (
                  <div >
                    <div className='sectionName'> {form.name}</div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      { form.ques_resp_list.data.list.map((ques, sno) => (
                        <Grid item xs={3}>
                          <div style={{padding: '10px'}} key={sno}>
                            <label className='question'>({ques.ques_no}) {ques.question}</label>
                            { (ques['free_text'] >= 1 && ques['file_upload'] === 0 && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11) ? (
                              <div className='quesRespBetw'>
                                {(ques['free_text'] !== 9 && ques['free_text'] !== 6 && ques['free_text'] !== 10) ? (
                                    <TextField
                                      fullWidth
                                      value={ques['response']}
                                      onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                      error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                      helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                      key={sno + 'ft'}
                                      className="GridinputBox"
                                      size='small'
                                    />
                                  ) : null}
                                {(ques['free_text'] === 6) ? (
                                    <TextField
                                      fullWidth
                                      value={ques['response']}
                                      onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                      className="GridinputBox"
                                      error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                      helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                      key={sno + 'ftes'}
                                      size='small'
                                      InputProps={{
                                        endAdornment: (
                                          <IconButton  onClick={(e) => mapdatepick(e,message_index,formIndex,sno,'all_in_one',ques['response'])}>
                                            <CalendarTodayIcon style={{fontSize:'15px'}} />
                                          </IconButton>
                                        ),
                                      }}
                                    />
                                  ) : null}
                                {(ques['free_text'] === 9) ? (
                                    <TextField
                                      fullWidth
                                      value={ques['response']}
                                      placeholder="HH:MM"
                                      onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                      className="GridinputBox"
                                      error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                      helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                      key={sno + 'ft9'}
                                      size='small'
                                    />
                                  ) : null}
                                {(ques['free_text'] === 10)?(
                                  <TextField
                                      fullWidth
                                      value={ques['response']}
                                      placeholder="HH:MM"
                                      className="GridinputBox"
                                      onChange={(e) => handleAllInOneResp(formIndex,sno,e.target.value,'all_in_one')}
                                      error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                      helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                      key={sno + 'ft10'}
                                      size='small'
                                    />
                                ):null}     
                              </div>
                            ):null}
                            { (ques['free_text'] === 7 ||  ques['free_text'] === 8 ||  ques['free_text'] === 11) ? (
                            <div  className='quesRespBetw'>
                              <Autocomplete
                                options={ques['options']}
                                getOptionLabel={(option) => option.val || ""}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e,newInputValue) => handleAllInOneResp(formIndex,sno,newInputValue,'all_in_one')}
                                onOpen={() => reloadOptions('refresh',form['ques_resp_list']['data']['list'],ques,message_index,formIndex,sno,null,'all_in_one')}
                                clearOnBlur
                                clearOnEscape
                                className="GridinputBox"
                                size='small'
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    onChange={(event) => reloadOptions('search',form['ques_resp_list']['data']['list'],ques,message_index,formIndex,sno,event.target.value,'all_in_one')}
                                    error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                    helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                    sx={{ backgroundColor: 'white'}}
                                  />
                                )}
                                key={sno + 'ft11'}
                              />
                            </div>
                            ):null}
                            { ((ques['options'].length!==0 || ques['free_text'] === 0) && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11 && ques['file_upload'] !== 1) ? (
                            <div className='quesRespBetw'>
                              <Autocomplete
                                options={ques['options']}
                                getOptionLabel={(option) => option.val || ques['response']}
                                onChange={(e,newInputValue) => handleAllInOneResp(formIndex,sno,(newInputValue ? newInputValue.val : ''),'all_in_one')}
                                clearOnBlur
                                clearOnEscape
                                className="GridinputBox"
                                size='small'
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],false) === 'error'}
                                    helperText={get_validate_message(ques['ques_id'],ques['free_text'],false)}
                                    sx={{ backgroundColor: 'white' }}
                                  />
                                )}
                                key={sno + 'ft12'}
                              />
                            </div>  
                            ) : null} 
                            { (ques['free_text'] === 0 && ques['file_upload'] === 1) ? (
                              <>
                                <Button 
                                  fullWidth
                                  variant="contained"
                                  onClick={() => showFileUpload(message_index,formIndex,sno,'all_in_one',ques['response'],'form_response')} 
                                    sx={{backgroundColor : 'white', color: 'black',
                                          '&:hover': {
                                          backgroundColor: '#00AAFF', // Adjust the alpha value to control transparency
                                          color: 'white'
                                      } }}
                                  >
                                  <UploadFileIcon />
                                  <span> - Upload File</span>
                                </Button>
                                {(ques.user_resp_files.length > 0) ? (
                                  <Paper
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      flexWrap: 'wrap',
                                      listStyle: 'none',
                                      p: 0.5,
                                      border: '1px dotted #00AAFF'
                                    }}
                                    component="ul"
                                  >
                                    {ques.user_resp_files.map((file, findex) => (
                                    <Chip label={file.file_name} icon={<SimCardDownloadIcon />} sx={{marginRight: '5px'}} variant="outlined" onClick={() => downloadUploadedFile(file.link,file.file_name)} onDelete={() => removeSingleOldFile(file.file_row_id,findex,formIndex,sno)} />
                                    ))}
                                  </Paper>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </Grid>
                        ))}
                    </Grid>
                    
                  </div>
                ))}
                </div>
              ) : null}
              {/* End - Normall All in one */}

              {/* <!--start multiple subform start --> */}
              {(have_bulk_subform(message['data'])) ? (
                (form_display_view === 'multi_sub') ? (
                  <div>
                    {(message['data']['bulk_ques_resp_list']['q1_toggle']) ? (
                      <div >
                        <div style={{marginBottom: '10px'}}>
                          <Grid container >
                            <Grid  item  xs={4}>
                              <div className='sectionName'>{message['data']['bulk_ques_resp_list']['subform_name']}</div>
                            </Grid>
                            <Grid  item xs={8} >
                              <Grid container >
                                <Grid  item  xs={3}>
                                  <Paper
                                    component="form"
                                    sx={{ display: 'flex', alignItems: 'center', width: '100%',borderRadius: 50,height:30, padding:'2px' }}
                                  >
                                    <InputBase
                                      sx={{ ml: 2, flex: 2,pl: 0,fontSize:'12px' }}
                                      placeholder="Search Dream Dashboard"
                                      value={subform_search_key}
                                      onChange={(e) => handleMasterSerach(e.target.value)}
                                      onKeyDown={(e) => enterKeyPress(e,'master_search')}
                                    />
                                    <IconButton type="button" sx={{ p: '2px',width: '40px',height: '40px', borderRadius: '50%' }} aria-label="search" onClick={() => triggerMasterSearch(message_index)}>
                                      <SearchIcon sx={{ width: '20px',height: '20px' }}/>
                                    </IconButton>
                                  </Paper>
                                </Grid>
                                <Grid  item  xs={2}>
                                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid  item  xs={6} >
                                      <Button sx={{backgroundColor: '#00AAFF',borderRadius:'200px',color: 'white',marginLeft: '10px',padding: '6px 15px',width: 'fit-content','&:hover': {
                                      backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                                      color: 'black'
                                      }}} onClick={() => reset_multisub()} title="Reset filters & sort" >
                                          Reset
                                      </Button>
                                    </Grid>
                                    <Grid  item  xs={6} style={{textAlign: 'center'}} >
                                      <Button onClick={() => refresh_mutiSub()} title="Refresh data with applied filters & sort" sx={{backgroundColor: '#00AAFF',borderRadius:'200px',color: 'white',marginLeft: '10px',padding: '6px 15px',width: 'fit-content','&:hover': {
                                      backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                                      color: 'black'
                                      }}}>
                                        <ReplayIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={5} >                            
                                    <Grid container>
                                      <Grid item xs={1.5} >
                                        <Button variant="text" sx={{color:'#4D4D4D'}} onClick={() => onPageForwRBACK("back")}>
                                          <ArrowBackIosOutlinedIcon sx={{width:'15px'}}/>
                                        </Button>
                                      </Grid>
                                      <Grid item xs={2} >
                                        <Select
                                          value={multisub_page_no}
                                          onChange={(e) => onPageChange(e)}
                                          sx={{color:'#4D4D4D',height:'35px',borderRadius:'10px',backgroundColor:'white'}}
                                          autoWidth
                                          size='small'
                                        >
                                          {Array.from({ length: CURR_MULTI_SUBFORM_TOTAL_PAGE_NO }, (_, index) => (
                                            <MenuItem key={index + 1} value={index + 1}>
                                            {index + 1}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                      </Grid>
                                      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color:'#4D4D4D',fontSize:'20px' }}>
                                        / {CURR_MULTI_SUBFORM_TOTAL_PAGE_NO}
                                      </Grid>
                                      <Grid item xs={1} >
                                        <Button sx={{color:'#4D4D4D'}} onClick={() => onPageForwRBACK("front")}>
                                          <ArrowForwardIosOutlinedIcon sx={{width:'15px',marginLeft: '-40px'}} />
                                        </Button>
                                      </Grid>
                                      
                                      <Grid item xs={2}>
                                          <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={MULTI_SUB_BATCH_COUNT}
                                            onChange={(e) => onPageSizeChange(e)}
                                            size='small'
                                            sx={{color:'#4D4D4D',height:'35px',borderRadius:'10px',backgroundColor:'white'}}
                                          >
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                          </Select>
                                        
                                      </Grid>

                                      <Grid item xs={1.8}>
                                        <Avatar
                                          sx={{ width: 40, height: 40 , marginLeft: '20px', backgroundColor: 'white', color: '#00AAFF',cursor:'pointer'}}
                                          onClick={()=>showBulkImpExp(message['data']['bulk_ques_resp_list'],0,message['data']['bulk_ques_resp_list']['bulk_upload_form_id'])}
                                        >
                                          <ImportExportIcon />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs={1.5}>
                                        {/* <Avatar
                                          sx={{ width: 40, height: 40 , marginLeft: '20px', backgroundColor: 'white', color: '#00AAFF',cursor:'pointer'}}
                                          onClick={handleFullScreen}
                                        >
                                          <FullscreenOutlinedIcon />
                                        </Avatar> */}
                                      </Grid>
                                    </Grid>
                                  {/* </Paper> */}
                                </Grid>
                                <Grid xs={2}>
                                {(!message['data']['bulk_ques_resp_list']['each_row_submit']) ? (
                                  
                                    <Button 
                                    sx={{backgroundColor: '#00AAFF',borderRadius:'200px',color: 'white',marginLeft: '10px',padding: '6px 15px',width: 'fit-content','&:hover': {
                                      // sx={{'font-family': 'Montserrat','font-style': 'normal','font-weight': '600','font-size': '13px','line-height': '20px',display: 'flex','align-items': 'center','text-align': 'center',backgroundColor : 'white', color: 'black','border-radius': '200px',border: 'none !important',transition: '0.3s ease',color:'#4D4D4D',boxShadow: '0px 0px 4px 0px #00000040',padding:'3px 15px',width: 'fit-content','&:hover': {

                                      backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                                      color: 'black'
                                      }}} onClick={() => submit_multiple_subform_atonce(message['data'],message['id'],message_index)} title="Submit" > 
                                      Submit All
                                    </Button>
                                  
                                  ) : null}
                                </Grid> 
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ):null}
                    {/* <p>dateaa : {JSON.stringify(CURR_MULTI_SUBFORM_TEMP)}</p> */}
                    {(message['data']['bulk_ques_resp_list']['col_names'].length === 0 && CURR_MULTI_SUBFORM_TEMP.length===0) ? (
                      <div >
                          Empty
                      </div>
                    ) : null}
                    {(message['data']['bulk_ques_resp_list']['col_names'].length !== 0 && CURR_MULTI_SUBFORM_TEMP.length!==0) ? (
                      <div>
                        {(message['data']['multi_subform_type'] === 'grid view') ? (
                          <div className='scroll_x_div' >
                            <div className="resp_outer_horiz">
                              <div className="Table_Content">
                                <div id="resp-table" style={{width : multiSubFormWidth}}>
                                  <div id="resp-table-header">
                                    <div className="table-header-cell-sno">
                                      <div className='resp-table-cell-content'>Sno</div>
                                    </div>
                                    {(message['data']['bulk_ques_resp_list']['q1_toggle']) ? (
                                      < >
                                        {message['data']['bulk_ques_resp_list']['col_names'].map((each_col,Hindx) => 
                                          <>
                                            <div className="table-header-cell">
                                              <div className='resp-table-cell-content'>
                                                {each_col.col}
                                              </div>
                                            </div>
                                            {(each_col.fixed_col)?(
                                                message['data']['bulk_ques_resp_list']['master_cf_ordered_list'].map((each_cf,cf_indx) =>
                                                  <div className="table-header-cell-cf" style={{width : `${each_cf.col_size}px`}} >
                                                    <div className='resp-table-cell-content'>
                                                      <a class={`${(each_cf.selected_filters && each_cf.selected_filters.length > 0) ? 'filter_higlight' : ''}`} onClick={(e) => openMasterColFilter(e,cf_indx)}> 
                                                        <FilterAltOutlinedIcon  className="Filtericon" />
                                                      </a>
                                                      {each_cf.cf_key} 
                                                    
                                                      {(each_cf.order_by.length === 0 || (each_cf.order_by.length > 0 && each_cf.order_by === 'desc')) ? (
                                                        <a onClick={() => changeOrder(cf_indx,each_cf,'task_custom_field',each_cf.cf_key,each_cf.cfm_id,each_cf.seq_id,'asc')}>
                                                          <KeyboardArrowUpIcon className="arrow-icon"/>
                                                        </a>
                                                      ) : null}
                                                      {(each_cf.order_by.length === 0 || (each_cf.order_by.length > 0 && each_cf.order_by === 'asc')) ? (
                                                        
                                                          <a onClick={() => changeOrder(cf_indx,each_cf,'task_custom_field',each_cf.cf_key,each_cf.cfm_id,each_cf.seq_id,'desc')}>
                                                            <KeyboardArrowDownIcon className="arrow-icon"/>
                                                          </a>
                                                        
                                                      ) : null}
                                                        
                                                    </div>
                                                  </div>
                                                )
                                            ):(null)}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {message['data']['bulk_ques_resp_list']['col_names'].map((each_col, Hindx) => (
                                          <div className="table-header-cell" key={'col-' + Hindx}>
                                            <div className='resp-table-cell-content'>
                                              <p>{each_col.col}</p>
                                              {each_col['mandatory'] === 1 ? <p>*</p> : null}
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {(message['data']['bulk_ques_resp_list']['each_row_submit']) ? (
                                      <div className="table-header-cell-cf" >
                                        <div className='resp-table-cell-content'>
                                          Action
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                  {(onTableRowLoad) ? (
                                    <Stack spacing={1}>
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                    </Stack>
                                  ) : (
                                    <div className="resp_outer_vertical">
                                        <div id="resp-table" style={{'width': (multiSubFormWidth + 'px')}}>
                                            <div id="resp-table-body" >
                                              {CURR_MULTI_SUBFORM_TEMP.map((each_row,block_sno) => (
                                                <>
                                                  <div key={'row'+block_sno} style={{backgroundColor : (hasMultipleFilledResponses(each_row) ? '#FFFBE5' : 'inherit') }}  className="resp-table-row">
                                                    <div className="table-body-cell-sno">
                                                      <div className='resp-table-cell-content'>{block_sno + 1}</div>
                                                    </div>
                                                    {/* <div className="table-body-cell-cb">
                                                      <div className='resp-table-cell-content'>
                                                        <Checkbox
                                                          {...label}
                                                          sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                        />
                                                        </div>
                                                    </div> */}
                                                    
                                                    {each_row.map((ques,sno)=> (
                                                        (ques['fixed_col']) ? (
                                                          <>
                                                            <div className="table-body-cell">
                                                              <div className='resp-table-cell-content'>
                                                                <a className="masterGridHyper" onClick={() => expMultisubRow(ques['response'],block_sno)}>{ques['response_preview']}</a>
                                                              </div>
                                                            </div>
                                                            {ques['master_cf'].map((each_cf_pair,cfref_indx) => 
                                                              <div key={block_sno + sno + cfref_indx} className="table-body-cell-cf"  style={{width: `${each_cf_pair.col_size}px`}}  >
                                                                <div className='resp-table-cell-content' >
                                                                  {(each_cf_pair.cf_files.length === 0) ? (
                                                                    (each_cf_pair['cf_value'].length <= cf_trunc_len) ? (
                                                                      <div style={{whiteSpace: 'break-spaces'}} >
                                                                        {each_cf_pair['cf_value']}
                                                                      </div>
                                                                    ) : (
                                                                      <LightTooltip
                                                                        title={
                                                                            <div style={{ display: 'flex', alignItems: 'center'}} >
                                                                              <ContentCopyIcon sx={{fontSize:'18px',color:'#1b55e3'}} onClick={() => {
                                                                                  // Logic to copy the text to clipboard
                                                                                  navigator.clipboard.writeText(each_cf_pair['cf_value']);
                                                                                }} />
                                                                                <Typography> {each_cf_pair['cf_value']}</Typography>
                                                                            </div>}
                                                                        arrow
                                                                      >
                                                                        <span style={{cursor:'pointer'}}>{each_cf_pair['cf_value'].slice(0, cf_trunc_len)}...</span>
                                                                      </LightTooltip>
                                                                    )
                                                                  ) : (<div>
                                                                    {/* <p>files in</p> */}
                                                                    <div style={{  boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '5px' , display: 'flex',justifyContent: 'center',verticalAlign: 'middle',width:'auto',height:'auto',padding:'10px' }}>
                                                                      {each_cf_pair.cf_files.slice(0, 2).map((file, index) => (
                                                                        <div key={index} onClick={() => openImgGal(index,cfref_indx,block_sno)}
                                                                        className="image-container"
                                                                        >
                                                                          {/* Display a centered thumbnail without bullet points */}
                                                                          <img
                                                                            src={returnImgLink(file)}
                                                                            alt={file.file_name}
                                                                            className="thumbnail"
                                                                          />
                                                                        </div>
                                                                      ))}
                                                                      {each_cf_pair.cf_files.length > 2 && (
                                                                          <div className="image-view" onClick={() => openImgGal(0,cfref_indx,block_sno)}>
                                                                            <div className="count">
                                                                              +{each_cf_pair.cf_files.length - 2}
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                    </div>
                                                                  </div>)}
                                                                </div>
                                                              </div>
                                                            )}
                                                          </>
                                                        ) : (
                                                              <div className="table-body-cell" >
                                                                <div className='resp-table-cell-content'>
                                                                  { (ques['free_text'] >= 1 && ques['file_upload'] === 0 && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11) ? (
                                                                    <>
                                                                      {(ques['free_text'] !== 9 && ques['free_text'] !== 6 && ques['free_text'] !== 10) ? (
                                                                          <TextField
                                                                            fullWidth
                                                                            value={ques['response']}
                                                                            onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',block_sno)}
                                                                            className="GridinputBox"
                                                                            error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                            helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                            key={'row'+block_sno + 'ques' + sno + 'rt1'}
                                                                            size='small'
                                                                          />
                                                                        ) : null}
                                                                      {(ques['free_text'] === 6) ? (
                                                                          <TextField
                                                                            fullWidth
                                                                            value={ques['response']}
                                                                            onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',block_sno)}
                                                                            className="GridinputBox"
                                                                            error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                            helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                            key={'row'+block_sno + 'ques' + sno + 'rt11'}
                                                                            size='small'
                                                                            InputProps={{
                                                                              endAdornment: (
                                                                                <IconButton  onClick={(e) => mapdatepick(e,message_index,null,sno,'all_in_one',ques['response'],true,block_sno)}>
                                                                                  <CalendarTodayIcon style={{fontSize:'15px'}} />
                                                                                </IconButton>
                                                                              ),
                                                                            }}
                                                                          />
                                                                        ) : null}
                                                                      {(ques['free_text'] === 9) ? (
                                                                          <TextField
                                                                            fullWidth
                                                                            value={ques['response']}
                                                                            placeholder="HH:MM"
                                                                            onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',block_sno)}
                                                                            className="GridinputBox"
                                                                            error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                            helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                            key={'row'+block_sno + 'ques' + sno + 'rt3'}
                                                                            size='small'
                                                                          />
                                                                        ) : null}
                                                                      {(ques['free_text'] === 10)?(
                                                                        <TextField
                                                                            fullWidth
                                                                            value={ques['response']}
                                                                            placeholder="HH:MM"                                                                            className="GridinputBox"
                                                                            onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',block_sno)}
                                                                            error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                            helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                            key={'row'+block_sno + 'ques' + sno + 'rt3'}
                                                                            size='small'
                                                                          />
                                                                      ):null}     
                                                                    </>
                                                                  ):null}
                                                                  { (ques['free_text'] === 7 ||  ques['free_text'] === 8 ||  ques['free_text'] === 11) ? (
                                                                    <>
                                                                    <Autocomplete
                                                                      options={ques['options']}
                                                                      getOptionLabel={(option) => option.val || ''}
                                                                      getOptionSelected={(option, value) => option.id === value.id}
                                                                      onChange={(e,newInputValue) => handleAllInOneResp(null,sno,newInputValue,'multi_subform',block_sno)}
                                                                      onOpen={() => reloadOptions('refresh',each_row,ques,message_index,null,sno,null,'all_in_one',true,block_sno)}
                                                                      clearOnBlur
                                                                      clearOnEscape
                                                                      className='GridinputBox'
                                                                      value={ques.options.find((opt) => opt.id === ques.response) || null} // Set the input value
                                                                      renderInput={(params) => (
                                                                        <TextField
                                                                          {...params}
                                                                          fullWidth
                                                                          onChange={(event) => reloadOptions('search',each_row,ques,message_index,null,sno,event.target.value,'all_in_one',true,block_sno)}
                                                                          error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                          helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                          className='GridinputBox'
                                                                          size='small'
                                                                        />
                                                                      )}
                                                                      key={'row'+block_sno + 'ques' + sno + 'rt5'}
                                                                    />
                                                                    </>
                                                                  ):null}
                                                                  { (!ques['tag_option_flag'] && (ques['options'].length!==0 || ques['free_text'] === 0) && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11 && ques['file_upload'] !== 1) ? (
                                                                  
                                                                    <Autocomplete
                                                                      options={ques['options']}
                                                                      getOptionLabel={(option) => option.val || ques['response']}
                                                                      onChange={(e,newInputValue) => handleAllInOneResp(null,sno,(newInputValue ? newInputValue.val : ''),'multi_subform',block_sno)}
                                                                      clearOnBlur
                                                                      clearOnEscape
                                                                      className='GridinputBox'
                                                                      renderInput={(params) => (
                                                                        <TextField
                                                                          {...params}
                                                                          fullWidth
                                                                          error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,block_sno) === 'error'}
                                                                          helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,block_sno)}
                                                                          size='small'
                                                                        />
                                                                      )}
                                                                      key={'row'+block_sno + 'ques' + sno + 'rt6'}
                                                                    />
                                                                  
                                                                  ) : null} 
                                                                  { (ques['free_text'] === 0 && ques['file_upload'] === 1) ? (
                                                                    <>
                                                                      <Grid container >
                                                                        <Grid  item  xs={12}>
                                                                          <Button 
                                                                            fullWidth
                                                                            onClick={() => showFileUpload(message_index,null,sno,'multi_subform',ques['response'],'form_response',true,block_sno)} 
                                                                              sx={{backgroundColor : 'white', color: 'black',border:'1px solid #00AAFF',
                                                                                    '&:hover': {
                                                                                    backgroundColor: '#00AAFF', // Adjust the alpha value to control transparency
                                                                                    color: 'white'
                                                                                } }}
                                                                            >
                                                                            <UploadFileIcon />
                                                                            <span> - Upload File</span>
                                                                          </Button>
                                                                        </Grid>
                                                                        <Grid  item  xs={12}>
                                                                          {(ques.user_resp_files.length > 0) ? (
                                                                            <Paper
                                                                              sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                flexWrap: 'wrap',
                                                                                listStyle: 'none',
                                                                                p: 0.5,
                                                                                border: '1px dotted #00AAFF'
                                                                              }}
                                                                              component="ul"
                                                                            >
                                                                              {ques.user_resp_files.map((file, findex) => (
                                                                              <Chip label={file.file_name} icon={<SimCardDownloadIcon />} sx={{marginRight: '5px'}} variant="outlined" onClick={() => downloadUploadedFile(file.link,file.file_name)} onDelete={() => removeSingleOldFile(file.file_row_id,findex,null,sno,true,block_sno)} />
                                                                              ))}
                                                                            </Paper>
                                                                          ) : null}
                                                                        </Grid>
                                                                      </Grid>
                                                                    </>
                                                                  ) : null}
                                                                  {(ques['tag_option_flag'] && ques['file_upload'] !== 1) ? (
                                                                    <>
                                                                      <Grid container sx={{ boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '5px' }} justifyContent="center" alignItems="center">
                                                                        {ques['options'].map((option, optInd) => (
                                                                          <Grid item key={optInd}>
                                                                            <Chip
                                                                              label={option['val']}
                                                                              style={{
                                                                                backgroundColor: option['checked'] ? '#008FD7' : '#E5F6FF',
                                                                                color: option['checked'] ? 'white' : 'black',
                                                                                height: '20px',
                                                                                margin: '2px',
                                                                              }}
                                                                              onClick={() => tagOptionCheckChange(option['checked'], optInd, option, ques['free_text'], message_index, null, sno, 'all_in_one', true, block_sno)}
                                                                            />
                                                                          </Grid>
                                                                        ))}
                                                                      </Grid>
                                                                    </>
                                                                  ) : null}

                                                                </div>
                                                              </div>
                                                          )
                                                      ))}
                                                    {(message['data']['bulk_ques_resp_list']['each_row_submit']) ? (
                                                    <div className="table-body-cell-cf"  >
                                                      <div className='margintopcust'>
                                                        <Button onClick={() => submit_single_row_grid_view(message['data'],block_sno)} title="Submit" sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white','&:hover': {
                                                                                backgroundColor: 'white', // Adjust the alpha value to control transparency
                                                                                color: 'black',
                                                                                border: '1px solid #00AAFF'
                                                                            }}}> 
                                                          <ArrowRightAltIcon />
                                                        </Button>
                                                      </div>
                                                    </div>
                                                    ) : null}
                                                  </div>
                                                  
                                                </>
                                              )
                                              )}
                                            </div>
                                        </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : null }
                      </div>
                    ) : null }
                  </div>
                ) : (
                  <>
                  <div className='multi_sub_single_div'>
                    <div className="headersingle">
                      <Fab style={{backgroundColor:'white'}}  onClick={revert_to_mul_sub_view}>
                        <KeyboardBackspaceIcon />
                      </Fab>
                      <span className='breadcrum'>
                        &nbsp;&nbsp;{message['data']['bulk_ques_resp_list']['subform_name']}
                        &nbsp;/&nbsp;
                        {CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind][0]['response_preview']}
                      </span>
                    </div>
                    <br></br>
                    <div >
                      <Grid container>
                        <Grid item xs={4}>
                          <Grid container>
                            <Divider orientation="vertical" flexItem></Divider>
                            <Grid item xs={2} style={{ display: 'flex' }}>
                              &nbsp; <ModeEditIcon sx={{fontSize: 18}} />&nbsp; Edit &nbsp;
                            </Grid>
                            <Divider orientation="vertical" flexItem></Divider>
                            <Grid item xs={4} style={{ display: 'flex' }}>
                              &nbsp; <MarkChatUnreadIcon sx={{fontSize: 20}} />&nbsp; Update Note &nbsp;
                            </Grid>
                            <Divider orientation="vertical" flexItem></Divider>
                            <Grid item xs={4} style={{ display: 'flex' }}>
                              &nbsp; <ListAltIcon sx={{fontSize: 20}} />&nbsp; Activity Log &nbsp;
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}></Grid>
                      </Grid>
                      <br></br>
                      <Divider />
                    </div>
                    <br></br>
                    <div className='customFields'>
                      <Grid container rowSpacing={2} columnSpacing={1}>
                        {CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind][0]['master_cf'].map((each_cf_pair,ecfIndx) => (
                          <>
                            <Grid item xs={3.9} key={ecfIndx} >
                              <Grid container>
                                <Grid item xs={5}><b>{each_cf_pair['cf_key']}</b></Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={6}>
                                  {each_cf_pair['cf_value']}
                                </Grid>
                              </Grid>
                            </Grid>
                            {((ecfIndx + 1)%3 !== 0) ? (<Divider orientation="vertical" flexItem></Divider>) : null}
                          </>
                        ))}
                        {(CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind][0]['master_file_list'].length > 0)? (
                          <Grid item xs={3.9} key="attachments" >
                            <Grid container>
                              <Grid item xs={3}><b>Attachments</b></Grid>
                              <Grid item xs={1}>:</Grid>
                              <Grid item xs={8}>
                                <div style={{backgroundColor:'white',padding:'10px'}}>
                                  <Grid container columnSpacing={1} >
                                    <>
                                      {imageObject.map((efile,eIndx) => (
                                        (eIndx <= 2)?(
                                          <Grid key={eIndx} item xs={3}>
                                            <Avatar onClick={() => openImgGal(eIndx)} src={efile.thumbImage} sx={{boxShadow: '0px 0px 2px 0px #00000040',height:'70px',width:'55px'}} variant="rounded" />
                                          </Grid>
                                        ):null
                                      ))}
                                      {(imageObject.length > 3) ? (
                                        <Fab size="small" onClick={() => openImgGal(0)} style={{backgroundColor:'white',color:'#00AAFF',fontWeight:'500',fontSize:'1rem',margin:'auto'}} >
                                          + {(imageObject.length - 3)}
                                        </Fab>
                                      ):null}
                                    </>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='SinglformDiv'>
                      <div className='formName'>Edit</div>
                      <div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind].map((ques,sno)=>(
                          <Grid item xs={4}>
                            <div style={{padding: '10px'}} key={sno}>
                              <div key={sno + 'q'} className='question'>({ques.ques_no}) {ques.question} </div>
                                { (ques['free_text'] >= 1 && ques['file_upload'] === 0 && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11) ? (
                                  <>
                                    {(ques['free_text'] !== 9 && ques['free_text'] !== 6 && ques['free_text'] !== 10) ? (
                                        <TextField
                                          fullWidth
                                          value={ques['response']}
                                          onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',curr_card_view_form_ind)}
                                          className="GridinputBox"
                                          error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                          helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                          key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt1'}
                                          size='small'
                                        />
                                      ) : null}
                                    {(ques['free_text'] === 6) ? (
                                        <TextField
                                          fullWidth
                                          value={ques['response']}
                                          onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',curr_card_view_form_ind)}
                                          className="GridinputBox"
                                          error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                          helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                          key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt11'}
                                          size='small'
                                          InputProps={{
                                            endAdornment: (
                                              <IconButton  onClick={(e) => mapdatepick(e,message_index,null,sno,'all_in_one',ques['response'],true,curr_card_view_form_ind)}>
                                                <CalendarTodayIcon style={{fontSize:'15px'}} />
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                      ) : null}
                                    {(ques['free_text'] === 9) ? (
                                        <TextField
                                          fullWidth
                                          value={ques['response']}
                                          placeholder="HH:MM"
                                          onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',curr_card_view_form_ind)}
                                          className="GridinputBox"
                                          error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                          helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                          key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt3'}
                                          size='small'
                                        />
                                      ) : null}
                                    {(ques['free_text'] === 10)?(
                                      <TextField
                                        type="date"
                                        fullWidth
                                        value={ques['response']}
                                        onChange={(e) => handleAllInOneResp(null,sno,e.target.value,'multi_subform',curr_card_view_form_ind)}
                                        error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                        helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                        placeholder="HH:MM"
                                        size='small'
                                        InputProps={{
                                          inputProps: {
                                            pattern: '([01]?[0-9]|2[0-3]):[0-5][0-9]',
                                          },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <span>:</span>
                                            </InputAdornment>
                                          ),
                                        }}
                                        className="GridinputBox"
                                        key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt4'}
                                      />
                                    ):null}     
                                  </>
                                ):null}
                                { (ques['free_text'] === 7 ||  ques['free_text'] === 8 ||  ques['free_text'] === 11) ? (
                                  <>
                                  <Autocomplete
                                    options={ques['options']}
                                    getOptionLabel={(option) => option.val || ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e,newInputValue) => handleAllInOneResp(null,sno,newInputValue,'multi_subform',curr_card_view_form_ind)}
                                    onOpen={() => reloadOptions('refresh',CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind],ques,message_index,null,sno,null,'all_in_one',true,curr_card_view_form_ind)}
                                    clearOnBlur
                                    clearOnEscape
                                    className='GridinputBox'
                                    value={ques.options.find((opt) => opt.id === ques.response) || null} // Set the input value
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        onChange={(event) => reloadOptions('search',CURR_MULTI_SUBFORM_TEMP[curr_card_view_form_ind],ques,message_index,null,sno,event.target.value,'all_in_one',true,curr_card_view_form_ind)}
                                        error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                        helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                        className='GridinputBox'
                                        size='small'
                                      />
                                    )}
                                    key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt5'}
                                  />
                                  </>
                                ):null}
                                { (!ques['tag_option_flag'] && (ques['options'].length!==0 || ques['free_text'] === 0) && ques['free_text'] !== 7 && ques['free_text'] !== 8 && ques['free_text'] !== 11 && ques['file_upload'] !== 1) ? (
                                
                                  <Autocomplete
                                    options={ques['options']}
                                    getOptionLabel={(option) => option.val || ques['response']}
                                    onChange={(e,newInputValue) => handleAllInOneResp(null,sno,(newInputValue ? newInputValue.val : ''),'multi_subform',curr_card_view_form_ind)}
                                    clearOnBlur
                                    clearOnEscape
                                    className='GridinputBox'
                                    value={ques.options.find((opt) => opt.val === ques.response) || ""} // Set the input value
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={validate_response_format(ques['ques_id'],ques,ques['free_text'],ques['response'],true,curr_card_view_form_ind) === 'error'}
                                        helperText={get_validate_message(ques['ques_id'],ques['free_text'],true,curr_card_view_form_ind)}
                                        size='small'
                                      />
                                    )}
                                    key={'row'+curr_card_view_form_ind + 'ques' + sno + 'rt6'}
                                  />
                                
                                ) : null} 
                                { (ques['free_text'] === 0 && ques['file_upload'] === 1) ? (
                                  <>
                                    <Grid container >
                                      <Grid  item  xs={12}>
                                        <Button 
                                          fullWidth
                                          onClick={() => showFileUpload(message_index,null,sno,'multi_subform',ques['response'],'update_note',true,curr_card_view_form_ind)} 
                                            sx={{backgroundColor : 'white', color: 'black',border:'1px solid #00AAFF',
                                                  '&:hover': {
                                                  backgroundColor: '#00AAFF', // Adjust the alpha value to control transparency
                                                  color: 'white'
                                              } }}
                                          >
                                          <UploadFileIcon />
                                          <span> - Upload File</span>
                                        </Button>
                                      </Grid>
                                      <Grid  item  xs={12}>
                                        {(ques.user_resp_files.length > 0) ? (
                                          <Paper
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              flexWrap: 'wrap',
                                              listStyle: 'none',
                                              p: 0.5,
                                              border: '1px dotted #00AAFF'
                                            }}
                                            component="ul"
                                          >
                                            {ques.user_resp_files.map((file, findex) => (
                                            <Chip label={file.file_name} icon={<SimCardDownloadIcon />} sx={{marginRight: '5px'}} variant="outlined" onClick={() => downloadUploadedFile(file.link,file.file_name)} onDelete={() => removeSingleOldFile(file.file_row_id,findex,null,sno,true,curr_card_view_form_ind)} />
                                            ))}
                                          </Paper>
                                        ) : null}
                                      </Grid>
                                    </Grid>
                                  </>
                                ) : null}
                                { (ques['tag_option_flag'] && ques['file_upload'] !== 1) ? (
                                  <Grid container  sx={{boxShadow: '0px 0px 4px 0px #00000040',borderRadius:'5px'}}>
                                    {ques['options'].map((option,optInd)=>(
                                      <Grid item key={optInd} >
                                        <Chip
                                        label={option['val']}
                                        style={{backgroundColor:(option['checked'] ? '#008FD7' : '#E5F6FF'),color:(option['checked'] ? 'white' : 'black'), height: '20px', margin: '4px', }}
                                        onClick={() => tagOptionCheckChange(option['checked'],optInd,option,ques['free_text'],message_index,null,sno,'all_in_one',true,curr_card_view_form_ind)} />
                                      </Grid>
                                    ))}
                                  </Grid>
                                ) : null}
                            </div>
                          </Grid>
                          ))}
                        </Grid>
                        <br></br>
                        {(message['data']['bulk_ques_resp_list']['each_row_submit']) ? (
                          <Button onClick={() => submit_single_row_grid_view(message['data'],curr_card_view_form_ind)} title="Submit" sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white','&:hover': {
                                                  backgroundColor: 'white', // Adjust the alpha value to control transparency
                                                  color: 'black',
                                                  border: '1px solid #00AAFF'
                                              }}}> 
                            Submit <ArrowRightAltIcon />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div className='SinglformDiv'>
                      <div className='formName'>Update Note</div>
                        <div>
                          <List sx={{ width: '99%',height:'400px',overflowY:'scroll', bgcolor: 'background.paper',padding:'10px',boxShadow:'inset 0 0 0.4em rgba(0, 0, 0, 0.2)' }}>
                            {expMasterTaskUpdates.map((value,i)=>(
                              <ListItem key={i} alignItems="flex-start" sx={{border: '0.1px solid gray',borderRadius:'5px',marginTop:'10px'}}>
                                <ListItemAvatar >
                                  <Avatar style={{backgroundColor:"#00AAFF"}}>{value.created_user[0]}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={value.created_user}
                                  secondary={
                                    <React.Fragment>
                                      <Typography>
                                        <b>{value.description}</b>
                                      </Typography>
                                      {(value.attachments.length > 0) ? (
                                        <Grid container sx={{marginTop:'20px',width:'auto'}} >
                                          <Grid item xs={12}><b>Attachments : </b></Grid>
                                          <Grid item xs={12}>
                                            <div style={{backgroundColor:'white',padding:'10px',borderRadius:'10px',boxShadow:'0px 0px 2px 0px #00000040',height:'70px'}}>
                                              <Grid container columnSpacing={1} >
                                                <>
                                                  {value.attachments.map((efile,eIndx) => (
                                                    // (eIndx <= 2)?(
                                                      <Grid key={eIndx} item xs={1}>
                                                        <Avatar onClick={() => imgGalDownload(returnImgLink(efile,'file_name','link',true))} src={returnImgLink(efile,'file_name','link',false)} sx={{boxShadow: '0px 0px 2px 0px #00000040',height:'70px',width:'55px',objectFit:'contain'}} variant="rounded" />
                                                      </Grid>
                                                    // ):null
                                                  ))}
                                                  {/* {(value.attachments.length > 3) ? (
                                                    <Fab size="small" onClick={() => openImgGal(0)} style={{backgroundColor:'white',color:'#00AAFF',fontWeight:'500',fontSize:'1rem',margin:'auto'}} >
                                                      + {(value.attachments.length - 3)}
                                                    </Fab>
                                                  ):null} */}
                                                </>
                                              </Grid>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      ) : null}
                                      {value.created_date}
                                    </React.Fragment>
                                  }
                                />
                            </ListItem>
                            ))}
                          </List>
                        </div>
                        <Grid container spacing={0.5} style={{marginTop:'10px'}}>
                          <Grid item xs={11.5}>
                            <Paper
                              component="form"
                              elevation={8}
                              sx={{ display: 'flex', alignItems: 'center',borderRadius: 50,height:35, padding:'2px' }}
                            >
                              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => showFileUpload(message_index,null,null,'multi_subform',null,'TaskUpdates',true,curr_card_view_form_ind)}>
                                <AttachFileIcon />
                              </IconButton>
                              <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Type you updates here."
                                value={updateNoteTaskform.description}
                                onChange={(e) => handleUpdateNoteDescr(e.target.value)}
                                onKeyDown={(e) => enterKeyPress(e,'update_note')}
                              />
                            </Paper>
                          </Grid>
                          <Grid item xs={0.5}>
                            <Fab size="small" style={{backgroundColor:'#00AAFF',color:'white'}} onClick={UpdateTaskNotes}>
                              <SendIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                        {(updateNoteTaskform.attachments.length > 0) ? (
                          <Paper
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                              listStyle: 'none',
                              p: 0.5,
                              border: '1px dotted #00AAFF'
                            }}
                            component="ul"
                          >
                            {updateNoteTaskform.attachments.map((file, findex) => (
                            <Chip key={findex} label={file.file_name} icon={<SimCardDownloadIcon />} sx={{marginRight: '5px'}} variant="outlined" onClick={() => downloadUploadedFile(file.link,file.file_name)} onDelete={() => removeUpdNoteOldFile(findex,file.file_row_id)} />
                            ))}
                          </Paper>
                        ) : null}
                    </div>
                  </div>
                  </>
                )
              ):null}
              {/* <!-- multiple sybform end --> */}
              {(message['data']['form_type'] === 'subform_list') ? (
                <div style={{marginTop:'20px'}} >
                  <List>
                      {message['data']['subform_list'].map((each_subform,subfIndx) => {
                        <ListItem disablePadding>
                          <ListItemButton onClick={() => sendmsg(each_subform['as'],message['id'])}>
                            <EditNoteIcon />
                            <ListItemText primary={each_subform.subform_name} />
                          </ListItemButton>
                        </ListItem>
                      })}
                  </List>
                </div>
              ):null}
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {(!have_bulk_subform(message['data'])) ? (
                <Grid  item xs={3}>
                  <div style={{margin:'10px'}}>
                      <Button 
                      size="large" 
                      variant="contained" 
                      color="primary" 
                      onClick={() => formsubMit(message['data']['form_list'],message['id'],'submit_and_continue')} 
                      sx={{backgroundColor : '#00AAFF', color: 'white', border: '1px solid', borderColor: '#00AAFF'}}
                      // disabled={() => enable_submit_for_all_in_one(message['data']['form_list'])}
                      >
                        Submit
                        <KeyboardArrowRightIcon />
                      </Button>
                  </div>
                </Grid>
                ) : (<>
                    {(!message['data']['bulk_ques_resp_list']['q1_toggle']) ? (
                        <Grid item xs={9}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{padding:'20px'}}>
                            <Grid item  xs={3}>
                              <Button 
                                size="small" 
                                variant="contained" 
                                color="primary" 
                                onClick={() => add_one_more_subform_resp(message_index)} title="add one more row"
                                sx={{backgroundColor : '#00AAFF', color: 'white', border: '1px solid', borderColor: '#00AAFF'}}
                                >
                                  Add One More
                                <AddIcon />
                              </Button>
                            </Grid>
                            {/* <Grid item  xs={3}>
                              <Button 
                                size="small" 
                                variant="contained" 
                                color="primary" 
                                onClick={() => dublicate_last_fill_subform_resp()} title="Duplicate last filled"
                                sx={{backgroundColor : '#00AAFF', color: 'white', border: '1px solid', borderColor: '#00AAFF'}}
                                >
                                  Duplicate Last Fill
                                <AddIcon />
                              </Button>
                            </Grid> */}
                            <Grid  item  xs={3}>
                              {(!message['data']['bulk_ques_resp_list']['each_row_submit']) ? (
                                <Button 
                                  size="small" 
                                  variant="contained" 
                                  color="primary" 
                                  sx={{backgroundColor : '#00AAFF', color: 'white', border: '1px solid', borderColor: '#00AAFF',width: 'fit-content'}} 
                                  onClick={() => submit_multiple_subform_atonce(message['data'],message['id'],message_index)} title="Submit" > 
                                  Submit All
                                </Button>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}</>
                )
              }
            </Grid>
          </div>
        ) : null}
        <Popover
          open={visble_datepick}
          anchorEl={anchorEl}
          onClose={handleDatePickClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Calendar onChange={(event) => OnDatePick(event)} value={datepickvar} />
        </Popover>
        {visble_col_filtr ? (<Popover
          open={visble_col_filtr}
          anchorEl={anchorEl}
          onClose={closeMasterColFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        > <div style={{width:'350px',padding:'10px'}}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                {(message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx].selected_filters.length > 0) ? (
                  <Paper sx={{padding:'10px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',margin:'10px'}}>
                      {message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx].selected_filters.map((each_sel_filt,scfindx) => {
                        return (
                          <Chip
                            key={scfindx}
                            label={each_sel_filt.val}
                            onDelete={() => handleMasterFilterDelete(each_sel_filt.val)}
                          />
                        );
                      })}
                    </Paper>
                ) : null}
                  
                <Autocomplete
                  options={message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx].filters}
                  getOptionLabel={(option) => (option.val  + '(' + option.count + ')')}
                  getoptionselected={(option, value) => option === value}
                  onChange={(e,newInput) => handleFilterOptionSelect(e,newInput)}
                  renderInput={(params) => (
                    <TextField
                    {...params} 
                    onChange={(e) => on_master_cf_stat_refresh(e,MasterFilterCfIndx,message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx])}
                      />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() =>OnResetMultiSubFilters(message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx],MasterFilterCfIndx)}>
                  Reset
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() =>OnApplyMultiSubFilters(message['data']['bulk_ques_resp_list']['master_cf_ordered_list'][MasterFilterCfIndx],MasterFilterCfIndx)}>
                    Apply
                </Button>
              </Grid>
            </Grid>
          </div>
        </Popover>) : null}
        <Dialog open={visibleFileUpload} onClose={() => cancelfileupload()} fullWidth maxWidth="sm">
          <DialogTitle>File Upload</DialogTitle>
          <DialogContent>
            <DialogContentText>Upload a file or view uploaded files.</DialogContentText>
            <InputBase type="file" onChange={handleUplloadedFileChange} />
            <List>
              {temp_Upld_fileList.map((file, index) => (
                <ListItem key={index}>
                  <ListItemText primary={index + 1 + ') '} />
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUploadedFile(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelfileupload} color="primary">
              Cancel & Close
            </Button>
            <Button onClick={confirmFileUpload} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {imgGalVisible ? (
          <Dialog open={imgGalVisible} onClose={() => closeImgGal()} PaperProps={{ style: { backgroundColor:'transparent' } }} maxWidth="xl">
            <DialogContent>
              <div className='imgGalCont' >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={0.5}>
                  <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                    <IconButton onClick={()=>onImgSlide('prev')} style={{ color: 'black',backgroundColor:'white',width:'40px',height:'40px' }} disabled={currImgIndx === 0}>
                      <ChevronLeftIcon size='large' />
                    </IconButton>
                  </div>
                </Grid>
                <Grid xs={11}>
                  <Card sx={{ display: 'flex', flexDirection: 'column',maxWidth: 350, margin: 'auto' }}>
                    <CardMedia
                      component="img"
                      sx={{ height: 450 }}
                      alt={`Image ${currImgIndx + 1}`}
                      src={`${imageObject[currImgIndx]['image']}`}
                      style={{ objectFit: 'contain' }}
                    />
                    <CardActions>
                      <Button onClick={() => imgGalDownload(imageObject[currImgIndx]['download'])} size="small">{imageObject[currImgIndx]['title']}</Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid xs={0.5}>
                  <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                    <IconButton onClick={()=>onImgSlide('next')} style={{ color: 'black',backgroundColor:'white',width:'40px',height:'40px' }} disabled={currImgIndx === imageObject.length - 1}>
                      <ChevronRightIcon size='large' />
                    </IconButton>
                  </div>
                </Grid>
                </Grid>
              </div>
            </DialogContent>
        </Dialog>
        ) : null}
        <Dialog open={BulkExImp} onClose={() => closeBulkExpImp()} fullWidth maxWidth="sm">
          {/* <DialogTitle>Bulk Export Import</DialogTitle> */}
          <DialogContent>
            <Grid container>
              <Grid item sx={8}>
                <div style={{border:'1px dotted #00AAFF',borderRadius:'15px',padding:'10px'}}> 
                  <h4>Bulk Response Upload</h4>
                  <Grid container >
                    <Grid item sx={6}>
                      <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                        backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                            color: 'black'
                            }}} onClick={() => exportFromRespTemplate()} title="Download Template" >
                        <SimCardDownloadIcon /> Template
                      </Button>
                    </Grid>
                    <Grid item sx={6}>
                      <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                        backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                            color: 'black'
                            }}} onClick={() => showFileUpload(message_index,null,null,null,null,'bulk_form_resp_upload')} title="Upload file" >
                              <UploadFileIcon />  Upload
                      </Button>
                      <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                        backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                            color: 'black'
                            }}} onClick={() => openFileStatusModal()} title="Uploaded file status" >
                              <InfoIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item sx={4} >
                <div style={{border:'1px dotted #00AAFF',borderRadius:'15px',padding:'10px'}}> 
                  <h4>Export Master Excel</h4>
                  <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                    backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                    color: 'black'
                    }}} onClick={() => exportMaster()} title="Export Master Fields as Excel" >
                    Master Export
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeBulkExpImp} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog 
         fullWidth
         maxWidth="xl" // You can adjust the maxWidth to your preference
         open={fileStatusModal} 
         onClose={() => closeBulkImpFileStatus()} >
          <DialogTitle>Bulk Import File Status</DialogTitle>
            <DialogContent>
              <div style={{textAlign:'right'}}>
                <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                  backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                  color: 'black'
                  }}} onClick={() => refreshBulkFileUpldStatus()} title="Refresh Status"
                >
                  <RefreshIcon />
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table  >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">File Name</TableCell>
                      <TableCell align="right">Uploaded Datetime</TableCell>
                      <TableCell align="right">Event Validation Status</TableCell>
                      <TableCell align="right">Event Completion Status</TableCell>
                      <TableCell align="right">Progress</TableCell>
                      <TableCell align="right">Primary & Sub Forms Submitted</TableCell>
                      <TableCell align="right">Total Actions</TableCell>
                      <TableCell align="right">Actual Actions</TableCell>
                      <TableCell align="right">Failed Actions</TableCell>
                      <TableCell align="right">Export Uploaded File</TableCell>
                      <TableCell align="right">Export With Status</TableCell>
                      <TableCell align="right">Export Failed Rows</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {File_status_list.map((data,inx) => (
                      <TableRow
                        key={inx}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell >{inx+1}</TableCell>
                        <TableCell >{data.file_name}</TableCell>
                        <TableCell >{data.timestamp}</TableCell>
                        <TableCell>
                          {(data.validation_status)?(<div className='succBadge'></div>):null}
                          {(!data.validation_status)?(
                          <div >
                              <CircularProgress />
                              {(data.total_forms_submitted)?(<Badge color="secondary" badgeContent={data.total_forms_submitted} /> ):null}
                          </div>
                          ):null}
                        </TableCell>
                        <TableCell>
                          {(data.validation_status)?(
                            <>
                              {(data.completed_status)?( <Chip label="completed" color="success" />):null}
                              {(!data.completed_status)?( <Chip label="In progress" color="warning" />):null}
                            </>
                          ):(
                            <Chip label="In progress" color="warning" />
                          )}
                        </TableCell>
                        <TableCell>
                          {(data.validation_status)?(
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                              <CircularProgress variant="determinate" />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: 'absolute',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography variant="caption" component="div" color="text.secondary">
                                  {data.completion_percentage}
                                </Typography>
                              </Box>
                            </Box>
                            ):(
                              <Chip label="In progress" color="warning" />
                            )}
                        </TableCell>
                        <TableCell>
                          {(data.validation_status)?(
                            <Chip label={data.total_forms_submitted} color="success" />
                            ):(
                              <Chip label="In progress" color="warning" />
                            )}
                        </TableCell>
                        <TableCell>
                          {(data.validation_status)?(
                            <Chip label={data.total_expected_actions} color="success" />
                          ):(
                            <Chip label="In progress" color="warning" />
                          )}
                        </TableCell>
                        <TableCell>
                          {(data.validation_status)?(
                            <Chip label={data.total_actual_actions} color="success" />
                          ):(
                            <Chip label="In progress" color="warning" />
                          )}
                        </TableCell>
                        <TableCell>
                          {(data.completed_status)?(
                            <Chip label={data.total_fail_actions} color="error" />
                          ):(
                            <Chip label="In progress" color="warning" />
                          )}
                        </TableCell>
                        <TableCell>
                          <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                          backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                              color: 'black'
                              }}} onClick={() => download_uploaded_file(data.uploaded_file_id)}
                              title="Uploaded file status" >
                                <FileDownloadOutlinedIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                          backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                              color: 'black'
                              }}} onClick={() => download_uploaded_file_with_status(currBulkUploadForm_formResp,data.instance_id,data.uploaded_file_id)}
                              disabled={(!data.completed_status)}
                              title="Uploaded file status" >
                                <FileDownloadOutlinedIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button sx={{backgroundColor: '#00AAFF',borderRadius:'25px',color: 'white',marginLeft: '10px',padding: '6px 15px','&:hover': {
                                          backgroundColor: '#E5F6FF', // Adjust the alpha value to control transparency
                              color: 'black'
                              }}} onClick={() => download_failed_rows(data.instance_id)} title="Uploaded file status"
                              disabled={((data.completed_status) && (data.total_fail_actions === 0))} >
                                <FileDownloadOutlinedIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          <DialogActions>
            <Button onClick={closeBulkImpFileStatus} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )}
  </div>
  )
};

ChatTemplate.propTypes = {};

ChatTemplate.defaultProps = {};

export default ChatTemplate;

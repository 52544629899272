import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import httpClient from '../../../../httpClient';
import LinearProgress from '@mui/material/LinearProgress'
import './embedView.css'
const EmbedView = (props) => {
  var [embed_link, setEmbedLink] = useState('');
  const [loading1, setLoading1] = useState(true);
  useEffect(() => {
    try{
      let payloadRL = {
        token: localStorage.getItem('vector_token')
      }
      httpClient.post('/getReportLink',payloadRL )
        .then(response => {
          console.log('Response: dream-dashboard/view embed', response.data,props.extLinkId);
            if(response.data){
              setLoading1(false);
              let obj = response.data.data.find(element => String(element.id) === String(props.extLinkId));
              console.log("obj :: ",obj)
              setEmbedLink(obj.link);
            }
        })
        .catch(error => {
          console.error('Error rl 1:', error);
          setLoading1(false); // Ensure loading state is updated in case of an error
        });
    } catch (error) {
      console.error('Error rl 2:', error);
      setLoading1(false); // Ensure loading state is updated in case of an error
      // Handle network or other errors
    }
  }, []
  );
  

  return  (
    <div>
      <div >
        {!loading1 ? (
          <iframe 
          className='wrap-element'
          src={embed_link} ></iframe>
        ) : (
          <LinearProgress />
        )}
      </div>
    </div>
    )
  };

EmbedView.propTypes = {};

EmbedView.defaultProps = {};

export default EmbedView;

import './App.css';
import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import CustomThemeProvider from './ThemeProvider';
import Layout from './components/Layout/Layout';
import Cookies from 'js-cookie';

const isAuthenticated = () => {
  // Replace this with your actual authentication logic
  // For example, check if the user is logged in based on a token or session
  if(Cookies.get('remember_status')=='true'){
    let storedToken = localStorage.getItem('vector_token');
  console.log("\n check is authendicated",storedToken)
  if(storedToken !== undefined && storedToken !== null && storedToken !== 'blocked'){
    return true; // Return true if authenticated, false otherwise
  }else{
    return false; // Return true if authenticated, false otherwise
  }
  }
  else{
    return false
  }
  
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" replace />;
};

const isLoggedIn = () => {
  return isAuthenticated();
};


function App() {
  return (
    <div className="App">
      <CustomThemeProvider>
        <Router>
          
            <Routes>
              {/* <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<PrivateRoute element={<Layout />} />} /> */}
              <Route
                path="/"
                element={isLoggedIn() ? <Navigate to="/layout/UsecaseList" /> : <LoginPage />}
              />
              <Route path="/layout/*" element={<Layout />} />
              <Route path="/login" element={<LoginPage />} />
            </Routes> 
          
        </Router>
      </CustomThemeProvider>
    </div>
  );
}

export default App;
import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import iconcrp_img from '../../../assets/Iconcrop.png'
import notifi_img from '../../../assets/notifications.png'
import image_img from '../../../assets/Image-48.png'
import notify_bg_img from '../../../assets/notification_bg.png'
import './DashboardList.css'
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../../httpClient';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { blue } from '@mui/material/colors';

const DashboardList = () => {
  let containerVisible = false;
  let dashboard_hover_items = false;
  // let dashboard_text_fill =  undefined;
  // let dashboard_hover_items = false;
  // let showFullContent = false;
  let fresh_pop = false;
  // let dashboard_list = [];
  const [dashboard_list, setItems_dashboard_list] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [searchKey,setsearchKey] = useState("");
  // let searchKey = "";
  function refresh_dream_dash_list(){
    try {
      const data = {
        token: localStorage.getItem('vector_token'),
        search_key:searchKey,
        page_no:1,
        row_per_page:100
      }
      httpClient.post('/dream-dashboard/view',data )
        .then(response => {
          console.log('Responsesss:', response.data);
          let res = response.data
          if (res.status) {
            setItems_dashboard_list(res.data);
            console.log("dream dash list :: ",dashboard_list);
          } else {
            console.log(res)
          }
          console.log("set oading off")
          setLoading(false);
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
        });
       
    } catch (error) {
      setLoading(false);
      // Handle network or other errors
    }
  }
  useEffect(() => {
    refresh_dream_dash_list();
  }, []
  ); // Empty dependency array to ensure the effect runs only once on mount

   const titleCase = (input) => {
    return input.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  };

  const getFirstThreeLetterDashboardName = (statement) => {
    const words = statement.split(' ');
    const firstThreeWords = words.slice(0, 3);
    const firstLetters = firstThreeWords.map(word => word[0]);
    if (statement.length > 75) {
      const dashboard_text = statement.slice(0, 75) + '...';
      return [firstLetters.join(''),dashboard_text]
      } else {
        const dashboard_text = statement;
        return [firstLetters.join(''),dashboard_text]
      }
  };

  const getNextColor = (() => {
    const colorList = ["#4BB649", "#63DEF6", "#667FFF", "#66B3FF", "#8EE35B", "#B366FF", "#CA4242", "#FF6666", "#FF66B3", "#FF9966", "#FFC266", "#FFDD66"];
    let currentIndex = 0;
  
    return () => {
      const currentColor = colorList[currentIndex];
      currentIndex = (currentIndex + 1) % colorList.length; // Increment and wrap around
      return currentColor;
    };
  })();
  
  const navigate = useNavigate();
  const goToDashboard = (id) =>{
    console.log("Dashboard call :: ",String(id))
    navigate(`/layout/Dashboard?id=${id}`);
   }

  const range = 7; // Set the desired range

  // Using map to create elements based on the range
  const skeletonLoad = Array.from({ length: range }, (_, index) => (
    <Grid key={index} item xs={2} sm={4} md={4}>
      <Skeleton variant="rounded" width='85%' height={85} />
    </Grid>
  ));

  const handleSearchChange = (event) => {
    setsearchKey(event.target.value);
  };

  const enterKeyPress = (event,funct_trigg) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      // Handle the Enter key press
      // console.log('Enter key pressed',funct_trigg == 'master_search',subform_search_key);
      // Add your logic here

      if(funct_trigg == 'dream_dash_search'){
        refresh_dream_dash_list();
      }
    }
  };

  return (
  <div className='alingItem'>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 13 }}>
      <Grid item xs={10}>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 13 }}>
          <Grid item xs={3}>
            <div className='dashBoardHeader'>
              My Dashboard
            </div>
          </Grid>
          <Grid item xs={9}>
            <div style={{float: 'left'}}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: '240px',borderRadius: '184px',height:'25px', padding: '4px 6px 4px 12px',backgroundColor: '#E5F6FF' }}
              >
                <InputBase
                  sx={{ ml: 0, flex: 1,pl: 0,fontSize:'12px' }}
                  placeholder="Search Dream Dashboard"
                  value={searchKey}
                  onChange={handleSearchChange}
                  onKeyDown={(e) => enterKeyPress(e,'dream_dash_search')}
                />
                <IconButton type="button" sx={{ p: '2px',width: '40px',height: '40px', borderRadius: '50%' }} aria-label="search" onClick={() => refresh_dream_dash_list()}>
                  <SearchIcon sx={{ width: '20px',height: '20px' }} />
                </IconButton>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
      </Grid>
    </Grid>
    <div style={{marginTop: '20px'}}>
      {loading ? (
        <>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {skeletonLoad}
          </Grid>
        </>
        
      ) : (
        <div>
          <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 6, sm: 8, md: 13 }}>
            {dashboard_list.map((each_dash, i) => {
              const randColor = getNextColor();
              const [threeLetters,dashboardText] = getFirstThreeLetterDashboardName(titleCase(each_dash.page_name));
              
              // console.log("result three letters and dashboardText ::",threeLetters,dashboardText)
              return(
                <Grid item xs={2} sm={4} md={4} key={i + 'grid'}>
                  {/* <button key={i + 'but'} className="each-dashboard" onClick={() => goToDashboard(each_dash.page_id)}> */}
                    {/* <div className="dashboar-text"><p>{dashboardText}</p></div>
                    <div className="each-dashboard-color" style={{backgroundColor: randColor}} ><div className="dashboard-color-text"><b>{threeLetters}</b></div>
                    </div> */}

                  
                    <Card sx={{ borderRadius: '8px', display: 'flex', overflow: 'hidden',height: '64px',width: '75%','&:hover': {cursor: 'pointer'} }} onClick={() => goToDashboard(each_dash.page_id)}>
                      {/* Left partition (20%) with background color */}
                      <div
                        style={{
                          flex: '20%',
                          backgroundColor: randColor, // Set your desired background color
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {/* Content in the left partition */}
                        <CardContent
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center', // Center text horizontally
                            lineHeight: '1.5',
                          }}
                        >
                          <Typography  color="white">
                          <b>{threeLetters}</b>
                          </Typography>
                        </CardContent>
                      </div>

                      {/* Right partition (80%) */}
                      <div style={{ flex: '70%', alignContent:'center' }} className="resp_outer_vertical_dashboard">
                        {/* Content in the right partition */}
                        <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                          <Typography variant="body1">
                            {dashboardText}
                          </Typography>    
                        </CardContent>
                      </div>
                    </Card>
                  {/* </button> */}
                </Grid>  
              )
              })}
          </Grid>
        </div>
      )}
    </div>
    
  </div>
  );
}

DashboardList.propTypes = {};

DashboardList.defaultProps = {};

export default DashboardList;

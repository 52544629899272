import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import httpClient from '../../../httpClient'
import { useParams,useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinearProgress from '@mui/material/LinearProgress'
import EmbedView from './embedView/embedView';
import ChatTemplate from './chatTemplate/chatTemplate'
import './OpenDashboard.css'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const viewFullScreen = () => {
  console.log("called full screen in open dash")
  const element = document.getElementById('fullscreen-div');
  // Change the background color to white
  element.style.backgroundColor = 'white';
  element.style.padding = '20px';
  toggleFullscreen();
};

function closeFullScreen(noExit = null){
  console.log("\n\closeFullScreen")
  const element = document.getElementById('fullscreen-div');
  // Change the background color to white
  element.style.backgroundColor = '';
  element.style.padding = '0px';
  if(noExit==null){
    toggleFullscreen();
  }
};

function toggleFullscreen() {
  var fullscreenDiv = document.getElementById('fullscreen-div');

  if (!document.fullscreenElement) {
    fullscreenDiv.requestFullscreen().catch(err => {
      console.error('Failed to enter fullscreen mode:', err);
    });
  } else {
    document.exitFullscreen();
  }
}

document.addEventListener('fullscreenchange', (event) => {
  console.log("\n\neacapeee")
  if (document.fullscreenElement === null) {
    // The document is no longer in fullscreen mode
    console.log('\n\nExited fullscreen mode');
    closeFullScreen(true);
    // You can perform additional actions or trigger functions here
  }
});

const OpenDashboard = () => {  
  // Access the 'id' parameter from the route
  // const { curr_page_id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const curr_page_id= queryParams.get('id');
  let [page_data, setPageData] = useState({});
  console.log("check curr_page_id :: ",curr_page_id)
  const [loading1, setLoading1] = useState(true);

  let add_additional_keys = (data) =>{
    let out_data = { ...data };
    for (let i = 0; i < out_data.sections.length; i++) {
      for (let j = 0; j < out_data.sections[i].widgets.length; j++) {
        // Create a new object for each widget to avoid modifying the original object
        out_data.sections[i].widgets[j] = {
          ...out_data.sections[i].widgets[j],
          active: false,
        };
      }
    }
    setLoading1(false);
    return out_data;
  };

  
  
  const GetPageDataDreamDashPage = (page_id) => {
    console.log("on call GetPageDataDreamDashPage")
    useEffect(() => {
      try{
        let payloadGPD = {
          token: localStorage.getItem('vector_token'),
          page_id:page_id
        }
        httpClient.post('/dream-dashboard/get-page-data',payloadGPD )
          .then(response => {
            console.log('Response dream-dashboard/get-page-data :', response.data);
            let res = response.data
            if (res.data === false) {
              console.log("\n\n Error \n\n")
            } else {
              page_data = setPageData(add_additional_keys(res.data))
              console.log("\n\n\npage_data :: ",page_data)
            }
          })
          .catch(error => {
            console.error('Error 1:', error);
          });
      } catch (error) {
        console.error('Error 2:', error);
        // Handle network or other errors
      }
      // Cleanup function
      return () => {
        console.log('Component will unmount.');
      };
    }, []
    );
  };
    

  GetPageDataDreamDashPage(curr_page_id);

  const [notiState, setNotiState] = React.useState({
    notiMessage : 'Sample Message',
    noti_open: false,
    noti_vertical: 'top',
    noti_horizontal: 'right',
    notiSeverity: 'success'
  });
  const { noti_vertical, noti_horizontal, noti_open,notiMessage, notiSeverity } = notiState;

  function ShowNotification(newState){
    // console.log("\n\n\n\nClose notification :: ")
    setNotiState({ ...newState, noti_open: true });
  };

  function CloseNotification(){
    setNotiState({ ...notiState, noti_open: false });
  };

  const triggerNotification = (MsgType,Msg) => {
    // console.log("triggerNotification :: ",MsgType,Msg)
    ShowNotification({ noti_vertical: 'top', noti_horizontal: 'right', notiMessage: Msg,notiSeverity : MsgType });
  }
  

  function CloseWidget(section_index,comp_type,comp_indx){
    console.log("\n\nclose executed");
    // ShowNotification({ noti_vertical: 'top', noti_horizontal: 'right', notiMessage: 'Successfully submited',notiSeverity : 'success' });
    setPageData((page_data) => {
      // Create a shallow copy of the previous state
      const newPageData = { ...page_data };
  
      // false the 'active' property for the specified widget
      newPageData.sections[section_index][comp_type][comp_indx].active = false;
  
      // Return the updated state
      return newPageData
    });
  }
  const enable_widget = (sect_indx,widg_indx) => {
    console.log("on widget open :: ",page_data,sect_indx,widg_indx)
    disable_remainig_widget(sect_indx,widg_indx)
    // page_data.sections[sect_indx].widgets[widg_indx].active = !page_data.sections[sect_indx].widgets[widg_indx].active;
    // console.log("after open :: ",page_data.sections[sect_indx].widgets[widg_indx].active)

    setPageData((page_data) => {
      // Create a shallow copy of the previous state
      const newPageData = { ...page_data };
  
      // Toggle the 'active' property for the specified widget
      newPageData.sections[sect_indx].widgets[widg_indx].active = !newPageData.sections[sect_indx].widgets[widg_indx].active;
  
      // Return the updated state
      return newPageData;
    });
  };

  const enable_view = (sect_indx,view_indx) => {
    console.log("on view open :: ",page_data,sect_indx,view_indx)
    // disable_remainig_view(sect_indx,view_indx)
    page_data.sections[sect_indx].views[view_indx].active = !page_data.sections[sect_indx].views[view_indx].active;
  };

  const disable_remainig_widget = (sect_indx,except_widg_index) => {
    for(let j = 0;j < page_data.sections[sect_indx].widgets.length;j++){
      if(j !== except_widg_index){
        page_data.sections[sect_indx].widgets[j]['active'] = false;
      }
    }
  };

  const disable_remainig_view = (sect_indx,except_view_index) => {
    for(let j = 0;j < page_data.sections[sect_indx].views.length;j++){
      if(j !== except_view_index){
        page_data.sections[sect_indx].views[j]['active'] = false;
      }
    }
  }

  const toggle_section = (sect_indx) => {
    page_data.sections[sect_indx].active = !page_data.sections[sect_indx].active;
  }

  return (
    <div key={queryParams.get('id')}  className='outerMostContainer'>
      {/* to test notification */}
      {/* <Button onClick={() => ShowNotification({ noti_vertical: 'top', noti_horizontal: 'right', notiMessage: 'error captured',notiSeverity : 'error' })}>
        Top-Right
      </Button> */}
      <Snackbar
        anchorOrigin={{ vertical :noti_vertical, horizontal: noti_horizontal }}
        open={noti_open}
        autoHideDuration={3000}
        onClose={() => CloseNotification()}
        message={notiMessage}
        key={noti_vertical + noti_horizontal}
        style={{zIndex:2500}}
      >
        <Alert onClose={() => CloseNotification()} severity={notiSeverity} sx={{ width: '100%' }}>
          {notiMessage}
        </Alert>
      </Snackbar>
      {(!loading1) ? (
        page_data.sections.map((sect_data, sect_indx) => (
          <div key={sect_indx}>
            {
              sect_data.active ? (
                <div >
                  {/* <Divider>{sect_data.name}</Divider> */}
                  <div className='sectionName'>{sect_data.name}</div>
                  <div className='widgetContainer'>
                    <Grid container spacing={2} sx={{marginTop:'5px'}}>
                      {
                        sect_data.widgets.map((each_widget,widg_indx) => (
                          <Grid item  key={widg_indx}>
                          <Button key={widg_indx}
                          className={`Button ${each_widget.active ? 'selected' : ''}`}
                           sx={{'font-family': 'Montserrat','font-style': 'normal','font-weight': '600','font-size': '13px','line-height': '20px',display: 'flex','align-items': 'center','text-align': 'center',backgroundColor : 'white', color: 'black','border-radius': '200px',border: 'none !important',transition: '0.3s ease',color:'#4D4D4D',boxShadow: '0px 0px 4px 0px #00000040',padding:'3px 15px',width: 'fit-content','&:hover': {
                            backgroundColor: each_widget.active ? '#0077CC' :'#65CCFF', // Adjust the alpha value to control transparency
                            color: 'white'
                            } }} onClick={() => enable_widget(sect_indx,widg_indx)}>
                            { each_widget.type_val }
                            {each_widget.active ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                          </Button>
                          
                          </Grid>
                        ))
                      }
                    </Grid>
                  </div>
                  <div >
                  {  
                        sect_data.widgets.map((each_widget,widg_indx2) => (
                          each_widget.active ? (
                            <div key={widg_indx2} className='widgetContainer'>
                                {each_widget.type  === 'form' ? (
                                  <div id="fullscreen-div" >
                                      {/* forms {widg_indx2} */}
                                      <ChatTemplate trigger_form_data={each_widget} trigger_section_index={sect_indx} trigger_comp_type={'widgets'} trigger_comp_index={widg_indx2} CloseWidget={CloseWidget} triggerNotification={triggerNotification} viewFullScreen={viewFullScreen} closeFullScreen={closeFullScreen}/>
                                  </div>
                                ): null}
                                {each_widget.type  !== 'form' ? (
                                  <div >
                                      <EmbedView extLinkId={each_widget.type_id} trigger_section_index={sect_indx} trigger_comp_type={'widgets'} trigger_comp_index={widg_indx2} />
                                  </div>
                                ): null}
                            </div>
                          ):null
                        ))
                      }
                  </div>          
                  
                    {
                      sect_data.views.map((each_view,view_indx) => (
                        <div className='ViewContainer' key={view_indx}>
                          { each_view.active ? (
                          <div >
                              { each_view.type === 'form' ? (
                              <div >
                                  {/* <div style={{textAlign: 'right'}}>
                                  </div> */}
                                  <div id="fullscreen-div">
                                      {/* view forms {view_indx} */}
                                      <ChatTemplate trigger_form_data={each_view} trigger_section_index={sect_indx} trigger_comp_type={'views'} trigger_comp_index={view_indx} CloseWidget={CloseWidget} triggerNotification={triggerNotification} viewFullScreen={viewFullScreen} closeFullScreen={closeFullScreen} />
                                  </div>
                              </div>
                              ):null}
                              { each_view.type !== 'form' ? (
                              <div >
                                  <div>
                                      <EmbedView extLinkId={each_view.type_id} trigger_section_index={sect_indx} trigger_comp_type={'views'} trigger_comp_index={view_indx}/>
                                  </div>
                              </div>
                              ): null}
                          </div>
                          ) : (
                          <div style={{textAlign : 'center'}}>
                              {each_view.type_val}
                          </div>
                          )}
                        </div>
                      ))
                    }
                  
                </div>
              ) : null 
            }
          </div>
        ))
      ):(
        <LinearProgress />
      )
      }

    </div>
  );
}

OpenDashboard.propTypes = {};

OpenDashboard.defaultProps = {};

export default OpenDashboard;

import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../httpClient';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { blue } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { testhttpClient } from '../../httpClient';
import { Tooltip } from '@mui/material';
import  { useRef } from 'react';
const UsecaseList = () => {
  const [usecase_dashboard_list,setusecase_dashboard_list] = useState([]);
  const [full_dashboard_list,set_full_dashboard_list] = useState([])
  const [disp_full_list,set_disp_full_list] = useState(false)
  const [disp_usecase_name,set_disp_usecase_name] = useState('')
  const [hide_usecase_index,set_hide_usecase_index] = useState(null)
  const targetDivRef = useRef(null);
  function get_usecase_dashboard_list(){
    const data = {
      token: localStorage.getItem('vector_token'),
    }
    testhttpClient.post('/get_usecase_dashboard_map_list',data).then(response=>{
      if(response.data.status == true){
        console.log('usecase data :: ',response.data.data)
        setusecase_dashboard_list(response.data.data)
        console.log('usecase list ',usecase_dashboard_list)
      }
      else{
        console.log('failed response :: ',response)
      }
    })
    
    // const usecase_data = [
    //   {
    //     'usecase_name':"grievance management",
    //     'usecase_id' : 12,
    //     'dashboard_list':[
    //       {
    //         "dd_id": 1,
    //         "dd_name": "new page frank test",
    //         "user_id": 2177
    //       },
    //       {
    //       "dd_id": 7,
    //       "dd_name": "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups",
    //       "user_id": 2177
    //       },
    //       {
    //       "dd_id": 8,
    //       "dd_name": "Dream",
    //       "user_id": 2177
    //       },
    //       {
    //         "dd_id": 816,
    //         "dd_name": "frank react test",
    //         "user_id": 2176
    //       },
    //     {
    //         "dd_id": 960,
    //         "dd_name": "React Testing",
    //         "user_id": 2177
    //     }    
    //  ]
    //   },
    //   {
    //     'usecase_name':'Daily Progress Report',
    //     'usecase_id': '13',
    //     'dashboard_list' : [
    //     {
    //         "dd_id": 295,
    //         "dd_name": "new dream dashboard",
    //         "user_id": 2177
    //     },
    //     {
    //         "dd_id": 321,
    //         "dd_name": "test page multi sub",
    //         "user_id": 2177
    //     }
    //   ]
    //   },
    //   {
    //   'usecase_name':'Others',
    //   'usecase_id': -1,
    //   'dashboard_list' : [
    //   {
    //       "dd_id": 364,
    //       "dd_name": "Users",
    //       "user_id": 2177
    //   },
    //   {
    //       "dd_id": 772,
    //       "dd_name": "User Dashboard",
    //       "user_id": 2177
    //   }
    //   ]
    //   }
    // ]

    // setusecase_dashboard_list(usecase_data)
    console.log('usecase list :: ',usecase_dashboard_list)
  }

  useEffect(() => {
    get_usecase_dashboard_list();
  }, []
  ); 

  const titleCase = (input) => {
    return input.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  };


const getFirstThreeLetterDashboardName = (statement) => {
    const words = statement.split(' ');
    const firstThreeWords = words.slice(0, 3);
    const firstLetters = firstThreeWords.map(word => word[0]);
    if (statement.length > 30) {
      const dashboard_text = statement.slice(0, 30) + '...';
      return [firstLetters.join(''),dashboard_text]
      } else {
        const dashboard_text = statement;
        return [firstLetters.join(''),dashboard_text]
      }
  };

  const getFirstThreeLetterUsecaseName = (statement) =>{
    const words = statement.split(' ');
    const firstThreeWords = words.slice(0, 3);
    const firstLetters = firstThreeWords.map(word => word[0]);
    if (statement.length > 30) {
      const dashboard_text = statement.slice(0, 30) + '...';
      return [firstLetters.join(''),dashboard_text]
      } else {
        const dashboard_text = statement;
        return [firstLetters.join(''),dashboard_text]
      }
  }


  const getNextUsecaseColour = (() => {
    const colourList =["#F0FFFF","#FFFAF0","#DCDCDC","#FFFFF0","#E0FFFF","#B0C4DE"]
    let currentIndex = 0;
  
    return () => {
      const currentColour = colourList[currentIndex];
      currentIndex = (currentIndex + 1) % colourList.length; // Increment and wrap around
      return currentColour;
    };
  })();

  const getNextColor = (() => {
    const colorList = ["#4BB649", "#63DEF6", "#667FFF", "#66B3FF", "#8EE35B", "#B366FF", "#CA4242", "#FF6666", "#FF66B3", "#FF9966", "#FFC266", "#FFDD66"];
    let currentIndex = 0;
  
    return () => {
      const currentColor = colorList[currentIndex];
      currentIndex = (currentIndex + 1) % colorList.length; // Increment and wrap around
      return currentColor;
    };
  })();

  const navigate = useNavigate();
  const goToDashboard = (id) =>{
    console.log("Dashboard call :: ",String(id))
    navigate(`/layout/Dashboard?id=${id}`);
   }

   const disp_usecase = (usecase) =>{
    console.log('usecase is :: ',usecase)
    set_full_dashboard_list(usecase.dashboard_list)
    // set_full_dashboard_list(usecase.dashboard_list)
    console.log('full display list :: ',full_dashboard_list)
    const dash_list = usecase.dashboard_list
    console.log('other dash list :: ',dash_list)
    set_disp_usecase_name(usecase.usecase_name)
    set_disp_full_list(true)
    for(let i = 0;i<usecase_dashboard_list.length;i++){
      if(usecase_dashboard_list[i].usecase_id == usecase.usecase_id){
        set_hide_usecase_index(i)
      }
    }
    // window.scrollTo({top:0,behavior:'smooth'});
    targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
   }
  
   const hide_usecase_disp = () =>{
    set_disp_full_list(false)
    set_hide_usecase_index(null)
    console.log('disp status :: ',disp_full_list)
   }

   

   

return(
  <div className='alingItem' ref={targetDivRef}>
    <Grid item xs={3}>
      <div className='dashBoardHeader'>
        My Dashboard
      </div>
      <br/><br/>
    </Grid>
    {disp_full_list == true&&(
      <div  >
        <Card sx={{ borderRadius: '8px', display: 'flex', overflow: 'hidden', height: '564px', width: '100%', '&:hover': { cursor: 'pointer' } }}>
          <CardContent>
              <Fab style={{backgroundColor:'white'}}  onClick={() => hide_usecase_disp()}>
                    <KeyboardBackspaceIcon />
              </Fab>
              <Typography sx={{ fontSize: 23 }} color="text.primary" gutterBottom>
                  {disp_usecase_name}
              </Typography>            
            <div>
            <Grid container spacing={2}>
              {full_dashboard_list.map((each_dash,dash_index)=>{
                const randColor = getNextColor();
                const [threeLetters,dashboardText] = getFirstThreeLetterDashboardName(titleCase(each_dash.dd_name));
                return(
                  <Grid item xs={2} sm={4} md={4} key={dash_index + 'grid'}>
                    <Card sx={{ borderRadius: '8px', display: 'flex', overflow: 'hidden',height: '66px',width: '76%','&:hover': {cursor: 'pointer'} }} onClick={() => goToDashboard(each_dash.dd_id)}>
                      <div
                      style={{
                        flex: '20%',
                        backgroundColor: randColor, // Set your desired background color
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center', // Center text horizontally
                            lineHeight: '1.5',
                          }}
                        >
                          <Typography color="white">
                          <b>{threeLetters}</b>
                          </Typography>
                        </CardContent>
                      </div>
                      <div style={{ flex: '70%' }}>
                        {/* Content in the right partition */}
                        <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <Tooltip title = {each_dash.dd_name}>
                            <Typography variant="body1">
                              {dashboardText}
                            </Typography>
                          </Tooltip>
                        </CardContent>
                      </div>                      
                      </Card> 
                 </Grid>    
                )
              })}
               </Grid>
            </div>
          </CardContent>
        </Card>
        <br/>
      </div>
    )}
  <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 8, md: 13 }}>
    {usecase_dashboard_list.map((each_usecase, index) => {
      const randColour = getNextUsecaseColour();
      const [threeLetters,usecaseText] = getFirstThreeLetterDashboardName(titleCase(each_usecase.usecase_name));
      if(index != hide_usecase_index){
        return (
          <Grid item xs={2} sm={4} md={4} key={index + 'grid'}>
            <Card sx={{ borderRadius: '8px', display: 'flex', overflow: 'hidden', height: '387px', width: '100%', '&:hover': { cursor: 'pointer' } }}>
              <div
                style={{
                  flex: '20%',
                  backgroundColor: randColour,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CardContent>
                  <div style={{height:'10%'}}>
                    <Tooltip title={each_usecase.usecase_name}>
                      <Typography sx={{ fontSize: 19 }} color="text.primary" gutterBottom height={'50px'}>
                        {usecaseText}
                      </Typography>
                    </Tooltip>
                  </div>
                  <div style={{height:'80%'}}>
                    <Grid container spacing={{ xs: 1 }}>
                      {each_usecase.dashboard_list.map((each_dd, dd_index) => { 
                        const randColor = getNextColor();
                        const [threeLetters,dashboardText] = getFirstThreeLetterDashboardName(titleCase(each_dd.dd_name));
                        if (dd_index <4){return (
                          <Grid item xs = {12}>
                            <Card sx={{ borderRadius: '8px', display: 'flex', overflow: 'hidden',height: '65px',width: '95%','&:hover': {cursor: 'pointer'}, }} onClick={() => goToDashboard(each_dd.dd_id)}>
                              <div
                              style={{
                                flex: '20%',
                                backgroundColor: randColor, // Set your desired background color
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                                <CardContent
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center', // Center text horizontally
                                    lineHeight: '1.5',
                                  }}
                                >
                                  <Typography color="white">
                                  <b>{threeLetters}</b>
                                  </Typography>
                                </CardContent>
                              </div>
                              <div style={{ flex: '70%' }} >
                                {/* Content in the right partition */}
                                <CardContent
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                  <Tooltip title={each_dd.dd_name}>
                                    <Typography variant="body1">
                                      {dashboardText}
                                    </Typography>
                                  </Tooltip>
                                </CardContent>
                              </div>                      
                            </Card> 
                          </Grid> 
                        )};
                        return null;
                      
                      })}
                    </Grid>
                    
                  </div>
                  <div style={{height:'10%'}}>
                  {each_usecase.dashboard_list.length > 4 && (
                          <Typography onClick={() => disp_usecase(each_usecase)} color="blue" >
                            See all ({each_usecase.dashboard_list.length})
                          </Typography>
                    )}                
                    </div>
                </CardContent>
              </div>
            </Card>
          </Grid>
        );
      }
    })}
  </Grid>
</div>
  )
}

UsecaseList.propTypes = {};

UsecaseList.defaultProps = {};

export default UsecaseList;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Layout.css';
import logo from '../../assets/Full Logo Verticle.svg';
import home from '../../assets/shape.svg';
import dashboard from '../../assets/dashboard.svg';
import chat from '../../assets/chat.svg';
import help from '../../assets/help.svg';
import Logout from '../../assets/logout.svg';
import revert from '../../assets/revert.svg';
import notification from '../../assets/notifications.svg';
import { HashRouter as Router, Route, Routes,Outlet,useNavigate } from 'react-router-dom';
import DashboardList from '../dashboard/DashboardList/DashboardList';
import Popover from '@mui/material/Popover';
import OpenDashboard from '../dashboard/OpenDashboard/OpenDashboard';
import Avatar from '@mui/material/Avatar';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import NotificationsIcon from '@mui/icons-material/Notifications';
import httpClient from '../../httpClient';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Collapse  from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Chip from '@mui/material/Chip';
import Cookies from 'js-cookie';
import UsecaseList from '../usecase_list/usecase_list';
import Tooltip from '@mui/material/Tooltip';



const Layout = () => {
  let dashboard_hover_items = false;
  let [visbleNotification,setvisbleNotification] = useState(false);
  let [notifications,setnotifications] = useState([]);
  let [notifiLoader,setnotifiLoader] = useState(false);
  const [itemsDashboardList, setItemsDashboardList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey,setsearchKey] = useState("");
  const [showDashboardTooltip, setShowDashboardTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUserTooltip,setShowUserTooltip] = useState(false)
  const [currentUser,setCurrentUser] = useState(null)
  const [currentOrg,setCurrentOrg]  = useState(null)
  const [currentUserID,setcurrentUserID]  = useState(null)
  const [clickedIcon, setClickedIcon] = useState(null);

  const handleIconClick = (iconName) => {
    setClickedIcon(iconName === clickedIcon ? null : iconName);
  };
  
  const openDashBoardTooltip = (event) => {
    setAnchorEl(event.currentTarget);
    setShowDashboardTooltip(true);
    setSelectedButton('dashboard');
  };

  const closeDashBoardTooltip = () => {
    setAnchorEl(null);
    setShowDashboardTooltip(false);
  };

  const disp_user_tooltip = (event) =>{
    setAnchorEl(event.currentTarget);
    setShowUserTooltip(true);
    setCurrentUser(localStorage.getItem('v_user_name'))
    setCurrentOrg(localStorage.getItem('v_org_name'))
    setcurrentUserID(localStorage.getItem('vector_user_id'))
    console.log('hover works :: ')
  }

  const closeUserTooltip = () =>{
    setAnchorEl(null);
    setShowUserTooltip(false)
  }

  function refreshList() {
    try {
      const data = {
        token: localStorage.getItem('vector_token'),
        search_key: searchKey,
        page_no: 1,
        row_per_page: 100,
      };

      httpClient.post('/dream-dashboard/view', data)
        .then(response => {
          console.log('Response:', response.data);
          setSelectedButton('home');
          const res = response.data;

          if (res.status) {
            setItemsDashboardList(res.data);
            // console.log("dream dash :: ", itemsDashboardList);
          } else {
            console.log(res);
          }

          console.log("set loading off");
          setLoading(false);
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
        });

    } catch (error) {
      setLoading(false);
      // Handle network or other errors
    }
  }

  useEffect(() => {
    refreshList();
  }, []);

  
   const navigate = useNavigate();

   const logout = () => {
    localStorage.setItem('vector_token','blocked');
    Cookies.set('email','')
    Cookies.set('password','')
    navigate('/login');
   }

   const goToDashboardList = () =>{
    setSelectedButton('home');
    console.log("\n\n\ndashboard list call")
    navigate('/layout/DashboardList');
   }

   const goToDashboard = (id) => (event) => {
    console.log("\n\nDashboard call :: ",String(id))
    closeDashBoardTooltip();
    // Prevent the click event from propagating to the button
    event.stopPropagation();
    navigate(`/layout/Dashboard?id=${id}`);
   }

   const goToUsecaseList = () =>{
    navigate('/layout/UsecaseList');
   }

   const AiTest = 'AI';

   const freshuser=() =>{
    return true;
   }

   const toggleContainer=() =>{
    window.location.href = 'https://vector.cloobot.ai/#/dream-dashboard';
    return true;
   }

   const [selectedButton, setSelectedButton] = useState(null);

  //  useEffect(() => {
  //   if (visbleNotification) {
  //     const sampleNotification = {
  //       id: notifications.length + 1, // Assuming unique IDs
  //       text: "This is a sample notification message.",
  //       date: "1/2/2024, 11:18:13 AM"
  //     };
  //     setnotifications([...notifications, sampleNotification]);
  //   }
  // }, [visbleNotification, notifications]);
    
  function openNotification() {
    setvisbleNotification(true);
    getNotifications();
  }

   function closeNotification(){
    setvisbleNotification(false);
   }

   function getNotifications(){
    setnotifiLoader(true);
    try{
      const postdata = {
        token: localStorage.getItem('vector_token'),
        limit : 10
      }
      httpClient.post('/getNotificationMessages',postdata )
      .then(response => {
        setnotifiLoader(false);
        let resp = response.data;
        if(resp.status){
          setnotifications(resp.data);
        }
      })
      .catch(error => {
        console.error('getNotificationMessages Error 1:', error);
      });
    } catch (error) {
      console.error('getNotificationMessages Error 2:', error);
    }
   }

  const [expandedItem, setExpandedItem] = useState(null);

  const handleExpand = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  function markAllNotificationAsSeen(){
    let seen_mesg_id_list = [];
    for (let k = 0; k < notifications.length; k++){
      seen_mesg_id_list.push(notifications[k]["id"])
    }
    if(seen_mesg_id_list.length){
      try{
        const postdata = {
          token: localStorage.getItem('vector_token'),
          mesg_ids:seen_mesg_id_list
        }
        httpClient.post('/markNotificationAsSeen',postdata )
        .then(response => {
          setnotifiLoader(false);
          console.log("notification marked as seen")
          setnotifications([]);
        })
        .catch(error => {
          console.error('markNotificationAsSeen Error 1:', error);
        });
      } catch (error) {
        console.error('markNotificationAsSeen Error 2:', error);
      }

    }
  }

  function markAsReadParticualrMsg(id,indx){
    try{
      const postdata = {
        token: localStorage.getItem('vector_token'),
        mesg_ids:[id]
      }
      httpClient.post('/markNotificationAsSeen',postdata )
      .then(response => {
        setnotifiLoader(false);
        let tempnotifi = [...notifications];
        tempnotifi.splice(indx,1);
        setnotifications(tempnotifi);
        console.log("notification marked as seen");
      })
      .catch(error => {
        console.error('markNotificationAsSeen Error 11:', error);
      });
    } catch (error) {
      console.error('markNotificationAsSeen Error 22:', error);
    }
  }
  const getNextColor = (() => {
    const colorList = ["#4BB649", "#63DEF6", "#667FFF", "#66B3FF", "#8EE35B", "#B366FF", "#CA4242", "#FF6666", "#FF66B3", "#FF9966", "#FFC266", "#FFDD66"];
    let currentIndex = 0;
  
    return () => {
      const currentColor = colorList[currentIndex];
      currentIndex = (currentIndex + 1) % colorList.length; // Increment and wrap around
      return currentColor;
    };
  })();

  const selectButton = (buttonName) => {
    setSelectedButton(buttonName);
  }


  return (
    <div className="background-container">
      <div className="container1">
        <img id="menuleft-logo" src={logo} alt="avatar" />
        <div className='centerAlign'>
          <div className='centerElem'>
            <button className={`circular-button ${selectedButton === 'home' ? 'selected' : ''}`}
                onClick={() => {
                selectButton('home');
                goToUsecaseList();
              }} >
              <img id="home" src={home} alt="Home" />
              <div className="newTooltip">Home</div>
            </button>
          </div>
          <div className='centerElem'>
            <button className={`circular-button ${selectedButton === 'dashboard' ? 'selected' : ''}`}
              onMouseEnter={(e) => openDashBoardTooltip(e)}>
              <img id="dashboard" src={dashboard} alt="dashboard" />
              <div className="newTooltip">My Dashboard</div>
            </button>
            <Popover 
              id="popover"
              open={showDashboardTooltip}
              onClose={() => closeDashBoardTooltip()}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              disableRestoreFocus
            >
              <div className='arrow-left'>
                      {itemsDashboardList.map((item, index) => (
                        <div className="each-items" key={index} onClick={goToDashboard(item.page_id)}>
                          <div className='ddName'>
                            <Tooltip title={item.page_name} arrow><div style={{backgroundColor: getNextColor(),borderRadius: '50%',width: '20px',height: '20px',display: 'inline-block',verticalAlign: 'middle',}}>
                            </div>
                            </Tooltip>
                          </div>
                          <div className='ddName'>
                            {item?.page_name && item.page_name.length > 25 ? (
                              <Tooltip title={item.page_name} arrow>
                                {item.page_name.slice(0, 25) + '...'}
                              </Tooltip>
                            ) : (
                              item.page_name
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
            </Popover>
          </div>
        </div>

        
        <div className="downicons">
          <div className='centerElem'>
            <button className={`circular-button ${selectedButton === 'help' ? 'selected' : ''}`}
                onClick={() => {
                selectButton('help');
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSfMPGmWIMtjEEo1L0dgeOugZQ-_DbUUhfpm4FwpYhoY6VrE9Q/viewform');
              }} >
              <img id="help" src={help} alt="help" />
              <div className="newTooltip">help</div>
            </button>
          </div>
          
        
          <div className='centerElem'>
            <button className="circular-button"onClick={logout}>
              {/* <LogoutRoundedIcon sx={{ color: blue[500] }}></LogoutRoundedIcon> */}
              <img id="Logout" src={Logout} alt="Logout" />
              <div className="newTooltip">Logout</div>
            </button>
          </div>
        </div>
      </div>

      {/* container two */}
      <div className="container2">
        <div className='top-div'>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={3}>
            {/* <div> */}
              <div className="top-right-icons">
                <Avatar className={`circular-button ${selectedButton === 'revert' ? 'selected' : ''}`}
                  onClick={toggleContainer}
                  sx={{ width: 40, height: 40 , marginLeft: '12px', backgroundColor: '#E5F6FF', color: 'E5F6FF'}}
                >
                  <Tooltip title="Revert to old UI" arrow>
                    <img id="revert" src={revert} alt="revert" />
                  </Tooltip>
                </Avatar>
                <Avatar className={`circular-button ${selectedButton === 'revert' ? 'selected' : ''}`}
                  onClick={openNotification}
                  sx={{ width: 40, height: 40 , marginLeft: '12px', backgroundColor: '#E5F6FF', color: '#00AAFF'}}
                >
                 <Tooltip title="Notification" arrow>
                    <img id="notification" src={notification} alt="notification" />
                  </Tooltip>
                </Avatar>
                {/* <Avatar
                  sx={{ width: 39, height: 39, marginLeft: '12px', backgroundColor: '#E5F6FF', color: '#00AAFF'}}
                  onMouseEnter={(e) => disp_user_tooltip(e)}
                >
                  <PersonOutlineOutlinedIcon />
                  <Popover 
                  id="popover"
                  open={showUserTooltip}
                  onClose={() => closeUserTooltip()}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  
                >
                  User ID : {currentUserID}
                  <br/>
                  User Name : {currentUser}
                  <br/>
                  Organisation : {currentOrg}
                </Popover>
                </Avatar> */}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='child-container'>
          <Outlet />
        </div>
        
        <Routes>
          <Route path="DashboardList" element={<DashboardList />} />
          <Route path="Dashboard" element={<OpenDashboard />} />
          <Route path="UsecaseList" element={<UsecaseList />} />
        </Routes> 
      </div>
      <Modal open={visbleNotification} onClose={closeNotification}>
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            bottom: 10,
            width: 400,
            borderRadius: '15px',
            backgroundColor: 'white',
            padding: 2,
          }}
        >
          <div className='notiheader'>
            <NotificationsIcon sx={{color:'#00AAFF',height:25,width:30}}/>
            <span className='notihead'>Notifications</span>
          </div>
          <div style={{textAlign:'right',height:'40px'}}>
            <Button onClick={markAllNotificationAsSeen} size='small' sx={{backgroundColor:'#00AAFF',color:'white','&:hover': {
                                    backgroundColor: 'white', // Adjust the alpha value to control transparency
                                    color: '#00AAFF',
                                    border:'1px solid #00AAFF'
                                }}}>Mark as Read</Button>
          </div>
          <div className='msgList'>
            <List>
              {notifications.slice(-10).map((item, Itindex) => (
                <React.Fragment key={Itindex}>
                  <ListItem sx={{backgroundColor: '#E5F6FF',boxShadow: '0px 0px 2px 0px #00000033',padding:'10px',marginBottom:'5px',borderRadius:'15px'}}>
                    <IconButton onClick={()=>markAsReadParticualrMsg(item.id,Itindex)} style={{ position: 'absolute', top: 0, right: 0 }}>
                      <CloseIcon />
                    </IconButton>
                    <ListItemText
                      primary={
                        <div style={{width:'350px'}} >
                         {(item.text.length > 50 && Itindex !== expandedItem) ? `${item.text.slice(0, 50)}...` : item.text}
                        </div>
                      }
                      secondary={
                        <>
                        {item.date}
                        {item.text.length > 50 && (
                        <IconButton onClick={() => handleExpand(Itindex)}>
                          {(Itindex !== expandedItem)?(<ExpandMoreIcon />) : (<ExpandLessIcon />)}
                        </IconButton>
                        )}
                        </>
                        }
                      />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </div>
        </Box>
      </Modal>
    </div>
  );
};




Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
import React, { useState } from 'react';
import './LoginPage.css';
import { CountryFlag } from 'react-country-flag';

import Select from 'react-select';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoginLogo from '../../assets/loginLogo.svg';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../httpClient';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from '../../assets/Cloobot X Logo.svg';
import './AuthPage.css'; // Import your styles
import DashboardList from '../dashboard/DashboardList/DashboardList';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, Input} from '@mui/material';
import { Cookie, Event } from '@mui/icons-material';
import Cookies from 'js-cookie';
import {useEffect} from 'react';
import { testhttpClient } from '../../httpClient';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const LoginPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false); // Added state to track signup mode
  const [fullName, setFullName] = useState('');
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [value, setValue] = useState();
  const [isChecked, setIsChecked] = useState(false);
  

  const countryOptions = countryList().getData().map(country => ({
    label: `${country.label} (+${country.value})`,
    value: country.value,
  }));

  

  const goToUsecaseList = () =>{
    navigate('/layout/UsecaseList');
   }

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const creds = {
      useremail: data.get('email'),
      password: data.get('password'),
    };
    try {
      const response = await httpClient.post('/checklogin', creds);
      const res = response.data;

      if (res.data === false) {
        setOpen(true);
        // Handle login failure
      } else {
        // Handle successful login
        localStorage.setItem('vector_token', res.data);
            localStorage.setItem('v_auth_level', res.auth_level);
            localStorage.setItem('vector_user_id', res.user_id);
            localStorage.setItem('v_user_name', res.user_name);
            localStorage.setItem('v_org_name', res.org_name);
            localStorage.setItem('user_time_zone', res.time_zone);
            localStorage.setItem('v_org_id', res.org_id);
            localStorage.setItem('v_bot_type', res.bot_type);
            // navigate('/layout/DashboardList');
            check_usecase_login()
            
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle network or other errors
    }
  };

  const check_usecase_login = ()=>{
    try {
      const data = {
        token: localStorage.getItem('vector_token'),
      }
      testhttpClient.post('/get_usecase_dashboard_map_list',data).then(response=>{
        if(response.data.status == true){
          console.log('usecase data :: ',response.data.data)
          if(response.data.data.length==1 && response.data.data[0].usecase_id == -1){
            console.log('dashboard list route :: ')
            navigate('/layout/DashboardList');
          }
          else{
            console.log('usecase route ::')
            navigate('/layout/UsecaseList')
          }
        }
        else{
          console.log('failed response :: ',response)
        }
      }).catch(error => {
        console.error('Error:', error);
        // setLoading(false);
      });
    }catch (error) {
      console.log('outer error :: ',error)
      // setLoading(false);
      // Handle network or other errors
    } 
  }

  const handleAutoLogin = async (email,password)=>{
    const creds = {
      useremail: email,
      password: password,
    };

    try {
      const response = await httpClient.post('/checklogin', creds);
      const res = response.data;

      if (res.data === false) {
        setOpen(true);
        // Handle login failure
      } else {
        // Handle successful login
        localStorage.setItem('vector_token', res.data);
            localStorage.setItem('v_auth_level', res.auth_level);
            localStorage.setItem('vector_user_id', res.user_id);
            localStorage.setItem('v_user_name', res.user_name);
            localStorage.setItem('v_org_name', res.org_name);
            localStorage.setItem('user_time_zone', res.time_zone);
            localStorage.setItem('v_org_id', res.org_id);
            localStorage.setItem('v_bot_type', res.bot_type);
            // navigate('/layout/DashboardList');
            try {
              const data = {
                token: localStorage.getItem('vector_token'),
              }
              testhttpClient.post('/get_usecase_dashboard_map_list',data).then(response=>{
                if(response.data.status == true){
                  console.log('usecase data :: ',response.data.data)
                  if(response.data.data.length==1 && response.data.data[0].usecase_id == -1){
                    navigate('/layout/DashboardList');
                  }
                  else{
                    navigate('/layout/UsecaseList')
                  }
                }
                else{
                  console.log('failed response :: ',response)
                }
              }).catch(error => {
                console.error('Error:', error);
                // setLoading(false);
              });
            }catch (error) {
              console.log('outer error :: ',error)
              // setLoading(false);
              // Handle network or other errors
            } 
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle network or other errors
    } 
  }

  
  useEffect(() => {
    Check_login_creds()
    const rem_status = Cookies.get('remember_status')
    console.log('rem status :: ',rem_status)
    if(rem_status == 'false'){
      setIsChecked(false)
    }
    else{
      setIsChecked(true)
    }
  },[]);

  const handleAuth = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const creds = {
      email: data.get('email'),
      password: data.get('password'),
      orgname: data.get('fullName'),
      code: data.get('code'),
      phone: data.get('phone'),
      country: selectedCountry?.label,
    };

    try {
      const response = await httpClient.post('/register', creds);
      const res = response.data;

      if (res.data === false) {
        setOpen(true);
        // Handle signup failure
      } else {
        // Handle successful signup
        localStorage.setItem('vector_token', res.data);
            localStorage.setItem('v_auth_level', res.auth_level);
            localStorage.setItem('vector_user_id', res.user_id);
            localStorage.setItem('v_user_name', res.user_name);
            localStorage.setItem('v_org_name', res.org_name);
            localStorage.setItem('user_time_zone', res.time_zone);
            localStorage.setItem('v_org_id', res.org_id);
            localStorage.setItem('v_bot_type', res.bot_type);
            navigate('/layout')
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle network or other errors
    }
  };

  const defaultTheme = createTheme();


  const handleCheckboxChange =(e)=>{
    console.log("event is :: ",e.target.checked)
    
    setIsChecked(e.target.checked)
    StoreLoginDetails(e.target.checked)
  }
  const StoreLoginDetails = (event)=>{
    console.log('event is :: ',event)
    // setIsChecked(!checked)
    if(event === true){
      Cookies.set('email',email)
      Cookies.set('password',password)
      Cookies.set('remember_status',event)
      console.log('email and password :: ',email,password)
    }
    else{
      Cookies.set('email','')
      Cookies.set('password','')
      console.log('email and password false :: ',email,password)
      Cookies.set('remember_status',event)
    }
  }

  const Check_login_creds = ()=>{
    console.log('check')
    console.log('email :: ',Cookies.get('email'))
    console.log('password :: ',Cookies.get('password'))
    setEmail(Cookies.get('email'))
    setPassword(Cookies.get('password'))
    console.log('email and password :: ',email,password)
    if(Cookies.get('email') != '' && Cookies.get('password') != ''){
      handleAutoLogin(Cookies.get('email'),Cookies.get('password'))
    }
  }

  return (
    <div className="login-container">
      <div className="background-text">
        <div className="text-block">Experience</div>
        <div className="text-block">THE NEW ERA</div>
        <div className="text-block">OF SOFTWARE</div>
      </div>
      <div className="login-box">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
        <form onSubmit={isSignUp ? handleAuth : handleLogin}>
          {isSignUp ? (
            <>
              <div className="form-group">
                <label htmlFor="fullName">Full Name:</label>
                <div className="input-container">
                <i className="fas fa-user"></i>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={fullName}
                  placeholder="Type your first and last name"
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <div className="input-container">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Type your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group-inline"> {/* Use a new class for inline layout */}
              <div className="form-group-small">
                <label htmlFor="code">Code:</label>
                <PhoneInput
                    type="text"
                    id="code"
                    name="code"
                    international={true}
                    defaultCountry="RU"
                    value={value}
                    onChange={setValue}/>
              </div>
                  <div className="form-group-small">
                    <label htmlFor="phone">Phone No:</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={phone}
                      placeholder="Type your phone number"
                      onChange={(e) => setPhone(e.target.value)}
                      className="phone-input"
                      required
                    />
                  </div>
                </div>

              <div className="form-group-password">
                <label htmlFor="password">Current Password:</label>
                <div className="input-container">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Current Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <div className="input-container">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Type your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="input-container">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Type your password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  {/* <p className="forgot-password">
                    Forgot password
                  </p> */}
                </div>
              </div>
              <label style={{alignItems:'center'}}>
                <Checkbox color="primary" checked={isChecked} onChange={handleCheckboxChange}/>
                Remember me
            </label>
            </>
          )}
  
          <button className='logbutton' type="submit">{isSignUp ? 'Sign Up' : 'Login'}</button>
        </form>

        <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const credentialResponseDecode = jwtDecode(
            credentialResponse.credential
          );
          const { email } = credentialResponseDecode;
          console.log('Email:', email);
          try {
            const response = await httpClient.post('/checklogin2?ui=1', email);
            const res = response.data;
      
            if (res.data === false) {
              setOpen(true);
              // Handle login failure
            } else {
              // Handle successful login
              localStorage.setItem('vector_token', res.data);
                  localStorage.setItem('v_auth_level', res.auth_level);
                  localStorage.setItem('vector_user_id', res.user_id);
                  localStorage.setItem('v_user_name', res.user_name);
                  localStorage.setItem('v_org_name', res.org_name);
                  localStorage.setItem('user_time_zone', res.time_zone);
                  localStorage.setItem('v_org_id', res.org_id);
                  localStorage.setItem('v_bot_type', res.bot_type);
                  // navigate('/layout/DashboardList');
                  try {
                    const data = {
                      token: localStorage.getItem('vector_token'),
                    }
                    testhttpClient.post('/get_usecase_dashboard_map_list',data).then(response=>{
                      if(response.data.status == true){
                        console.log('usecase data :: ',response.data.data)
                        if(response.data.data.length==1 && response.data.data[0].usecase_id == -1){
                          navigate('/layout/DashboardList');
                        }
                        else{
                          navigate('/layout/UsecaseList')
                        }
                      }
                      else{
                        console.log('failed response :: ',response)
                      }
                    }).catch(error => {
                      console.error('Error:', error);
                      // setLoading(false);
                    });
                  }catch (error) {
                    console.log('outer error :: ',error)
                    // setLoading(false);
                    // Handle network or other errors
                  } 
            }
          } catch (error) {
            console.error('Error:', error);
            // Handle network or other errors
          } 
        }
        }
        onError={() => {
          console.log('Login Failed');
        }}
      />

        {error && <p className="error-message">{error}</p>}
        <div className="create-account-text">
          {isSignUp ? (
            <>
              Already have an account?{' '}
              <a href="#" onClick={() => setIsSignUp(false)}>
                Login
              </a>
            </>
          ) : (
            <>
              Don't have an account?{' '}
              <a href="#" onClick={() => setIsSignUp(true)}>
                Create an account
              </a>
            </>
          )}
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-links">
            <a href="https://cloobot-public.s3.ap-south-1.amazonaws.com/Cloobot+Product+Suite+Terms+and+Conditions.pdf" target='_blank'>Terms & Conditions</a>
            <a href="https://cloobot-public.s3.ap-south-1.amazonaws.com/Cloobot+Suite+Privacy+Policy.pdf" target='_blank'>Privacy Policy</a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfMPGmWIMtjEEo1L0dgeOugZQ-_DbUUhfpm4FwpYhoY6VrE9Q/viewform" target='_blank'>Contact Support</a>
          </div>
          <p>© Cloobot Techlabs Pvt. Ltd.</p>
        </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;





// ThemeProvider.js
import React from 'react';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat', // Replace with your preferred font
  },
  palette: {
    primary: {
      main: '#00AAFF',
    },
    secondary: {
      main: green[500],
    },
  },
});

const CustomThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
